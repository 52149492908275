import { ɵgetAllInstancesOf as _getAllInstancesOf, ɵgetDefaultInstanceOf as _getDefaultInstanceOf, VERSION, ɵAngularFireSchedulers as _AngularFireSchedulers, ɵzoneWrap as _zoneWrap } from '@angular/fire';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, Optional, PLATFORM_ID, NgModule, makeEnvironmentProviders, NgZone, Injector } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { registerVersion } from 'firebase/app';
import { deleteToken as deleteToken$1, getMessaging as getMessaging$1, getToken as getToken$1, isSupported as isSupported$1, onMessage as onMessage$1 } from 'firebase/messaging';
export * from 'firebase/messaging';
class Messaging {
  constructor(messaging) {
    return messaging;
  }
}
const MESSAGING_PROVIDER_NAME = 'messaging';
class MessagingInstances {
  constructor() {
    return _getAllInstancesOf(MESSAGING_PROVIDER_NAME);
  }
}
const messagingInstance$ = timer(0, 300).pipe(concatMap(() => from(_getAllInstancesOf(MESSAGING_PROVIDER_NAME))), distinct());
const PROVIDED_MESSAGING_INSTANCES = new InjectionToken('angularfire2.messaging-instances');
function defaultMessagingInstanceFactory(provided, defaultApp, platformId) {
  if (isPlatformServer(platformId)) {
    return null;
  }
  const defaultMessaging = _getDefaultInstanceOf(MESSAGING_PROVIDER_NAME, provided, defaultApp);
  return defaultMessaging && new Messaging(defaultMessaging);
}
function messagingInstanceFactory(fn) {
  return (zone, injector, platformId) => {
    if (isPlatformServer(platformId)) {
      return null;
    }
    const messaging = zone.runOutsideAngular(() => fn(injector));
    return new Messaging(messaging);
  };
}
const MESSAGING_INSTANCES_PROVIDER = {
  provide: MessagingInstances,
  deps: [[new Optional(), PROVIDED_MESSAGING_INSTANCES]]
};
const DEFAULT_MESSAGING_INSTANCE_PROVIDER = {
  provide: Messaging,
  useFactory: defaultMessagingInstanceFactory,
  deps: [[new Optional(), PROVIDED_MESSAGING_INSTANCES], FirebaseApp, PLATFORM_ID]
};
class MessagingModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'fcm');
  }
  static ɵfac = function MessagingModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MessagingModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MessagingModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [DEFAULT_MESSAGING_INSTANCE_PROVIDER, MESSAGING_INSTANCES_PROVIDER]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessagingModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_MESSAGING_INSTANCE_PROVIDER, MESSAGING_INSTANCES_PROVIDER]
    }]
  }], () => [], null);
})();
function provideMessaging(fn, ...deps) {
  registerVersion('angularfire', VERSION.full, 'fcm');
  return makeEnvironmentProviders([DEFAULT_MESSAGING_INSTANCE_PROVIDER, MESSAGING_INSTANCES_PROVIDER, {
    provide: PROVIDED_MESSAGING_INSTANCES,
    useFactory: messagingInstanceFactory(fn),
    multi: true,
    deps: [NgZone, Injector, PLATFORM_ID, _AngularFireSchedulers, FirebaseApps, ...deps]
  }]);
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const deleteToken = _zoneWrap(deleteToken$1, true, 2);
const getMessaging = _zoneWrap(getMessaging$1, true);
const getToken = _zoneWrap(getToken$1, true);
const isSupported = _zoneWrap(isSupported$1, false);
const onMessage = _zoneWrap(onMessage$1, false);

/**
 * Generated bundle index. Do not edit.
 */

export { Messaging, MessagingInstances, MessagingModule, deleteToken, getMessaging, getToken, isSupported, messagingInstance$, onMessage, provideMessaging };
