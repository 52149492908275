import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserService } from 'src/app/auth/services/user.service';
import { OrganizationConfigs } from '../../models/organization/config';
import { NavigationService } from '../../services/navigation.service';
import { OrganizationConfigService } from '../../services/organization-config.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass'],
    standalone: false
})
export class HomeComponent implements OnInit {

  activeModules: OrganizationConfigs.ActiveModules = new OrganizationConfigs.ActiveModules()

  constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private orgConfigService: OrganizationConfigService,
  ) {
    console.log('home component')
    this.navigationService.setTitle(environment.appName)
  }

  ngOnInit(): void {
    console.log('home component oninit')
    const user = this.userService.getOrgUser()
    this.activeModules = this.orgConfigService.getOrganizationConfig().activeModules || this.activeModules;
    if (this.redirectIfOnlyOneActiveModule()) {
      return
    }

    if (user.roles.length == 1) this.redirectToRole(user.roles[0])
  }

  redirectToRole(role: string): void {
    let path = "";
    switch (role) {
      case "ADMIN":
        if (!this.activeModules.procurement) return;
        // path = '/app/procurement';
        path = '/app/salesrep';
        break;
      case "WAREHOUSEMANAGER":
        if (!this.activeModules.warehouseOrders) return;
        path = '/app/warehouse-orders';
        break;
      case "ACCOUNTANT":
        if (!this.activeModules.warehouseOrders) return;
        path = '/app/warehouse-orders';
        break;
      case "WAREHOUSECLERK":
        if (!this.activeModules.warehouseOrders) return;
        path = '/app/warehouse-orders';
        break;
      case "SALESREP":
        if (!this.activeModules.salesrep) return;
        path = '/app/salesrep';
        break;
      case "PROCUREMENTMANAGER":
        if (!this.activeModules.procurement) return;
        path = '/app/procurement';
        // path = '/app/salesrep';
        break;
    }
    this.router.navigate([path])
  }

  redirectIfOnlyOneActiveModule(): boolean {

    let redirectTo = '';

    if (this.activeModules.warehouseOrders) {
      redirectTo = '/app/warehouse-orders'
    }
    if (this.activeModules.procurement) {
      if (redirectTo) return false;
      redirectTo = '/app/procurement'
      // redirectTo = '/app/salesrep'
    }
    if (this.activeModules.salesrep) {
      if (redirectTo) return false;
      redirectTo = '/app/salesrep';
    }
    if (this.activeModules.expenses) {
      if (redirectTo) return false;
      redirectTo = '/app/expenses'
    }
    // console.log("this.activeModules:", this.activeModules)
    this.router.navigate([redirectTo])
    return true;
  }

}
