import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';



@Component({
    selector: 'app-file-upload-button',
    templateUrl: './file-upload-button.component.html',
    styleUrls: ['./file-upload-button.component.sass'],
    standalone: false
})
export class FileUploadButtonComponent implements OnInit {

  @Input()
  buttonText: string = "";

  @Output()
  fileUploaded: EventEmitter<File> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleFileUpload(event: any) {
    const file: File = event.target.files[0];
    this.fileUploaded.emit(file)
  }

}
