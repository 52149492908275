import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil, filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from 'src/app/auth/services/user.service';
import { OrgUser } from '../../models/user/user';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '@ngxs/store';
import { OrganizationConfigService } from '../../services/organization-config.service';
import { OrganizationConfig, OrganizationConfigs } from '../../models/organization/config';
import { OrganizationService } from '../../services/organization.service';
import { Organization } from '../../models/organization/organization';
import { UserOrganizationsState } from 'src/app/profile/states/user-organizations/user-organizations.state';
import { UserOrganizationsActions } from 'src/app/profile/states/user-organizations/user-organizations.actions';
import { GlobalActions } from '../../actions/global.actions';
import { MessagingService } from '../../services/messaging.service';
import { environment } from 'src/environments/environment';
import posthog from 'posthog-js';

type _moduleName = 'warehouseOrders' | 'salesrep' | 'procurement' | 'expenses' | 'settings' | 'timesheets';
type _route = { path: string, roles: string[], name: string, moduleName: _moduleName };

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass'],
  standalone: false
})
export class NavigationComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  title$: Observable<string>;
  orgUser: OrgUser = new OrgUser();
  activeModules: OrganizationConfigs.ActiveModules = new OrganizationConfigs.ActiveModules();
  organization: Organization = new Organization();
  userOrganizations: Organization[] = [];
  selectedOrg: Organization = new Organization();
  showBackNavigation: boolean = true;

  routes: _route[] = [
    {
      path: 'warehouse-orders',
      roles: ['ADMIN', 'WAREHOUSEMANAGER', 'ACCOUNTANT', 'WAREHOUSECLERK'],
      name: 'Distribucija',
      moduleName: 'warehouseOrders'
    },
    {
      path: 'salesrep',
      roles: ['ADMIN', 'SALESREP'],
      name: 'Komercijala',
      moduleName: 'salesrep'
    },
    {
      path: 'procurement',
      roles: ['ADMIN', 'PROCUREMENTMANAGER'],
      name: 'Nabavka',
      moduleName: 'procurement'
    },
    {
      path: 'expenses',
      roles: ['ADMIN', 'PROCUREMENTMANAGER', 'SALESREP', 'WAREHOUSEMANAGER'],
      name: 'Troškovi',
      moduleName: 'expenses'
    },
    {
      path: 'users-management',
      roles: ['ADMIN'],
      name: 'Podešavanja',
      moduleName: 'settings'
    },
    {
      path: 'timesheets',
      roles: [], //edit this thoughtfully
      name: 'Evidencija prisustva',
      moduleName: 'timesheets'

    }/* ,
    {
      path: 'profile',
      roles: ['ADMIN', 'SALESREP', 'WAREHOUSEMANAGER', 'ACCOUNTANT'],
      name: 'Profil'
    } */
  ]

  isHandset$: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private messagingService: MessagingService,
    private navigationService: NavigationService,
    private router: Router,
    private userService: UserService,
    private store: Store,
    private organizationConfigService: OrganizationConfigService,
    private orgService: OrganizationService,
  ) {
    this.title$ = this.navigationService.getTitle();

    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(map(result => result.matches), shareReplay());

    // ✅ Handle back navigation visibility
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      const navEvent = event as NavigationEnd;
      this.showBackNavigation = !navEvent.url.includes('customer-orders/new-order');
    });
  }

  ngOnInit(): void {
    this.initializeUserTracking();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();

    this.userService.orgUserAsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((orgUser: OrgUser) => {
        this.orgUser = orgUser;
      });

    this.orgService.orgAsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org: Organization) => {
        this.organization = org;
      });

    this.organizationConfigService.configAsObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((config: OrganizationConfig) => {
        this.activeModules = config.activeModules || this.activeModules;
      });

    this.store.dispatch(new UserOrganizationsActions.GetOrganizations());

    this.store.select(UserOrganizationsState.getOrganizations)
      .pipe(takeUntil(this.destroy$))
      .subscribe((orgs: Organization[]) => {
        this.userOrganizations = environment.singleOrgId
          ? orgs.filter(org => org.id === environment.singleOrgId)
          : orgs;

        this.getSelectedOrg();
      });
  }

  private initializeUserTracking(): void {
    const _curUser = this.userService.getCurrentUser();

    if (environment.production) {
      posthog.init('phc_M7C3vZW0wAUgvWRimPEpSCRCzwluRGFr7NZ0e2O6QAB', {
        api_host: 'https://eu.i.posthog.com',
        loaded: function (posthog) {
          if (_curUser?.id) {
            posthog.identify(_curUser.id, {
              id: _curUser.id,
              name: _curUser.fullName,
              orgId: _curUser.orgId
            });
          } else {
            console.error('User information is not available for identification.');
          }
        }
      });
    }
  }

  signOut() {
    this.authService.clearAllStatesAndSignout();
  }

  isVisibleToUser(roles: string[], path: string): boolean {
    if (path === 'timesheets') return true;
    return this.orgUser?.roles?.some(role => roles.includes(role)) ?? false;
  }

  isVisibleToOrg(moduleName: _moduleName): boolean {
    return !!this.activeModules[moduleName];
  }

  navigateBack() {
    this.navigationService.goBack();
  }

  compareOrgs(org1: any, org2: any): boolean {
    return org1?.id === org2?.id;
  }

  getSelectedOrg() {
    const orgId = this.orgService.getCurrentOrganizationId();
    this.selectedOrg = this.userOrganizations.find(org => org.id === orgId) || new Organization();
  }

  async changeOrg() {
    this.store.dispatch(new GlobalActions.ClearAll());
    await this.orgService.switchOrganization(this.selectedOrg.id);
    window.location.reload();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
