import { Injectable } from '@angular/core';
import { Messaging, getToken, onMessage } from '@angular/fire/messaging';
import { UserService } from 'src/app/auth/services/user.service';
import { SnackbarService } from './snackbar.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(
    private messaging: Messaging,
    private userService: UserService,
    private snackbar: SnackbarService
  ) {}

  /** ✅ Requests notification permission & retrieves the token */
  async requestPermission(): Promise<void> {
    try {
      const permission = await Notification.requestPermission();
      // console.log(`Notification permission: ${permission}`);

      if (permission === 'granted') {
        const token = await getToken(this.messaging, { vapidKey: environment.vapidKey });
        if (token) {
          // console.log('FCM Token:', token);
          await this.userService.saveNotificationToken(token);
        } else {
          console.warn('No FCM token received.');
        }
      } else {
        console.warn('Notification permission denied.');
      }
    } catch (error) {
      console.error('Error getting FCM token:', error);
    }
  }

  /** ✅ Listens for incoming push messages */
  receiveMessage(): void {
    onMessage(this.messaging, (payload) => {
      console.log("📩 Message received:", payload);
      const remoteMsg = payload?.notification?.body ?? "";
      if (remoteMsg) {
        this.snackbar.info(remoteMsg);
      }
    });
  }
}
