import { Injectable } from "@angular/core";
import { Firestore, doc, getDoc, updateDoc, collection, getDocs, query, where } from "@angular/fire/firestore";
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { NO_USER, UserService } from "src/app/auth/services/user.service";
import { JoinRequest } from "src/app/core/models/organization/joinRequest";
import { Organization } from "src/app/core/models/organization/organization";
import { OrgUser, UserFS } from "src/app/core/models/user/user";
import { UserOrganizationsActions } from "./user-organizations.actions";
import { Subscription } from "rxjs";

export class UserOrganizationsStateModel {
  organizationsBucket: { [id: string]: Organization } = {};
  joinRequests: JoinRequest[] = [];
  user: UserFS | undefined = NO_USER;
}

@State<UserOrganizationsStateModel>({
  name: 'userOrganizations',
  defaults: {
    organizationsBucket: {},
    joinRequests: [],
    user: NO_USER
  }
})
@Injectable()
export class UserOrganizationsState implements NgxsOnInit {
  joinRequestsSub: Subscription | undefined;

  constructor(
    private firestore: Firestore,
    private store: Store,
    private userService: UserService
  ) { }

  ngxsOnInit(ctx: StateContext<UserOrganizationsStateModel>) {
    this.userService.userAsObservable().subscribe(async (user: UserFS | undefined) => {
      if (this.joinRequestsSub) {
        this.joinRequestsSub.unsubscribe();
      }

      if (!user || user.id == "NO_USER") {
        ctx.setState(patch({
          organizationsBucket: {},
          joinRequests: [],
          user: NO_USER
        }));
        return;
      }

      ctx.setState(patch({ user: user }));

      // Fetch join requests using modern Firestore API
      const joinRequestsQuery = query(
        collection(this.firestore, 'join_requests'),
        where('userId', '==', user.id),
        where('isResolved', '==', false)
      );

      const result = await getDocs(joinRequestsQuery);
      const jrs = result.docs.map(doc => new JoinRequest(doc.data()));

      ctx.setState(patch({ joinRequests: jrs }));
    });
  }

  @Action(UserOrganizationsActions.GetOrganizations)
  fetchOrganizations(ctx: StateContext<UserOrganizationsStateModel>) {
    const bucket = ctx.getState().organizationsBucket;
    const user = ctx.getState().user;
    if (!user) return;

    user.orgIds.forEach((orgId: string) => {
      if (!bucket[orgId]) {
        this.store.dispatch(new UserOrganizationsActions.UpdateOrganizationsBucket(orgId));
      }
    });
  }

  @Action(UserOrganizationsActions.UpdateOrganizationsBucket)
  async updateSelectedOrg(ctx: StateContext<UserOrganizationsStateModel>, action: UserOrganizationsActions.UpdateOrganizationsBucket) {
    const orgRef = doc(this.firestore, 'organizations', action.orgId);
    const result = await getDoc(orgRef);

    if (result.exists()) {
      const organization = result.data() as Organization;
      ctx.setState(patch({
        organizationsBucket: patch({ [action.orgId]: organization })
      }));
    }
  }

  @Action(UserOrganizationsActions.CancelJoinRequest)
  async cancelJoinRequest(ctx: StateContext<UserOrganizationsStateModel>, { payload }: UserOrganizationsActions.CancelJoinRequest) {
    const joinRequestRef = doc(this.firestore, 'join_requests', payload);
    await updateDoc(joinRequestRef, {
      resolvedTimestamp: new Date(),
      isApproved: false,
      isResolved: true
    });
  }

  @Action(UserOrganizationsActions.SwitchOrganization)
  switchOrganization(ctx: StateContext<UserOrganizationsStateModel>, action: UserOrganizationsActions.SwitchOrganization) {
    const currentState = ctx.getState();
    const _newUser = new UserFS(currentState.user);
    _newUser.orgId = action.organizationId;

    ctx.setState({
      ...currentState,
      user: _newUser
    });
  }

  @Selector()
  static getOrganizations(state: UserOrganizationsStateModel) {
    return Object.values(state.organizationsBucket)
  }

  @Selector()
  static getOrganizationsById(state: UserOrganizationsStateModel) {
    return (orgId: string) => {
      return state.organizationsBucket[orgId]
    }
  }

  @Selector()
  static getJoinRequests(state: UserOrganizationsStateModel) {
    return state.joinRequests
  }
}