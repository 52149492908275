import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OrgUser, UserFS } from 'src/app/core/models/user/user';
import { NO_ORGANIZATION, NO_TEFTER_ACCOUNT, NO_USER, NO_USER_ID, PENDING_TO_JOIN, UserService } from './user.service';
import { OrganizationService } from 'src/app/core/services/organization.service';
import { GlobalActions } from 'src/app/core/actions/global.actions';
import { Store } from '@ngxs/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth, User, authState, signInWithPhoneNumber, signInWithEmailAndPassword, signOut, RecaptchaVerifier, ConfirmationResult, getIdTokenResult } from '@angular/fire/auth';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userAF: User | null = null;
  userFS: UserFS | undefined = undefined;
  confirmationResult: ConfirmationResult | null = null;
  private recaptchaVerifier: RecaptchaVerifier | null = null;

  private loggedInSubject = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedInSubject.asObservable();

  private idTokenSubject = new BehaviorSubject<any>("TOKEN_PENDING");
  idToken$ = this.idTokenSubject.asObservable();

  constructor(
    private auth: Auth,
    private router: Router,
    private store: Store,
    private userService: UserService,
    private organizationService: OrganizationService,
    private http: HttpClient
  ) {
    console.log('AuthService: Initializing...');

    authState(this.auth).pipe(
      switchMap(async (user: User | null) => {
        if (!user) {
          console.log("AuthService: No authenticated user, signing out...");
          this.handleUserSignOut();
          return null;
        }

        console.log(`AuthService: Authenticated user: ${user.uid}`);
        this.loggedInSubject.next(true);
        this.userAF = user;

        // ✅ Fetch Firestore user
        const firestoreUser = await this.userService.getUserByUID(user.uid);

        if (!firestoreUser || firestoreUser.id === NO_USER_ID) {
          console.warn(`AuthService: No Firestore user found, redirecting to register-user.`);
          this.userFS = new UserFS({ id: user.uid, phoneNumber: user.phoneNumber ?? "", registrationStatus: NO_TEFTER_ACCOUNT });
          this.userService.setCurrentUser(this.userFS);
          this.router.navigate(['/account/register-user']);
          return null;
        }

        //console.log("AuthService: Firestore user exists ✅", firestoreUser);

        // ✅ Ensure user organization is properly handled
        const selectedOrgId = await this.handleUserOrganizations(user, firestoreUser);
        if (!selectedOrgId) {
          return null;
        }

        console.log("AuthService: User authenticated and authorized ✅");

        return user;
      })
    ).subscribe(async (user: User | null) => {
      if (!user) {
        this.handleUserSignOut();
        return;
      }

      this.userAF = user;
      this.loggedInSubject.next(true);

      // ✅ Fetch ID Token & Update Observable
      const idToken = await user.getIdTokenResult();
      this.idTokenSubject.next(idToken);
    });
  }

  /** ✅ Ensure ID Token is Always Available */
  async getFreshIdToken(): Promise<any> {
    if (!this.userAF) {
      console.warn("AuthService: No authenticated user found.");
      return "TOKEN_PENDING";
    }

    const idToken = await this.userAF.getIdTokenResult();
    this.idTokenSubject.next(idToken);
    return idToken;
  }

  private async handleUserOrganizations(user: User, firestoreUser: UserFS): Promise<string | null> {
    console.log("AuthService: Checking user organizations for:", user.uid);

    let selectedOrgId = firestoreUser.orgId;
    if (!selectedOrgId) {
      console.warn("AuthService: User has no organization, redirecting...");
      this.userFS = firestoreUser;
      this.userService.setCurrentUser(this.userFS);
      this.router.navigate(['/account/register-organization']);
      return null;
    }

    // ✅ Update Firestore user with organization details
    const claimsArrayOfOrgIds = firestoreUser.orgIds || [];
    await this.userService.updateCurrentUser(user.uid, selectedOrgId, claimsArrayOfOrgIds);

    // ✅ Store user details and update observable
    this.userFS = firestoreUser;
    this.userService.setCurrentUser(this.userFS);

    // ✅ Fetch organization details
    await this.organizationService.setUserOganization(selectedOrgId);

    return selectedOrgId;
  }

  private handleUserSignOut() {
    console.warn("AuthService: User signed out.");
    this.loggedInSubject.next(false);
    this.idTokenSubject.next("TOKEN_PENDING");
    this.userService.setCurrentUser(NO_USER);
    localStorage.removeItem('user');
    localStorage.removeItem('orgId');
    this.router.navigate(['login']);
  }

  getFirebaseUser(): Observable<User | null> {
    return authState(this.auth);
  }

  sendOtpCode(phoneNumber: string, recaptchaToken: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.sendOtpCodeUrl, { phoneNumber, recaptchaToken }, { headers });
  }

  verifyOtp(phoneNumber: string, otp: string): Observable<any> {
    return this.http.post(environment.verifyOtpUrl, { phoneNumber, otp });
  }

  async clearAllStatesAndSignout() {
    this.store.dispatch(new GlobalActions.ClearAll()).subscribe(() => {
      this.logOut();
    });
  }

  async logOut() {
    try {
      await signOut(this.auth);
      this.handleUserSignOut();
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  async sendLoginCode(phoneNumber: string, appVerifier: RecaptchaVerifier): Promise<void> {
    return signInWithPhoneNumber(this.auth, phoneNumber, appVerifier)
      .then((result) => {
        this.confirmationResult = result;
      })
      .catch((error) => {
        console.error("Error sending login code:", error);
        throw error;
      });
  }

  async verifyLoginCode(smsCode: string): Promise<void> {
    if (!this.confirmationResult) {
      return Promise.reject(new Error('No confirmation result found. Please request a login code first.'));
    }

    return this.confirmationResult.confirm(smsCode)
      .then((userCredential) => {
        console.log('Login successful:', userCredential);
      })
      .catch((error) => {
        console.error("Error verifying login code:", error);
        throw error;
      });
  }

  logIn(email: string, password: string): Observable<any> {
    return from(signInWithEmailAndPassword(this.auth, email, password))
      .pipe(
        map(result => result),
        catchError(error => throwError(() => error))
      );
  }
}
