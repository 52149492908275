import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appClickStopPropagation]',
    standalone: false
})
export class ClickStopPropagationDirective {

  @HostListener('click', ['$event'])
  onClick(event: any): void{
    event.preventDefault()
    event.stopPropagation()
  }

}
