var e,
  t = "undefined" != typeof window ? window : void 0,
  i = "undefined" != typeof globalThis ? globalThis : t,
  r = Array.prototype,
  n = r.forEach,
  s = r.indexOf,
  o = null == i ? void 0 : i.navigator,
  a = null == i ? void 0 : i.document,
  l = null == i ? void 0 : i.location,
  u = null == i ? void 0 : i.fetch,
  c = null != i && i.XMLHttpRequest && "withCredentials" in new i.XMLHttpRequest() ? i.XMLHttpRequest : void 0,
  d = null == i ? void 0 : i.AbortController,
  h = null == o ? void 0 : o.userAgent,
  _ = null != t ? t : {},
  p = {
    DEBUG: !1,
    LIB_VERSION: "1.234.6"
  },
  v = "$copy_autocapture",
  g = ["$snapshot", "$pageview", "$pageleave", "$set", "survey dismissed", "survey sent", "survey shown", "$identify", "$groupidentify", "$create_alias", "$$client_ingestion_warning", "$web_experiment_applied", "$feature_enrollment_update", "$feature_flag_called"];
!function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(e || (e = {}));
var f = ["fatal", "error", "warning", "log", "info", "debug"];
function m(e, t) {
  return -1 !== e.indexOf(t);
}
var y = function (e) {
    return e.trim();
  },
  b = function (e) {
    return e.replace(/^\$/, "");
  };
var w = Array.isArray,
  S = Object.prototype,
  k = S.hasOwnProperty,
  E = S.toString,
  x = w || function (e) {
    return "[object Array]" === E.call(e);
  },
  I = e => "function" == typeof e,
  C = e => e === Object(e) && !x(e),
  P = e => {
    if (C(e)) {
      for (var t in e) if (k.call(e, t)) return !1;
      return !0;
    }
    return !1;
  },
  F = e => void 0 === e,
  T = e => "[object String]" == E.call(e),
  R = e => T(e) && 0 === e.trim().length,
  $ = e => null === e,
  O = e => F(e) || $(e),
  M = e => "[object Number]" == E.call(e),
  A = e => "[object Boolean]" === E.call(e),
  L = e => e instanceof FormData,
  D = e => m(g, e),
  q = e => {
    var i = {
      _log: function (i) {
        if (t && (p.DEBUG || _.POSTHOG_DEBUG) && !F(t.console) && t.console) {
          for (var r = ("__rrweb_original__" in t.console[i]) ? t.console[i].__rrweb_original__ : t.console[i], n = arguments.length, s = new Array(n > 1 ? n - 1 : 0), o = 1; o < n; o++) s[o - 1] = arguments[o];
          r(e, ...s);
        }
      },
      info: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("log", ...t);
      },
      warn: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("warn", ...t);
      },
      error: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("error", ...t);
      },
      critical: function () {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        console.error(e, ...i);
      },
      uninitializedWarning: e => {
        i.error("You must initialize PostHog before calling ".concat(e));
      },
      createLogger: t => q("".concat(e, " ").concat(t))
    };
    return i;
  },
  N = q("[PostHog.js]"),
  B = N.createLogger,
  H = B("[ExternalScriptsLoader]"),
  j = (e, t, i) => {
    if (e.config.disable_external_dependency_loading) return H.warn("".concat(t, " was requested but loading of external scripts is disabled.")), i("Loading of external scripts is disabled");
    var r = null == a ? void 0 : a.querySelectorAll("script");
    if (r) for (var n = 0; n < r.length; n++) if (r[n].src === t) return i();
    var s = () => {
      if (!a) return i("document not found");
      var r = a.createElement("script");
      if (r.type = "text/javascript", r.crossOrigin = "anonymous", r.src = t, r.onload = e => i(void 0, e), r.onerror = e => i(e), e.config.prepare_external_dependency_script && (r = e.config.prepare_external_dependency_script(r)), !r) return i("prepare_external_dependency_script returned null");
      var n,
        s = a.querySelectorAll("body > script");
      s.length > 0 ? null === (n = s[0].parentNode) || void 0 === n || n.insertBefore(r, s[0]) : a.body.appendChild(r);
    };
    null != a && a.body ? s() : null == a || a.addEventListener("DOMContentLoaded", s);
  };
function U(e, t) {
  var i = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), i.push.apply(i, r);
  }
  return i;
}
function z(e) {
  for (var t = 1; t < arguments.length; t++) {
    var i = null != arguments[t] ? arguments[t] : {};
    t % 2 ? U(Object(i), !0).forEach(function (t) {
      W(e, t, i[t]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(i)) : U(Object(i)).forEach(function (t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(i, t));
    });
  }
  return e;
}
function W(e, t, i) {
  return t in e ? Object.defineProperty(e, t, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = i, e;
}
function V(e, t) {
  if (null == e) return {};
  var i,
    r,
    n = function (e, t) {
      if (null == e) return {};
      var i,
        r,
        n = {},
        s = Object.keys(e);
      for (r = 0; r < s.length; r++) i = s[r], t.indexOf(i) >= 0 || (n[i] = e[i]);
      return n;
    }(e, t);
  if (Object.getOwnPropertySymbols) {
    var s = Object.getOwnPropertySymbols(e);
    for (r = 0; r < s.length; r++) i = s[r], t.indexOf(i) >= 0 || Object.prototype.propertyIsEnumerable.call(e, i) && (n[i] = e[i]);
  }
  return n;
}
_.__PosthogExtensions__ = _.__PosthogExtensions__ || {}, _.__PosthogExtensions__.loadExternalDependency = (e, t, i) => {
  var r = "/static/".concat(t, ".js") + "?v=".concat(e.version);
  if ("remote-config" === t && (r = "/array/".concat(e.config.token, "/config.js")), "toolbar" === t) {
    var n = 3e5,
      s = Math.floor(Date.now() / n) * n;
    r = "".concat(r, "&t=").concat(s);
  }
  var o = e.requestRouter.endpointFor("assets", r);
  j(e, o, i);
}, _.__PosthogExtensions__.loadSiteApp = (e, t, i) => {
  var r = e.requestRouter.endpointFor("api", t);
  j(e, r, i);
};
var G = {};
function J(e, t, i) {
  if (x(e)) if (n && e.forEach === n) e.forEach(t, i);else if ("length" in e && e.length === +e.length) for (var r = 0, s = e.length; r < s; r++) if (r in e && t.call(i, e[r], r) === G) return;
}
function Y(e, t, i) {
  if (!O(e)) {
    if (x(e)) return J(e, t, i);
    if (L(e)) {
      for (var r of e.entries()) if (t.call(i, r[1], r[0]) === G) return;
    } else for (var n in e) if (k.call(e, n) && t.call(i, e[n], n) === G) return;
  }
}
var K = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    return J(i, function (t) {
      for (var i in t) void 0 !== t[i] && (e[i] = t[i]);
    }), e;
  },
  X = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    return J(i, function (t) {
      J(t, function (t) {
        e.push(t);
      });
    }), e;
  };
function Q(e) {
  for (var t = Object.keys(e), i = t.length, r = new Array(i); i--;) r[i] = [t[i], e[t[i]]];
  return r;
}
var Z = function (e) {
    try {
      return e();
    } catch (e) {
      return;
    }
  },
  ee = function (e) {
    return function () {
      try {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        return e.apply(this, i);
      } catch (e) {
        N.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), N.critical(e);
      }
    };
  },
  te = function (e) {
    var t = {};
    return Y(e, function (e, i) {
      T(e) && e.length > 0 && (t[i] = e);
    }), t;
  };
function ie(e, t) {
  return i = e, r = e => T(e) && !$(t) ? e.slice(0, t) : e, n = new Set(), function e(t, i) {
    return t !== Object(t) ? r ? r(t, i) : t : n.has(t) ? void 0 : (n.add(t), x(t) ? (s = [], J(t, t => {
      s.push(e(t));
    })) : (s = {}, Y(t, (t, i) => {
      n.has(t) || (s[i] = e(t, i));
    })), s);
    var s;
  }(i);
  var i, r, n;
}
var re = ["herokuapp.com", "vercel.app", "netlify.app"];
function ne(e) {
  var t = null == e ? void 0 : e.hostname;
  if (!T(t)) return !1;
  var i = t.split(".").slice(-2).join(".");
  for (var r of re) if (i === r) return !1;
  return !0;
}
function se(e, t) {
  for (var i = 0; i < e.length; i++) if (t(e[i])) return e[i];
}
function oe(e, t, i, r) {
  var {
    capture: n = !1,
    passive: s = !0
  } = null != r ? r : {};
  null == e || e.addEventListener(t, i, {
    capture: n,
    passive: s
  });
}
var ae = "$people_distinct_id",
  le = "__alias",
  ue = "__timers",
  ce = "$autocapture_disabled_server_side",
  de = "$heatmaps_enabled_server_side",
  he = "$exception_capture_enabled_server_side",
  _e = "$web_vitals_enabled_server_side",
  pe = "$dead_clicks_enabled_server_side",
  ve = "$web_vitals_allowed_metrics",
  ge = "$session_recording_enabled_server_side",
  fe = "$console_log_recording_enabled_server_side",
  me = "$session_recording_network_payload_capture",
  ye = "$session_recording_masking",
  be = "$session_recording_canvas_recording",
  we = "$replay_sample_rate",
  Se = "$replay_minimum_duration",
  ke = "$replay_script_config",
  Ee = "$sesid",
  xe = "$session_is_sampled",
  Ie = "$session_recording_url_trigger_activated_session",
  Ce = "$session_recording_event_trigger_activated_session",
  Pe = "$enabled_feature_flags",
  Fe = "$early_access_features",
  Te = "$feature_flag_details",
  Re = "$stored_person_properties",
  $e = "$stored_group_properties",
  Oe = "$surveys",
  Me = "$surveys_activated",
  Ae = "$flag_call_reported",
  Le = "$user_state",
  De = "$client_session_props",
  qe = "$capture_rate_limit",
  Ne = "$initial_campaign_params",
  Be = "$initial_referrer_info",
  He = "$initial_person_info",
  je = "$epp",
  Ue = "__POSTHOG_TOOLBAR__",
  ze = "$posthog_cookieless",
  We = [ae, le, "__cmpns", ue, ge, de, Ee, Pe, Le, Fe, Te, $e, Re, Oe, Ae, De, qe, Ne, Be, je];
function Ve(e) {
  var t;
  return e instanceof Element && (e.id === Ue || !(null === (t = e.closest) || void 0 === t || !t.call(e, ".toolbar-global-fade-container")));
}
function Ge(e) {
  return !!e && 1 === e.nodeType;
}
function Je(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function Ye(e) {
  return !!e && 3 === e.nodeType;
}
function Ke(e) {
  return !!e && 11 === e.nodeType;
}
function Xe(e) {
  return e ? y(e).split(/\s+/) : [];
}
function Qe(e) {
  var i = null == t ? void 0 : t.location.href;
  return !!(i && e && e.some(e => i.match(e)));
}
function Ze(e) {
  var t = "";
  switch (typeof e.className) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = (e.className && "baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return Xe(t);
}
function et(e) {
  return O(e) ? null : y(e).split(/(\s+)/).filter(e => pt(e)).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255);
}
function tt(e) {
  var t = "";
  return ot(e) && !at(e) && e.childNodes && e.childNodes.length && Y(e.childNodes, function (e) {
    var i;
    Ye(e) && e.textContent && (t += null !== (i = et(e.textContent)) && void 0 !== i ? i : "");
  }), y(t);
}
function it(e) {
  return F(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
  var t;
}
var rt = ["a", "button", "form", "input", "select", "textarea", "label"];
function nt(e) {
  var t = e.parentNode;
  return !(!t || !Ge(t)) && t;
}
function st(e, i) {
  var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0,
    n = arguments.length > 3 ? arguments[3] : void 0,
    s = arguments.length > 4 ? arguments[4] : void 0;
  if (!t || !e || Je(e, "html") || !Ge(e)) return !1;
  if (null != r && r.url_allowlist && !Qe(r.url_allowlist)) return !1;
  if (null != r && r.url_ignorelist && Qe(r.url_ignorelist)) return !1;
  if (null != r && r.dom_event_allowlist) {
    var o = r.dom_event_allowlist;
    if (o && !o.some(e => i.type === e)) return !1;
  }
  for (var a = !1, l = [e], u = !0, c = e; c.parentNode && !Je(c, "body");) if (Ke(c.parentNode)) l.push(c.parentNode.host), c = c.parentNode.host;else {
    if (!(u = nt(c))) break;
    if (n || rt.indexOf(u.tagName.toLowerCase()) > -1) a = !0;else {
      var d = t.getComputedStyle(u);
      d && "pointer" === d.getPropertyValue("cursor") && (a = !0);
    }
    l.push(u), c = u;
  }
  if (!function (e, t) {
    var i = null == t ? void 0 : t.element_allowlist;
    if (F(i)) return !0;
    var r = function (e) {
      if (i.some(t => e.tagName.toLowerCase() === t)) return {
        v: !0
      };
    };
    for (var n of e) {
      var s = r(n);
      if ("object" == typeof s) return s.v;
    }
    return !1;
  }(l, r)) return !1;
  if (!function (e, t) {
    var i = null == t ? void 0 : t.css_selector_allowlist;
    if (F(i)) return !0;
    var r = function (e) {
      if (i.some(t => e.matches(t))) return {
        v: !0
      };
    };
    for (var n of e) {
      var s = r(n);
      if ("object" == typeof s) return s.v;
    }
    return !1;
  }(l, r)) return !1;
  var h = t.getComputedStyle(e);
  if (h && "pointer" === h.getPropertyValue("cursor") && "click" === i.type) return !0;
  var _ = e.tagName.toLowerCase();
  switch (_) {
    case "html":
      return !1;
    case "form":
      return (s || ["submit"]).indexOf(i.type) >= 0;
    case "input":
    case "select":
    case "textarea":
      return (s || ["change", "click"]).indexOf(i.type) >= 0;
    default:
      return a ? (s || ["click"]).indexOf(i.type) >= 0 : (s || ["click"]).indexOf(i.type) >= 0 && (rt.indexOf(_) > -1 || "true" === e.getAttribute("contenteditable"));
  }
}
function ot(e) {
  for (var t = e; t.parentNode && !Je(t, "body"); t = t.parentNode) {
    var i = Ze(t);
    if (m(i, "ph-sensitive") || m(i, "ph-no-capture")) return !1;
  }
  if (m(Ze(e), "ph-include")) return !0;
  var r = e.type || "";
  if (T(r)) switch (r.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  var n = e.name || e.id || "";
  if (T(n)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(n.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function at(e) {
  return !!(Je(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || Je(e, "select") || Je(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
var lt = "(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})",
  ut = new RegExp("^(?:".concat(lt, ")$")),
  ct = new RegExp(lt),
  dt = "\\d{3}-?\\d{2}-?\\d{4}",
  ht = new RegExp("^(".concat(dt, ")$")),
  _t = new RegExp("(".concat(dt, ")"));
function pt(e) {
  var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
  if (O(e)) return !1;
  if (T(e)) {
    if (e = y(e), (t ? ut : ct).test((e || "").replace(/[- ]/g, ""))) return !1;
    if ((t ? ht : _t).test(e)) return !1;
  }
  return !0;
}
function vt(e) {
  var t = tt(e);
  return pt(t = "".concat(t, " ").concat(gt(e)).trim()) ? t : "";
}
function gt(e) {
  var t = "";
  return e && e.childNodes && e.childNodes.length && Y(e.childNodes, function (e) {
    var i;
    if (e && "span" === (null === (i = e.tagName) || void 0 === i ? void 0 : i.toLowerCase())) try {
      var r = tt(e);
      t = "".concat(t, " ").concat(r).trim(), e.childNodes && e.childNodes.length && (t = "".concat(t, " ").concat(gt(e)).trim());
    } catch (e) {
      N.error("[AutoCapture]", e);
    }
  }), t;
}
function ft(e) {
  return function (e) {
    var t = e.map(e => {
      var t,
        i,
        r = "";
      if (e.tag_name && (r += e.tag_name), e.attr_class) for (var n of (e.attr_class.sort(), e.attr_class)) r += ".".concat(n.replace(/"/g, ""));
      var s = z(z(z(z({}, e.text ? {
          text: e.text
        } : {}), {}, {
          "nth-child": null !== (t = e.nth_child) && void 0 !== t ? t : 0,
          "nth-of-type": null !== (i = e.nth_of_type) && void 0 !== i ? i : 0
        }, e.href ? {
          href: e.href
        } : {}), e.attr_id ? {
          attr_id: e.attr_id
        } : {}), e.attributes),
        o = {};
      return Q(s).sort((e, t) => {
        var [i] = e,
          [r] = t;
        return i.localeCompare(r);
      }).forEach(e => {
        var [t, i] = e;
        return o[mt(t.toString())] = mt(i.toString());
      }), r += ":", r += Q(s).map(e => {
        var [t, i] = e;
        return "".concat(t, '="').concat(i, '"');
      }).join("");
    });
    return t.join(";");
  }(function (e) {
    return e.map(e => {
      var t,
        i,
        r = {
          text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
          tag_name: e.tag_name,
          href: null === (i = e.attr__href) || void 0 === i ? void 0 : i.slice(0, 2048),
          attr_class: yt(e),
          attr_id: e.attr__id,
          nth_child: e.nth_child,
          nth_of_type: e.nth_of_type,
          attributes: {}
        };
      return Q(e).filter(e => {
        var [t] = e;
        return 0 === t.indexOf("attr__");
      }).forEach(e => {
        var [t, i] = e;
        return r.attributes[t] = i;
      }), r;
    });
  }(e));
}
function mt(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function yt(e) {
  var t = e.attr__class;
  return t ? x(t) ? t : Xe(t) : void 0;
}
class bt {
  constructor() {
    this.clicks = [];
  }
  isRageClick(e, t, i) {
    var r = this.clicks[this.clicks.length - 1];
    if (r && Math.abs(e - r.x) + Math.abs(t - r.y) < 30 && i - r.timestamp < 1e3) {
      if (this.clicks.push({
        x: e,
        y: t,
        timestamp: i
      }), 3 === this.clicks.length) return !0;
    } else this.clicks = [{
      x: e,
      y: t,
      timestamp: i
    }];
    return !1;
  }
}
var wt = ["localhost", "127.0.0.1"],
  St = e => {
    var t = null == a ? void 0 : a.createElement("a");
    return F(t) ? null : (t.href = e, t);
  },
  kt = function (e) {
    var t,
      i,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&",
      n = [];
    return Y(e, function (e, r) {
      F(e) || F(r) || "undefined" === r || (t = encodeURIComponent((e => e instanceof File)(e) ? e.name : e.toString()), i = encodeURIComponent(r), n[n.length] = i + "=" + t);
    }), n.join(r);
  },
  Et = function (e, t) {
    for (var i, r = ((e.split("#")[0] || "").split(/\?(.*)/)[1] || "").replace(/^\?+/g, "").split("&"), n = 0; n < r.length; n++) {
      var s = r[n].split("=");
      if (s[0] === t) {
        i = s;
        break;
      }
    }
    if (!x(i) || i.length < 2) return "";
    var o = i[1];
    try {
      o = decodeURIComponent(o);
    } catch (e) {
      N.error("Skipping decoding for malformed query param: " + o);
    }
    return o.replace(/\+/g, " ");
  },
  xt = function (e, t, i) {
    if (!e || !t || !t.length) return e;
    for (var r = e.split("#"), n = r[0] || "", s = r[1], o = n.split("?"), a = o[1], l = o[0], u = (a || "").split("&"), c = [], d = 0; d < u.length; d++) {
      var h = u[d].split("=");
      x(h) && (t.includes(h[0]) ? c.push(h[0] + "=" + i) : c.push(u[d]));
    }
    var _ = l;
    return null != a && (_ += "?" + c.join("&")), null != s && (_ += "#" + s), _;
  },
  It = function (e, t) {
    var i = e.match(new RegExp(t + "=([^&]*)"));
    return i ? i[1] : null;
  },
  Ct = B("[AutoCapture]");
function Pt(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
function Ft(e) {
  if (e.previousElementSibling) return e.previousElementSibling;
  var t = e;
  do {
    t = t.previousSibling;
  } while (t && !Ge(t));
  return t;
}
function Tt(e, t, i, r) {
  var n = e.tagName.toLowerCase(),
    s = {
      tag_name: n
    };
  rt.indexOf(n) > -1 && !i && ("a" === n.toLowerCase() || "button" === n.toLowerCase() ? s.$el_text = Pt(1024, vt(e)) : s.$el_text = Pt(1024, tt(e)));
  var o = Ze(e);
  o.length > 0 && (s.classes = o.filter(function (e) {
    return "" !== e;
  })), Y(e.attributes, function (i) {
    var n;
    if ((!at(e) || -1 !== ["name", "id", "class", "aria-label"].indexOf(i.name)) && (null == r || !r.includes(i.name)) && !t && pt(i.value) && (n = i.name, !T(n) || "_ngcontent" !== n.substring(0, 10) && "_nghost" !== n.substring(0, 7))) {
      var o = i.value;
      "class" === i.name && (o = Xe(o).join(" ")), s["attr__" + i.name] = Pt(1024, o);
    }
  });
  for (var a = 1, l = 1, u = e; u = Ft(u);) a++, u.tagName === e.tagName && l++;
  return s.nth_child = a, s.nth_of_type = l, s;
}
function Rt(e, i) {
  for (var r, n, {
      e: s,
      maskAllElementAttributes: o,
      maskAllText: a,
      elementAttributeIgnoreList: l,
      elementsChainAsString: u
    } = i, c = [e], d = e; d.parentNode && !Je(d, "body");) Ke(d.parentNode) ? (c.push(d.parentNode.host), d = d.parentNode.host) : (c.push(d.parentNode), d = d.parentNode);
  var h,
    _ = [],
    p = {},
    v = !1,
    g = !1;
  if (Y(c, e => {
    var t = ot(e);
    "a" === e.tagName.toLowerCase() && (v = e.getAttribute("href"), v = t && v && pt(v) && v), m(Ze(e), "ph-no-capture") && (g = !0), _.push(Tt(e, o, a, l));
    var i = function (e) {
      if (!ot(e)) return {};
      var t = {};
      return Y(e.attributes, function (e) {
        if (e.name && 0 === e.name.indexOf("data-ph-capture-attribute")) {
          var i = e.name.replace("data-ph-capture-attribute-", ""),
            r = e.value;
          i && r && pt(r) && (t[i] = r);
        }
      }), t;
    }(e);
    K(p, i);
  }), g) return {
    props: {},
    explicitNoCapture: g
  };
  if (a || ("a" === e.tagName.toLowerCase() || "button" === e.tagName.toLowerCase() ? _[0].$el_text = vt(e) : _[0].$el_text = tt(e)), v) {
    var f, y;
    _[0].attr__href = v;
    var b = null === (f = St(v)) || void 0 === f ? void 0 : f.host,
      w = null == t || null === (y = t.location) || void 0 === y ? void 0 : y.host;
    b && w && b !== w && (h = v);
  }
  return {
    props: K({
      $event_type: s.type,
      $ce_version: 1
    }, u ? {} : {
      $elements: _
    }, {
      $elements_chain: ft(_)
    }, null !== (r = _[0]) && void 0 !== r && r.$el_text ? {
      $el_text: null === (n = _[0]) || void 0 === n ? void 0 : n.$el_text
    } : {}, h && "click" === s.type ? {
      $external_click_url: h
    } : {}, p)
  };
}
class $t {
  constructor(e) {
    W(this, "_initialized", !1), W(this, "_isDisabledServerSide", null), W(this, "rageclicks", new bt()), W(this, "_elementsChainAsString", !1), this.instance = e, this._elementSelectors = null;
  }
  get config() {
    var e,
      t,
      i = C(this.instance.config.autocapture) ? this.instance.config.autocapture : {};
    return i.url_allowlist = null === (e = i.url_allowlist) || void 0 === e ? void 0 : e.map(e => new RegExp(e)), i.url_ignorelist = null === (t = i.url_ignorelist) || void 0 === t ? void 0 : t.map(e => new RegExp(e)), i;
  }
  _addDomEventHandlers() {
    if (this.isBrowserSupported()) {
      if (t && a) {
        var e = e => {
          e = e || (null == t ? void 0 : t.event);
          try {
            this._captureEvent(e);
          } catch (e) {
            Ct.error("Failed to capture event", e);
          }
        };
        if (oe(a, "submit", e, {
          capture: !0
        }), oe(a, "change", e, {
          capture: !0
        }), oe(a, "click", e, {
          capture: !0
        }), this.config.capture_copied_text) {
          var i = e => {
            e = e || (null == t ? void 0 : t.event), this._captureEvent(e, v);
          };
          oe(a, "copy", i, {
            capture: !0
          }), oe(a, "cut", i, {
            capture: !0
          });
        }
      }
    } else Ct.info("Disabling Automatic Event Collection because this browser is not supported");
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (this._addDomEventHandlers(), this._initialized = !0);
  }
  onRemoteConfig(e) {
    e.elementsChainAsString && (this._elementsChainAsString = e.elementsChainAsString), this.instance.persistence && this.instance.persistence.register({
      [ce]: !!e.autocapture_opt_out
    }), this._isDisabledServerSide = !!e.autocapture_opt_out, this.startIfEnabled();
  }
  setElementSelectors(e) {
    this._elementSelectors = e;
  }
  getElementSelectors(e) {
    var t,
      i = [];
    return null === (t = this._elementSelectors) || void 0 === t || t.forEach(t => {
      var r = null == a ? void 0 : a.querySelectorAll(t);
      null == r || r.forEach(r => {
        e === r && i.push(t);
      });
    }), i;
  }
  get isEnabled() {
    var e,
      t,
      i = null === (e = this.instance.persistence) || void 0 === e ? void 0 : e.props[ce],
      r = this._isDisabledServerSide;
    if ($(r) && !A(i) && !this.instance.config.advanced_disable_decide) return !1;
    var n = null !== (t = this._isDisabledServerSide) && void 0 !== t ? t : !!i;
    return !!this.instance.config.autocapture && !n;
  }
  _captureEvent(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "$autocapture";
    if (this.isEnabled) {
      var r,
        n = it(e);
      if (Ye(n) && (n = n.parentNode || null), "$autocapture" === i && "click" === e.type && e instanceof MouseEvent) this.instance.config.rageclick && null !== (r = this.rageclicks) && void 0 !== r && r.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._captureEvent(e, "$rageclick");
      var s = i === v;
      if (n && st(n, e, this.config, s, s ? ["copy", "cut"] : void 0)) {
        var {
          props: o,
          explicitNoCapture: a
        } = Rt(n, {
          e: e,
          maskAllElementAttributes: this.instance.config.mask_all_element_attributes,
          maskAllText: this.instance.config.mask_all_text,
          elementAttributeIgnoreList: this.config.element_attribute_ignorelist,
          elementsChainAsString: this._elementsChainAsString
        });
        if (a) return !1;
        var l = this.getElementSelectors(n);
        if (l && l.length > 0 && (o.$element_selectors = l), i === v) {
          var u,
            c = et(null == t || null === (u = t.getSelection()) || void 0 === u ? void 0 : u.toString()),
            d = e.type || "clipboard";
          if (!c) return !1;
          o.$selected_content = c, o.$copy_type = d;
        }
        return this.instance.capture(i, o), !0;
      }
    }
  }
  isBrowserSupported() {
    return I(null == a ? void 0 : a.querySelectorAll);
  }
}
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return M(e) && isFinite(e) && Math.floor(e) === e;
});
var Ot = "0123456789abcdef";
class Mt {
  constructor(e) {
    if (this.bytes = e, 16 !== e.length) throw new TypeError("not 128-bit length");
  }
  static fromFieldsV7(e, t, i, r) {
    if (!Number.isInteger(e) || !Number.isInteger(t) || !Number.isInteger(i) || !Number.isInteger(r) || e < 0 || t < 0 || i < 0 || r < 0 || e > 0xffffffffffff || t > 4095 || i > 1073741823 || r > 4294967295) throw new RangeError("invalid field value");
    var n = new Uint8Array(16);
    return n[0] = e / Math.pow(2, 40), n[1] = e / Math.pow(2, 32), n[2] = e / Math.pow(2, 24), n[3] = e / Math.pow(2, 16), n[4] = e / Math.pow(2, 8), n[5] = e, n[6] = 112 | t >>> 8, n[7] = t, n[8] = 128 | i >>> 24, n[9] = i >>> 16, n[10] = i >>> 8, n[11] = i, n[12] = r >>> 24, n[13] = r >>> 16, n[14] = r >>> 8, n[15] = r, new Mt(n);
  }
  toString() {
    for (var e = "", t = 0; t < this.bytes.length; t++) e = e + Ot.charAt(this.bytes[t] >>> 4) + Ot.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
    if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
    return e;
  }
  clone() {
    return new Mt(this.bytes.slice(0));
  }
  equals(e) {
    return 0 === this.compareTo(e);
  }
  compareTo(e) {
    for (var t = 0; t < 16; t++) {
      var i = this.bytes[t] - e.bytes[t];
      if (0 !== i) return Math.sign(i);
    }
    return 0;
  }
}
class At {
  constructor() {
    W(this, "timestamp", 0), W(this, "counter", 0), W(this, "random", new qt());
  }
  generate() {
    var e = this.generateOrAbort();
    if (F(e)) {
      this.timestamp = 0;
      var t = this.generateOrAbort();
      if (F(t)) throw new Error("Could not generate UUID after timestamp reset");
      return t;
    }
    return e;
  }
  generateOrAbort() {
    var e = Date.now();
    if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
      if (!(e + 1e4 > this.timestamp)) return;
      this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
    }
    return Mt.fromFieldsV7(this.timestamp, Math.trunc(this.counter / Math.pow(2, 30)), this.counter & Math.pow(2, 30) - 1, this.random.nextUint32());
  }
  resetCounter() {
    this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
  }
}
var Lt,
  Dt = e => {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (var t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
t && !F(t.crypto) && crypto.getRandomValues && (Dt = e => crypto.getRandomValues(e));
class qt {
  constructor() {
    W(this, "buffer", new Uint32Array(8)), W(this, "cursor", 1 / 0);
  }
  nextUint32() {
    return this.cursor >= this.buffer.length && (Dt(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
  }
}
var Nt = () => Bt().toString(),
  Bt = () => (Lt || (Lt = new At())).generate(),
  Ht = "Thu, 01 Jan 1970 00:00:00 GMT",
  jt = "";
var Ut = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function zt(e, t) {
  if (t) {
    var i = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : a;
      if (jt) return jt;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      for (var i = e.split("."), r = Math.min(i.length, 8), n = "dmn_chk_" + Nt(), s = new RegExp("(^|;)\\s*" + n + "=1"); !jt && r--;) {
        var o = i.slice(r).join("."),
          l = n + "=1;domain=." + o;
        t.cookie = l, s.test(t.cookie) && (t.cookie = l + ";expires=" + Ht, jt = o);
      }
      return jt;
    }(e);
    if (!i) {
      var r = (e => {
        var t = e.match(Ut);
        return t ? t[0] : "";
      })(e);
      r !== i && N.info("Warning: cookie subdomain discovery mismatch", r, i), i = r;
    }
    return i ? "; domain=." + i : "";
  }
  return "";
}
var Wt,
  Vt = {
    is_supported: () => !!a,
    error: function (e) {
      N.error("cookieStore error: " + e);
    },
    get: function (e) {
      if (a) {
        try {
          for (var t = e + "=", i = a.cookie.split(";").filter(e => e.length), r = 0; r < i.length; r++) {
            for (var n = i[r]; " " == n.charAt(0);) n = n.substring(1, n.length);
            if (0 === n.indexOf(t)) return decodeURIComponent(n.substring(t.length, n.length));
          }
        } catch (e) {}
        return null;
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(Vt.get(e)) || {};
      } catch (e) {}
      return t;
    },
    set: function (e, t, i, r, n) {
      if (a) try {
        var s = "",
          o = "",
          l = zt(a.location.hostname, r);
        if (i) {
          var u = new Date();
          u.setTime(u.getTime() + 24 * i * 60 * 60 * 1e3), s = "; expires=" + u.toUTCString();
        }
        n && (o = "; secure");
        var c = e + "=" + encodeURIComponent(JSON.stringify(t)) + s + "; SameSite=Lax; path=/" + l + o;
        return c.length > 3686.4 && N.warn("cookieStore warning: large cookie, len=" + c.length), a.cookie = c, c;
      } catch (e) {
        return;
      }
    },
    remove: function (e, t) {
      try {
        Vt.set(e, "", -1, t);
      } catch (e) {
        return;
      }
    }
  },
  Gt = null,
  Jt = {
    is_supported: function () {
      if (!$(Gt)) return Gt;
      var e = !0;
      if (F(t)) e = !1;else try {
        var i = "__mplssupport__";
        Jt.set(i, "xyz"), '"xyz"' !== Jt.get(i) && (e = !1), Jt.remove(i);
      } catch (t) {
        e = !1;
      }
      return e || N.error("localStorage unsupported; falling back to cookie store"), Gt = e, e;
    },
    error: function (e) {
      N.error("localStorage error: " + e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.localStorage.getItem(e);
      } catch (e) {
        Jt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(Jt.get(e)) || {};
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.localStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        Jt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.localStorage.removeItem(e);
      } catch (e) {
        Jt.error(e);
      }
    }
  },
  Yt = ["distinct_id", Ee, xe, je, He],
  Kt = z(z({}, Jt), {}, {
    parse: function (e) {
      try {
        var t = {};
        try {
          t = Vt.parse(e) || {};
        } catch (e) {}
        var i = K(t, JSON.parse(Jt.get(e) || "{}"));
        return Jt.set(e, i), i;
      } catch (e) {}
      return null;
    },
    set: function (e, t, i, r, n, s) {
      try {
        Jt.set(e, t, void 0, void 0, s);
        var o = {};
        Yt.forEach(e => {
          t[e] && (o[e] = t[e]);
        }), Object.keys(o).length && Vt.set(e, o, i, r, n, s);
      } catch (e) {
        Jt.error(e);
      }
    },
    remove: function (e, i) {
      try {
        null == t || t.localStorage.removeItem(e), Vt.remove(e, i);
      } catch (e) {
        Jt.error(e);
      }
    }
  }),
  Xt = {},
  Qt = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      N.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return Xt[e] || null;
    },
    parse: function (e) {
      return Xt[e] || null;
    },
    set: function (e, t) {
      Xt[e] = t;
    },
    remove: function (e) {
      delete Xt[e];
    }
  },
  Zt = null,
  ei = {
    is_supported: function () {
      if (!$(Zt)) return Zt;
      if (Zt = !0, F(t)) Zt = !1;else try {
        var e = "__support__";
        ei.set(e, "xyz"), '"xyz"' !== ei.get(e) && (Zt = !1), ei.remove(e);
      } catch (e) {
        Zt = !1;
      }
      return Zt;
    },
    error: function (e) {
      N.error("sessionStorage error: ", e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.sessionStorage.getItem(e);
      } catch (e) {
        ei.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(ei.get(e)) || null;
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.sessionStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        ei.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.sessionStorage.removeItem(e);
      } catch (e) {
        ei.error(e);
      }
    }
  };
!function (e) {
  e[e.PENDING = -1] = "PENDING", e[e.DENIED = 0] = "DENIED", e[e.GRANTED = 1] = "GRANTED";
}(Wt || (Wt = {}));
class ti {
  constructor(e) {
    this.instance = e;
  }
  get config() {
    return this.instance.config;
  }
  get consent() {
    return this.getDnt() ? Wt.DENIED : this.storedConsent;
  }
  isOptedOut() {
    return this.consent === Wt.DENIED || this.consent === Wt.PENDING && this.config.opt_out_capturing_by_default;
  }
  isOptedIn() {
    return !this.isOptedOut();
  }
  optInOut(e) {
    this.storage.set(this.storageKey, e ? 1 : 0, this.config.cookie_expiration, this.config.cross_subdomain_cookie, this.config.secure_cookie);
  }
  reset() {
    this.storage.remove(this.storageKey, this.config.cross_subdomain_cookie);
  }
  get storageKey() {
    var {
      token: e,
      opt_out_capturing_cookie_prefix: t
    } = this.instance.config;
    return (t || "__ph_opt_in_out_") + e;
  }
  get storedConsent() {
    var e = this.storage.get(this.storageKey);
    return "1" === e ? Wt.GRANTED : "0" === e ? Wt.DENIED : Wt.PENDING;
  }
  get storage() {
    if (!this._storage) {
      var e = this.config.opt_out_capturing_persistence_type;
      this._storage = "localStorage" === e ? Jt : Vt;
      var t = "localStorage" === e ? Vt : Jt;
      t.get(this.storageKey) && (this._storage.get(this.storageKey) || this.optInOut("1" === t.get(this.storageKey)), t.remove(this.storageKey, this.config.cross_subdomain_cookie));
    }
    return this._storage;
  }
  getDnt() {
    return !!this.config.respect_dnt && !!se([null == o ? void 0 : o.doNotTrack, null == o ? void 0 : o.msDoNotTrack, _.doNotTrack], e => m([!0, 1, "1", "yes"], e));
  }
}
var ii = B("[Dead Clicks]"),
  ri = () => !0,
  ni = e => {
    var t,
      i = !(null === (t = e.instance.persistence) || void 0 === t || !t.get_property(pe)),
      r = e.instance.config.capture_dead_clicks;
    return A(r) ? r : i;
  };
class si {
  get lazyLoadedDeadClicksAutocapture() {
    return this._lazyLoadedDeadClicksAutocapture;
  }
  constructor(e, t, i) {
    this.instance = e, this.isEnabled = t, this.onCapture = i, this.startIfEnabled();
  }
  onRemoteConfig(e) {
    this.instance.persistence && this.instance.persistence.register({
      [pe]: null == e ? void 0 : e.captureDeadClicks
    }), this.startIfEnabled();
  }
  startIfEnabled() {
    this.isEnabled(this) && this.loadScript(() => {
      this.start();
    });
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.initDeadClicksAutocapture && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "dead-clicks-autocapture", t => {
      t ? ii.error("failed to load script", t) : e();
    });
  }
  start() {
    var e;
    if (a) {
      if (!this._lazyLoadedDeadClicksAutocapture && null !== (e = _.__PosthogExtensions__) && void 0 !== e && e.initDeadClicksAutocapture) {
        var t = C(this.instance.config.capture_dead_clicks) ? this.instance.config.capture_dead_clicks : {};
        t.__onCapture = this.onCapture, this._lazyLoadedDeadClicksAutocapture = _.__PosthogExtensions__.initDeadClicksAutocapture(this.instance, t), this._lazyLoadedDeadClicksAutocapture.start(a), ii.info("starting...");
      }
    } else ii.error("`document` not found. Cannot start.");
  }
  stop() {
    this._lazyLoadedDeadClicksAutocapture && (this._lazyLoadedDeadClicksAutocapture.stop(), this._lazyLoadedDeadClicksAutocapture = void 0, ii.info("stopping..."));
  }
}
var oi = B("[ExceptionAutocapture]");
class ai {
  constructor(e) {
    var i;
    W(this, "startCapturing", () => {
      var e;
      if (t && this.isEnabled && null !== (e = _.__PosthogExtensions__) && void 0 !== e && e.errorWrappingFunctions) {
        var i = _.__PosthogExtensions__.errorWrappingFunctions.wrapOnError,
          r = _.__PosthogExtensions__.errorWrappingFunctions.wrapUnhandledRejection,
          n = _.__PosthogExtensions__.errorWrappingFunctions.wrapConsoleError;
        try {
          !this.unwrapOnError && this.config.capture_unhandled_errors && (this.unwrapOnError = i(this.captureException.bind(this))), !this.unwrapUnhandledRejection && this.config.capture_unhandled_rejections && (this.unwrapUnhandledRejection = r(this.captureException.bind(this))), !this.unwrapConsoleError && this.config.capture_console_errors && (this.unwrapConsoleError = n(this.captureException.bind(this)));
        } catch (e) {
          oi.error("failed to start", e), this.stopCapturing();
        }
      }
    }), this.instance = e, this.remoteEnabled = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[he]), this.config = this.requiredConfig(), this.startIfEnabled();
  }
  requiredConfig() {
    var e = this.instance.config.capture_exceptions,
      t = {
        capture_unhandled_errors: !1,
        capture_unhandled_rejections: !1,
        capture_console_errors: !1
      };
    return C(e) ? t = z(z({}, t), e) : (F(e) ? this.remoteEnabled : e) && (t = z(z({}, t), {}, {
      capture_unhandled_errors: !0,
      capture_unhandled_rejections: !0
    })), t;
  }
  get isEnabled() {
    return this.config.capture_console_errors || this.config.capture_unhandled_errors || this.config.capture_unhandled_rejections;
  }
  startIfEnabled() {
    this.isEnabled && (oi.info("enabled"), this.loadScript(this.startCapturing));
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.errorWrappingFunctions && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "exception-autocapture", t => {
      if (t) return oi.error("failed to load script", t);
      e();
    });
  }
  stopCapturing() {
    var e, t, i;
    null === (e = this.unwrapOnError) || void 0 === e || e.call(this), this.unwrapOnError = void 0, null === (t = this.unwrapUnhandledRejection) || void 0 === t || t.call(this), this.unwrapUnhandledRejection = void 0, null === (i = this.unwrapConsoleError) || void 0 === i || i.call(this), this.unwrapConsoleError = void 0;
  }
  onRemoteConfig(e) {
    var t = e.autocaptureExceptions;
    this.remoteEnabled = !!t || !1, this.config = this.requiredConfig(), this.instance.persistence && this.instance.persistence.register({
      [he]: this.remoteEnabled
    }), this.startIfEnabled();
  }
  captureException(e) {
    var t = this.instance.requestRouter.endpointFor("ui");
    e.$exception_personURL = "".concat(t, "/project/").concat(this.instance.config.token, "/person/").concat(this.instance.get_distinct_id()), this.instance.exceptions.sendExceptionEvent(e);
  }
}
function li(e) {
  return !F(Event) && ui(e, Event);
}
function ui(e, t) {
  try {
    return e instanceof t;
  } catch (e) {
    return !1;
  }
}
function ci(e, t) {
  return Object.prototype.toString.call(e) === "[object ".concat(t, "]");
}
function di(e) {
  return ci(e, "DOMError");
}
var hi = /\(error: (.*)\)/,
  _i = 50,
  pi = "?";
function vi(e, t, i, r) {
  var n = {
    platform: "web:javascript",
    filename: e,
    function: "<anonymous>" === t ? pi : t,
    in_app: !0
  };
  return F(i) || (n.lineno = i), F(r) || (n.colno = r), n;
}
var gi = /^\s*at (\S+?)(?::(\d+))(?::(\d+))\s*$/i,
  fi = /^\s*at (?:(.+?\)(?: \[.+\])?|.*?) ?\((?:address at )?)?(?:async )?((?:<anonymous>|[-a-z]+:|.*bundle|\/)?.*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i,
  mi = /\((\S*)(?::(\d+))(?::(\d+))\)/,
  yi = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)?((?:[-a-z]+)?:\/.*?|\[native code\]|[^@]*(?:bundle|\d+\.js)|\/[\w\-. /=]+)(?::(\d+))?(?::(\d+))?\s*$/i,
  bi = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i,
  wi = function () {
    for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
    var r = t.sort((e, t) => e[0] - t[0]).map(e => e[1]);
    return function (e) {
      for (var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0, i = [], n = e.split("\n"), s = t; s < n.length; s++) {
        var o = n[s];
        if (!(o.length > 1024)) {
          var a = hi.test(o) ? o.replace(hi, "$1") : o;
          if (!a.match(/\S*Error: /)) {
            for (var l of r) {
              var u = l(a);
              if (u) {
                i.push(u);
                break;
              }
            }
            if (i.length >= _i) break;
          }
        }
      }
      return function (e) {
        if (!e.length) return [];
        var t = Array.from(e);
        return t.reverse(), t.slice(0, _i).map(e => z(z({}, e), {}, {
          filename: e.filename || Si(t).filename,
          function: e.function || pi
        }));
      }(i);
    };
  }(...[[30, e => {
    var t = gi.exec(e);
    if (t) {
      var [, i, r, n] = t;
      return vi(i, pi, +r, +n);
    }
    var s = fi.exec(e);
    if (s) {
      if (s[2] && 0 === s[2].indexOf("eval")) {
        var o = mi.exec(s[2]);
        o && (s[2] = o[1], s[3] = o[2], s[4] = o[3]);
      }
      var [a, l] = Ii(s[1] || pi, s[2]);
      return vi(l, a, s[3] ? +s[3] : void 0, s[4] ? +s[4] : void 0);
    }
  }], [50, e => {
    var t = yi.exec(e);
    if (t) {
      if (t[3] && t[3].indexOf(" > eval") > -1) {
        var i = bi.exec(t[3]);
        i && (t[1] = t[1] || "eval", t[3] = i[1], t[4] = i[2], t[5] = "");
      }
      var r = t[3],
        n = t[1] || pi;
      return [n, r] = Ii(n, r), vi(r, n, t[4] ? +t[4] : void 0, t[5] ? +t[5] : void 0);
    }
  }]]);
function Si(e) {
  return e[e.length - 1] || {};
}
var ki,
  Ei,
  xi,
  Ii = (e, t) => {
    var i = -1 !== e.indexOf("safari-extension"),
      r = -1 !== e.indexOf("safari-web-extension");
    return i || r ? [-1 !== e.indexOf("@") ? e.split("@")[0] : pi, i ? "safari-extension:".concat(t) : "safari-web-extension:".concat(t)] : [e, t];
  };
var Ci = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?(.*)$/i;
function Pi(e) {
  var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
    i = e.stacktrace || e.stack || "",
    r = function (e) {
      if (e && Fi.test(e.message)) return 1;
      return 0;
    }(e);
  try {
    var n = wi,
      s = function (e, t) {
        var i = function (e) {
          var t = globalThis._posthogChunkIds;
          if (!t) return {};
          var i = Object.keys(t);
          return xi && i.length === Ei || (Ei = i.length, xi = i.reduce((i, r) => {
            ki || (ki = {});
            var n = ki[r];
            if (n) i[n[0]] = n[1];else for (var s = e(r), o = s.length - 1; o >= 0; o--) {
              var a = s[o],
                l = null == a ? void 0 : a.filename,
                u = t[r];
              if (l && u) {
                i[l] = u, ki[r] = [l, u];
                break;
              }
            }
            return i;
          }, {})), xi;
        }(t);
        return e.forEach(e => {
          e.filename && (e.chunk_id = i[e.filename]);
        }), e;
      }(n(i, r), n);
    return s.slice(0, s.length - t);
  } catch (e) {}
  return [];
}
var Fi = /Minified React error #\d+;/i;
function Ti(e, t) {
  var i,
    r,
    n = Pi(e),
    s = null === (i = null == t ? void 0 : t.handled) || void 0 === i || i,
    o = null !== (r = null == t ? void 0 : t.synthetic) && void 0 !== r && r;
  return {
    $exception_list: [{
      type: null != t && t.overrideExceptionType ? t.overrideExceptionType : e.name,
      value: function (e) {
        var t = e.message;
        if (t.error && "string" == typeof t.error.message) return String(t.error.message);
        return String(t);
      }(e),
      stacktrace: {
        frames: n,
        type: "raw"
      },
      mechanism: {
        handled: s,
        synthetic: o
      }
    }],
    $exception_level: "error"
  };
}
function Ri(e, t) {
  var i,
    r,
    n,
    s = null === (i = null == t ? void 0 : t.handled) || void 0 === i || i,
    o = null === (r = null == t ? void 0 : t.synthetic) || void 0 === r || r,
    a = {
      type: null != t && t.overrideExceptionType ? t.overrideExceptionType : null !== (n = null == t ? void 0 : t.defaultExceptionType) && void 0 !== n ? n : "Error",
      value: e || (null == t ? void 0 : t.defaultExceptionMessage),
      mechanism: {
        handled: s,
        synthetic: o
      }
    };
  if (null != t && t.syntheticException) {
    var l = Pi(t.syntheticException, 1);
    l.length && (a.stacktrace = {
      frames: l,
      type: "raw"
    });
  }
  return {
    $exception_list: [a],
    $exception_level: "error"
  };
}
function $i(e) {
  return T(e) && !R(e) && f.indexOf(e) >= 0;
}
function Oi(e, t) {
  var i,
    r,
    n = null === (i = null == t ? void 0 : t.handled) || void 0 === i || i,
    s = null === (r = null == t ? void 0 : t.synthetic) || void 0 === r || r,
    o = null != t && t.overrideExceptionType ? t.overrideExceptionType : li(e) ? e.constructor.name : "Error",
    a = "Non-Error 'exception' captured with keys: ".concat(function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 40,
        i = Object.keys(e);
      if (i.sort(), !i.length) return "[object has no keys]";
      for (var r = i.length; r > 0; r--) {
        var n = i.slice(0, r).join(", ");
        if (!(n.length > t)) return r === i.length || n.length <= t ? n : "".concat(n.slice(0, t), "...");
      }
      return "";
    }(e)),
    l = {
      type: o,
      value: a,
      mechanism: {
        handled: n,
        synthetic: s
      }
    };
  if (null != t && t.syntheticException) {
    var u = Pi(null == t ? void 0 : t.syntheticException, 1);
    u.length && (l.stacktrace = {
      frames: u,
      type: "raw"
    });
  }
  return {
    $exception_list: [l],
    $exception_level: $i(e.level) ? e.level : "error"
  };
}
function Mi(e, t) {
  var {
      error: i,
      event: r
    } = e,
    n = {
      $exception_list: []
    },
    s = i || r;
  if (di(s) || function (e) {
    return ci(e, "DOMException");
  }(s)) {
    var o = s;
    if (function (e) {
      return "stack" in e;
    }(s)) n = Ti(s, t);else {
      var a = o.name || (di(o) ? "DOMError" : "DOMException"),
        l = o.message ? "".concat(a, ": ").concat(o.message) : a,
        u = di(o) ? "DOMError" : "DOMException";
      n = Ri(l, z(z({}, t), {}, {
        overrideExceptionType: u,
        defaultExceptionMessage: l
      }));
    }
    return "code" in o && (n.$exception_DOMException_code = "".concat(o.code)), n;
  }
  if (function (e) {
    return ci(e, "ErrorEvent");
  }(s) && s.error) return Ti(s.error, t);
  if (function (e) {
    switch (Object.prototype.toString.call(e)) {
      case "[object Error]":
      case "[object Exception]":
      case "[object DOMException]":
      case "[object DOMError]":
        return !0;
      default:
        return ui(e, Error);
    }
  }(s)) return Ti(s, t);
  if (function (e) {
    return ci(e, "Object");
  }(s) || li(s)) return Oi(s, t);
  if (F(i) && T(r)) {
    var c = "Error",
      d = r,
      h = r.match(Ci);
    return h && (c = h[1], d = h[2]), Ri(d, z(z({}, t), {}, {
      overrideExceptionType: c,
      defaultExceptionMessage: d
    }));
  }
  return Ri(s, t);
}
function Ai(e) {
  var t, i;
  return (null === (t = JSON.stringify(e, (i = [], function (e, t) {
    if (C(t)) {
      for (; i.length > 0 && i[i.length - 1] !== this;) i.pop();
      return i.includes(t) ? "[Circular]" : (i.push(t), t);
    }
    return t;
  }))) || void 0 === t ? void 0 : t.length) || 0;
}
function Li(e) {
  var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 6606028.8;
  if (e.size >= t && e.data.length > 1) {
    var i = Math.floor(e.data.length / 2),
      r = e.data.slice(0, i),
      n = e.data.slice(i);
    return [Li({
      size: Ai(r),
      data: r,
      sessionId: e.sessionId,
      windowId: e.windowId
    }), Li({
      size: Ai(n),
      data: n,
      sessionId: e.sessionId,
      windowId: e.windowId
    })].flatMap(e => e);
  }
  return [e];
}
var Di = (e => (e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e))(Di || {}),
  qi = (e => (e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet", e[e.CustomElement = 16] = "CustomElement", e))(qi || {}),
  Ni = "[SessionRecording]",
  Bi = "redacted",
  Hi = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: e => e,
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6,
    payloadHostDenyList: [".lr-ingest.io", ".ingest.sentry.io", ".clarity.ms", "analytics.google.com"]
  },
  ji = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  Ui = ["password", "secret", "passwd", "api_key", "apikey", "auth", "credentials", "mysql_pwd", "privatekey", "private_key", "token"],
  zi = ["/s/", "/e/", "/i/"];
function Wi(e, t, i, r) {
  if (O(e)) return e;
  var n = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return T(n) && (n = parseInt(n)), n > i ? Ni + " ".concat(r, " body too large to record (").concat(n, " bytes)") : e;
}
function Vi(e, t) {
  if (O(e)) return e;
  var i = e;
  return pt(i, !1) || (i = Ni + " " + t + " body " + Bi), Y(Ui, e => {
    var r, n;
    null !== (r = i) && void 0 !== r && r.length && -1 !== (null === (n = i) || void 0 === n ? void 0 : n.indexOf(e)) && (i = Ni + " " + t + " body " + Bi + " as might contain: " + e);
  }), i;
}
var Gi = (e, t) => {
  var i,
    r,
    n,
    s = {
      payloadSizeLimitBytes: Hi.payloadSizeLimitBytes,
      performanceEntryTypeToObserve: [...Hi.performanceEntryTypeToObserve],
      payloadHostDenyList: [...(t.payloadHostDenyList || []), ...Hi.payloadHostDenyList]
    },
    o = !1 !== e.session_recording.recordHeaders && t.recordHeaders,
    a = !1 !== e.session_recording.recordBody && t.recordBody,
    l = !1 !== e.capture_performance && t.recordPerformance,
    u = (i = s, n = Math.min(1e6, null !== (r = i.payloadSizeLimitBytes) && void 0 !== r ? r : 1e6), e => (null != e && e.requestBody && (e.requestBody = Wi(e.requestBody, e.requestHeaders, n, "Request")), null != e && e.responseBody && (e.responseBody = Wi(e.responseBody, e.responseHeaders, n, "Response")), e)),
    c = t => {
      return u(((e, t) => {
        var i,
          r = St(e.name),
          n = 0 === t.indexOf("http") ? null === (i = St(t)) || void 0 === i ? void 0 : i.pathname : t;
        "/" === n && (n = "");
        var s = null == r ? void 0 : r.pathname.replace(n || "", "");
        if (!(r && s && zi.some(e => 0 === s.indexOf(e)))) return e;
      })((r = (i = t).requestHeaders, O(r) || Y(Object.keys(null != r ? r : {}), e => {
        ji.includes(e.toLowerCase()) && (r[e] = Bi);
      }), i), e.api_host));
      var i, r;
    },
    d = I(e.session_recording.maskNetworkRequestFn);
  return d && I(e.session_recording.maskCapturedNetworkRequestFn) && N.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), d && (e.session_recording.maskCapturedNetworkRequestFn = t => {
    var i = e.session_recording.maskNetworkRequestFn({
      url: t.name
    });
    return z(z({}, t), {}, {
      name: null == i ? void 0 : i.url
    });
  }), s.maskRequestFn = I(e.session_recording.maskCapturedNetworkRequestFn) ? t => {
    var i,
      r,
      n,
      s = c(t);
    return s && null !== (i = null === (r = (n = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === r ? void 0 : r.call(n, s)) && void 0 !== i ? i : void 0;
  } : e => function (e) {
    if (!F(e)) return e.requestBody = Vi(e.requestBody, "Request"), e.responseBody = Vi(e.responseBody, "Response"), e;
  }(c(e)), z(z(z({}, Hi), s), {}, {
    recordHeaders: o,
    recordBody: a,
    recordPerformance: l,
    recordInitialRequests: l
  });
};
function Ji(e, t, i, r, n) {
  return t > i && (N.warn("min cannot be greater than max."), t = i), M(e) ? e > i ? (r && N.warn(r + " cannot be  greater than max: " + i + ". Using max value instead."), i) : e < t ? (r && N.warn(r + " cannot be less than min: " + t + ". Using min value instead."), t) : e : (r && N.warn(r + " must be a number. using max or fallback. max: " + i + ", fallback: " + n), Ji(n || i, t, i, r));
}
class Yi {
  constructor(e) {
    var t,
      i,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    W(this, "bucketSize", 100), W(this, "refillRate", 10), W(this, "mutationBuckets", {}), W(this, "loggedTracker", {}), W(this, "refillBuckets", () => {
      Object.keys(this.mutationBuckets).forEach(e => {
        this.mutationBuckets[e] = this.mutationBuckets[e] + this.refillRate, this.mutationBuckets[e] >= this.bucketSize && delete this.mutationBuckets[e];
      });
    }), W(this, "getNodeOrRelevantParent", e => {
      var t = this.rrweb.mirror.getNode(e);
      if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
        var i = t.closest("svg");
        if (i) return [this.rrweb.mirror.getId(i), i];
      }
      return [e, t];
    }), W(this, "numberOfChanges", e => {
      var t, i, r, n, s, o, a, l;
      return (null !== (t = null === (i = e.removes) || void 0 === i ? void 0 : i.length) && void 0 !== t ? t : 0) + (null !== (r = null === (n = e.attributes) || void 0 === n ? void 0 : n.length) && void 0 !== r ? r : 0) + (null !== (s = null === (o = e.texts) || void 0 === o ? void 0 : o.length) && void 0 !== s ? s : 0) + (null !== (a = null === (l = e.adds) || void 0 === l ? void 0 : l.length) && void 0 !== a ? a : 0);
    }), W(this, "throttleMutations", e => {
      if (3 !== e.type || 0 !== e.data.source) return e;
      var t = e.data,
        i = this.numberOfChanges(t);
      t.attributes && (t.attributes = t.attributes.filter(e => {
        var t,
          i,
          r,
          [n, s] = this.getNodeOrRelevantParent(e.id);
        if (0 === this.mutationBuckets[n]) return !1;
        (this.mutationBuckets[n] = null !== (t = this.mutationBuckets[n]) && void 0 !== t ? t : this.bucketSize, this.mutationBuckets[n] = Math.max(this.mutationBuckets[n] - 1, 0), 0 === this.mutationBuckets[n]) && (this.loggedTracker[n] || (this.loggedTracker[n] = !0, null === (i = (r = this.options).onBlockedNode) || void 0 === i || i.call(r, n, s)));
        return e;
      }));
      var r = this.numberOfChanges(t);
      return 0 !== r || i === r ? e : void 0;
    }), this.rrweb = e, this.options = r, this.refillRate = Ji(null !== (t = this.options.refillRate) && void 0 !== t ? t : this.refillRate, 0, 100, "mutation throttling refill rate"), this.bucketSize = Ji(null !== (i = this.options.bucketSize) && void 0 !== i ? i : this.bucketSize, 0, 100, "mutation throttling bucket size"), setInterval(() => {
      this.refillBuckets();
    }, 1e3);
  }
}
var Ki = Uint8Array,
  Xi = Uint16Array,
  Qi = Uint32Array,
  Zi = new Ki([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  er = new Ki([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  tr = new Ki([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  ir = function (e, t) {
    for (var i = new Xi(31), r = 0; r < 31; ++r) i[r] = t += 1 << e[r - 1];
    var n = new Qi(i[30]);
    for (r = 1; r < 30; ++r) for (var s = i[r]; s < i[r + 1]; ++s) n[s] = s - i[r] << 5 | r;
    return [i, n];
  },
  rr = ir(Zi, 2),
  nr = rr[0],
  sr = rr[1];
nr[28] = 258, sr[258] = 28;
for (var or = ir(er, 0)[1], ar = new Xi(32768), lr = 0; lr < 32768; ++lr) {
  var ur = (43690 & lr) >>> 1 | (21845 & lr) << 1;
  ur = (61680 & (ur = (52428 & ur) >>> 2 | (13107 & ur) << 2)) >>> 4 | (3855 & ur) << 4, ar[lr] = ((65280 & ur) >>> 8 | (255 & ur) << 8) >>> 1;
}
var cr = function (e, t, i) {
    for (var r = e.length, n = 0, s = new Xi(t); n < r; ++n) ++s[e[n] - 1];
    var o,
      a = new Xi(t);
    for (n = 0; n < t; ++n) a[n] = a[n - 1] + s[n - 1] << 1;
    if (i) {
      o = new Xi(1 << t);
      var l = 15 - t;
      for (n = 0; n < r; ++n) if (e[n]) for (var u = n << 4 | e[n], c = t - e[n], d = a[e[n] - 1]++ << c, h = d | (1 << c) - 1; d <= h; ++d) o[ar[d] >>> l] = u;
    } else for (o = new Xi(r), n = 0; n < r; ++n) o[n] = ar[a[e[n] - 1]++] >>> 15 - e[n];
    return o;
  },
  dr = new Ki(288);
for (lr = 0; lr < 144; ++lr) dr[lr] = 8;
for (lr = 144; lr < 256; ++lr) dr[lr] = 9;
for (lr = 256; lr < 280; ++lr) dr[lr] = 7;
for (lr = 280; lr < 288; ++lr) dr[lr] = 8;
var hr = new Ki(32);
for (lr = 0; lr < 32; ++lr) hr[lr] = 5;
var _r = cr(dr, 9, 0),
  pr = cr(hr, 5, 0),
  vr = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  gr = function (e, t, i) {
    (null == i || i > e.length) && (i = e.length);
    var r = new (e instanceof Xi ? Xi : e instanceof Qi ? Qi : Ki)(i - t);
    return r.set(e.subarray(t, i)), r;
  },
  fr = function (e, t, i) {
    i <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= i, e[r + 1] |= i >>> 8;
  },
  mr = function (e, t, i) {
    i <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= i, e[r + 1] |= i >>> 8, e[r + 2] |= i >>> 16;
  },
  yr = function (e, t) {
    for (var i = [], r = 0; r < e.length; ++r) e[r] && i.push({
      s: r,
      f: e[r]
    });
    var n = i.length,
      s = i.slice();
    if (!n) return [new Ki(0), 0];
    if (1 == n) {
      var o = new Ki(i[0].s + 1);
      return o[i[0].s] = 1, [o, 1];
    }
    i.sort(function (e, t) {
      return e.f - t.f;
    }), i.push({
      s: -1,
      f: 25001
    });
    var a = i[0],
      l = i[1],
      u = 0,
      c = 1,
      d = 2;
    for (i[0] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    }; c != n - 1;) a = i[i[u].f < i[d].f ? u++ : d++], l = i[u != c && i[u].f < i[d].f ? u++ : d++], i[c++] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    };
    var h = s[0].s;
    for (r = 1; r < n; ++r) s[r].s > h && (h = s[r].s);
    var _ = new Xi(h + 1),
      p = br(i[c - 1], _, 0);
    if (p > t) {
      r = 0;
      var v = 0,
        g = p - t,
        f = 1 << g;
      for (s.sort(function (e, t) {
        return _[t.s] - _[e.s] || e.f - t.f;
      }); r < n; ++r) {
        var m = s[r].s;
        if (!(_[m] > t)) break;
        v += f - (1 << p - _[m]), _[m] = t;
      }
      for (v >>>= g; v > 0;) {
        var y = s[r].s;
        _[y] < t ? v -= 1 << t - _[y]++ - 1 : ++r;
      }
      for (; r >= 0 && v; --r) {
        var b = s[r].s;
        _[b] == t && (--_[b], ++v);
      }
      p = t;
    }
    return [new Ki(_), p];
  },
  br = function (e, t, i) {
    return -1 == e.s ? Math.max(br(e.l, t, i + 1), br(e.r, t, i + 1)) : t[e.s] = i;
  },
  wr = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var i = new Xi(++t), r = 0, n = e[0], s = 1, o = function (e) {
        i[r++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == n && a != t) ++s;else {
      if (!n && s > 2) {
        for (; s > 138; s -= 138) o(32754);
        s > 2 && (o(s > 10 ? s - 11 << 5 | 28690 : s - 3 << 5 | 12305), s = 0);
      } else if (s > 3) {
        for (o(n), --s; s > 6; s -= 6) o(8304);
        s > 2 && (o(s - 3 << 5 | 8208), s = 0);
      }
      for (; s--;) o(n);
      s = 1, n = e[a];
    }
    return [i.subarray(0, r), t];
  },
  Sr = function (e, t) {
    for (var i = 0, r = 0; r < t.length; ++r) i += e[r] * t[r];
    return i;
  },
  kr = function (e, t, i) {
    var r = i.length,
      n = vr(t + 2);
    e[n] = 255 & r, e[n + 1] = r >>> 8, e[n + 2] = 255 ^ e[n], e[n + 3] = 255 ^ e[n + 1];
    for (var s = 0; s < r; ++s) e[n + s + 4] = i[s];
    return 8 * (n + 4 + r);
  },
  Er = function (e, t, i, r, n, s, o, a, l, u, c) {
    fr(t, c++, i), ++n[256];
    for (var d = yr(n, 15), h = d[0], _ = d[1], p = yr(s, 15), v = p[0], g = p[1], f = wr(h), m = f[0], y = f[1], b = wr(v), w = b[0], S = b[1], k = new Xi(19), E = 0; E < m.length; ++E) k[31 & m[E]]++;
    for (E = 0; E < w.length; ++E) k[31 & w[E]]++;
    for (var x = yr(k, 7), I = x[0], C = x[1], P = 19; P > 4 && !I[tr[P - 1]]; --P);
    var F,
      T,
      R,
      $,
      O = u + 5 << 3,
      M = Sr(n, dr) + Sr(s, hr) + o,
      A = Sr(n, h) + Sr(s, v) + o + 14 + 3 * P + Sr(k, I) + (2 * k[16] + 3 * k[17] + 7 * k[18]);
    if (O <= M && O <= A) return kr(t, c, e.subarray(l, l + u));
    if (fr(t, c, 1 + (A < M)), c += 2, A < M) {
      F = cr(h, _, 0), T = h, R = cr(v, g, 0), $ = v;
      var L = cr(I, C, 0);
      fr(t, c, y - 257), fr(t, c + 5, S - 1), fr(t, c + 10, P - 4), c += 14;
      for (E = 0; E < P; ++E) fr(t, c + 3 * E, I[tr[E]]);
      c += 3 * P;
      for (var D = [m, w], q = 0; q < 2; ++q) {
        var N = D[q];
        for (E = 0; E < N.length; ++E) {
          var B = 31 & N[E];
          fr(t, c, L[B]), c += I[B], B > 15 && (fr(t, c, N[E] >>> 5 & 127), c += N[E] >>> 12);
        }
      }
    } else F = _r, T = dr, R = pr, $ = hr;
    for (E = 0; E < a; ++E) if (r[E] > 255) {
      B = r[E] >>> 18 & 31;
      mr(t, c, F[B + 257]), c += T[B + 257], B > 7 && (fr(t, c, r[E] >>> 23 & 31), c += Zi[B]);
      var H = 31 & r[E];
      mr(t, c, R[H]), c += $[H], H > 3 && (mr(t, c, r[E] >>> 5 & 8191), c += er[H]);
    } else mr(t, c, F[r[E]]), c += T[r[E]];
    return mr(t, c, F[256]), c + T[256];
  },
  xr = new Qi([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  Ir = function () {
    for (var e = new Qi(256), t = 0; t < 256; ++t) {
      for (var i = t, r = 9; --r;) i = (1 & i && 3988292384) ^ i >>> 1;
      e[t] = i;
    }
    return e;
  }(),
  Cr = function () {
    var e = 4294967295;
    return {
      p: function (t) {
        for (var i = e, r = 0; r < t.length; ++r) i = Ir[255 & i ^ t[r]] ^ i >>> 8;
        e = i;
      },
      d: function () {
        return 4294967295 ^ e;
      }
    };
  },
  Pr = function (e, t, i, r, n) {
    return function (e, t, i, r, n, s) {
      var o = e.length,
        a = new Ki(r + o + 5 * (1 + Math.floor(o / 7e3)) + n),
        l = a.subarray(r, a.length - n),
        u = 0;
      if (!t || o < 8) for (var c = 0; c <= o; c += 65535) {
        var d = c + 65535;
        d < o ? u = kr(l, u, e.subarray(c, d)) : (l[c] = s, u = kr(l, u, e.subarray(c, o)));
      } else {
        for (var h = xr[t - 1], _ = h >>> 13, p = 8191 & h, v = (1 << i) - 1, g = new Xi(32768), f = new Xi(v + 1), m = Math.ceil(i / 3), y = 2 * m, b = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << y) & v;
          }, w = new Qi(25e3), S = new Xi(288), k = new Xi(32), E = 0, x = 0, I = (c = 0, 0), C = 0, P = 0; c < o; ++c) {
          var F = b(c),
            T = 32767 & c,
            R = f[F];
          if (g[T] = R, f[F] = T, C <= c) {
            var $ = o - c;
            if ((E > 7e3 || I > 24576) && $ > 423) {
              u = Er(e, l, 0, w, S, k, x, I, P, c - P, u), I = E = x = 0, P = c;
              for (var O = 0; O < 286; ++O) S[O] = 0;
              for (O = 0; O < 30; ++O) k[O] = 0;
            }
            var M = 2,
              A = 0,
              L = p,
              D = T - R & 32767;
            if ($ > 2 && F == b(c - D)) for (var q = Math.min(_, $) - 1, N = Math.min(32767, c), B = Math.min(258, $); D <= N && --L && T != R;) {
              if (e[c + M] == e[c + M - D]) {
                for (var H = 0; H < B && e[c + H] == e[c + H - D]; ++H);
                if (H > M) {
                  if (M = H, A = D, H > q) break;
                  var j = Math.min(D, H - 2),
                    U = 0;
                  for (O = 0; O < j; ++O) {
                    var z = c - D + O + 32768 & 32767,
                      W = z - g[z] + 32768 & 32767;
                    W > U && (U = W, R = z);
                  }
                }
              }
              D += (T = R) - (R = g[T]) + 32768 & 32767;
            }
            if (A) {
              w[I++] = 268435456 | sr[M] << 18 | or[A];
              var V = 31 & sr[M],
                G = 31 & or[A];
              x += Zi[V] + er[G], ++S[257 + V], ++k[G], C = c + M, ++E;
            } else w[I++] = e[c], ++S[e[c]];
          }
        }
        u = Er(e, l, s, w, S, k, x, I, P, c - P, u);
      }
      return gr(a, 0, r + vr(u) + n);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, i, r, !n);
  },
  Fr = function (e, t, i) {
    for (; i; ++t) e[t] = i, i >>>= 8;
  },
  Tr = function (e, t) {
    var i = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && Fr(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), i) {
      e[3] = 8;
      for (var r = 0; r <= i.length; ++r) e[r + 10] = i.charCodeAt(r);
    }
  },
  Rr = function (e) {
    return 10 + (e.filename && e.filename.length + 1 || 0);
  };
function $r(e, t) {
  void 0 === t && (t = {});
  var i = Cr(),
    r = e.length;
  i.p(e);
  var n = Pr(e, t, Rr(t), 8),
    s = n.length;
  return Tr(n, t), Fr(n, s - 8, i.d()), Fr(n, s - 4, r), n;
}
function Or(e, t) {
  var i = e.length;
  if ("undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
  for (var r = new Ki(e.length + (e.length >>> 1)), n = 0, s = function (e) {
      r[n++] = e;
    }, o = 0; o < i; ++o) {
    if (n + 5 > r.length) {
      var a = new Ki(n + 8 + (i - o << 1));
      a.set(r), r = a;
    }
    var l = e.charCodeAt(o);
    l < 128 || t ? s(l) : l < 2048 ? (s(192 | l >>> 6), s(128 | 63 & l)) : l > 55295 && l < 57344 ? (s(240 | (l = 65536 + (1047552 & l) | 1023 & e.charCodeAt(++o)) >>> 18), s(128 | l >>> 12 & 63), s(128 | l >>> 6 & 63), s(128 | 63 & l)) : (s(224 | l >>> 12), s(128 | l >>> 6 & 63), s(128 | 63 & l));
  }
  return gr(r, 0, n);
}
function Mr(e, t) {
  return function (e) {
    for (var t = 0, i = 0; i < e.length; i++) t = (t << 5) - t + e.charCodeAt(i), t |= 0;
    return Math.abs(t);
  }(e) % 100 < Ji(100 * t, 0, 100);
}
var Ar = "[SessionRecording]",
  Lr = B(Ar);
function Dr() {
  var e, t;
  return null == _ || null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.rrweb) || void 0 === t ? void 0 : t.record;
}
var qr = 3e5,
  Nr = [qi.MouseMove, qi.MouseInteraction, qi.Scroll, qi.ViewportResize, qi.Input, qi.TouchMove, qi.MediaInteraction, qi.Drag],
  Br = e => ({
    rrwebMethod: e,
    enqueuedAt: Date.now(),
    attempt: 1
  });
function Hr(e) {
  return function (e, t) {
    for (var i = "", r = 0; r < e.length;) {
      var n = e[r++];
      n < 128 || t ? i += String.fromCharCode(n) : n < 224 ? i += String.fromCharCode((31 & n) << 6 | 63 & e[r++]) : n < 240 ? i += String.fromCharCode((15 & n) << 12 | (63 & e[r++]) << 6 | 63 & e[r++]) : (n = ((15 & n) << 18 | (63 & e[r++]) << 12 | (63 & e[r++]) << 6 | 63 & e[r++]) - 65536, i += String.fromCharCode(55296 | n >> 10, 56320 | 1023 & n));
    }
    return i;
  }($r(Or(JSON.stringify(e))), !0);
}
function jr(e) {
  return e.type === Di.Custom && "sessionIdle" === e.data.tag;
}
function Ur(e, t) {
  return t.some(t => "regex" === t.matching && new RegExp(t.url).test(e));
}
class zr {
  get sessionIdleThresholdMilliseconds() {
    return this.instance.config.session_recording.session_idle_threshold_ms || 3e5;
  }
  get started() {
    return this._captureStarted;
  }
  get sessionManager() {
    if (!this.instance.sessionManager) throw new Error(Ar + " must be started with a valid sessionManager.");
    return this.instance.sessionManager;
  }
  get fullSnapshotIntervalMillis() {
    var e, t;
    return "trigger_pending" === this.triggerStatus ? 6e4 : null !== (e = null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.full_snapshot_interval_millis) && void 0 !== e ? e : qr;
  }
  get isSampled() {
    var e = this.instance.get_property(xe);
    return A(e) ? e : null;
  }
  get sessionDuration() {
    var e,
      t,
      i = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
      {
        sessionStartTimestamp: r
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0);
    return i ? i.timestamp - r : null;
  }
  get isRecordingEnabled() {
    var e = !!this.instance.get_property(ge),
      i = !this.instance.config.disable_session_recording;
    return t && e && i;
  }
  get isConsoleLogCaptureEnabled() {
    var e = !!this.instance.get_property(fe),
      t = this.instance.config.enable_recording_console_log;
    return null != t ? t : e;
  }
  get canvasRecording() {
    var e,
      t,
      i,
      r,
      n,
      s,
      o = this.instance.config.session_recording.captureCanvas,
      a = this.instance.get_property(be),
      l = null !== (e = null !== (t = null == o ? void 0 : o.recordCanvas) && void 0 !== t ? t : null == a ? void 0 : a.enabled) && void 0 !== e && e,
      u = null !== (i = null !== (r = null == o ? void 0 : o.canvasFps) && void 0 !== r ? r : null == a ? void 0 : a.fps) && void 0 !== i ? i : 4,
      c = null !== (n = null !== (s = null == o ? void 0 : o.canvasQuality) && void 0 !== s ? s : null == a ? void 0 : a.quality) && void 0 !== n ? n : .4;
    if ("string" == typeof c) {
      var d = parseFloat(c);
      c = isNaN(d) ? .4 : d;
    }
    return {
      enabled: l,
      fps: Ji(u, 0, 12, "canvas recording fps", 4),
      quality: Ji(c, 0, 1, "canvas recording quality", .4)
    };
  }
  get networkPayloadCapture() {
    var e,
      t,
      i = this.instance.get_property(me),
      r = {
        recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
        recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
      },
      n = (null == r ? void 0 : r.recordHeaders) || (null == i ? void 0 : i.recordHeaders),
      s = (null == r ? void 0 : r.recordBody) || (null == i ? void 0 : i.recordBody),
      o = C(this.instance.config.capture_performance) ? this.instance.config.capture_performance.network_timing : this.instance.config.capture_performance,
      a = !!(A(o) ? o : null == i ? void 0 : i.capturePerformance);
    return n || s || a ? {
      recordHeaders: n,
      recordBody: s,
      recordPerformance: a
    } : void 0;
  }
  get masking() {
    var e,
      t,
      i,
      r,
      n,
      s,
      o = this.instance.get_property(ye),
      a = {
        maskAllInputs: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.maskAllInputs,
        maskTextSelector: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.maskTextSelector,
        blockSelector: null === (i = this.instance.config.session_recording) || void 0 === i ? void 0 : i.blockSelector
      },
      l = null !== (r = null == a ? void 0 : a.maskAllInputs) && void 0 !== r ? r : null == o ? void 0 : o.maskAllInputs,
      u = null !== (n = null == a ? void 0 : a.maskTextSelector) && void 0 !== n ? n : null == o ? void 0 : o.maskTextSelector,
      c = null !== (s = null == a ? void 0 : a.blockSelector) && void 0 !== s ? s : null == o ? void 0 : o.blockSelector;
    return F(l) && F(u) && F(c) ? void 0 : {
      maskAllInputs: null == l || l,
      maskTextSelector: u,
      blockSelector: c
    };
  }
  get sampleRate() {
    var e = this.instance.get_property(we);
    return M(e) ? e : null;
  }
  get minimumDuration() {
    var e = this.instance.get_property(Se);
    return M(e) ? e : null;
  }
  get status() {
    return this.receivedDecide ? this.isRecordingEnabled ? !1 === this.isSampled ? "disabled" : this._urlBlocked ? "paused" : O(this._linkedFlag) || this._linkedFlagSeen ? "trigger_pending" === this.triggerStatus ? "buffering" : A(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "buffering" : "disabled" : "buffering";
  }
  get urlTriggerStatus() {
    var e;
    return 0 === this._urlTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Ie)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get eventTriggerStatus() {
    var e;
    return 0 === this._eventTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Ce)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get triggerStatus() {
    var e = "trigger_activated" === this.eventTriggerStatus || "trigger_activated" === this.urlTriggerStatus,
      t = "trigger_pending" === this.eventTriggerStatus || "trigger_pending" === this.urlTriggerStatus;
    return e ? "trigger_activated" : t ? "trigger_pending" : "trigger_disabled";
  }
  constructor(e) {
    if (W(this, "queuedRRWebEvents", []), W(this, "isIdle", "unknown"), W(this, "_linkedFlagSeen", !1), W(this, "_lastActivityTimestamp", Date.now()), W(this, "_linkedFlag", null), W(this, "_removePageViewCaptureHook", void 0), W(this, "_onSessionIdListener", void 0), W(this, "_persistDecideOnSessionListener", void 0), W(this, "_samplingSessionListener", void 0), W(this, "_urlTriggers", []), W(this, "_urlBlocklist", []), W(this, "_urlBlocked", !1), W(this, "_eventTriggers", []), W(this, "_removeEventTriggerCaptureHook", void 0), W(this, "_forceAllowLocalhostNetworkCapture", !1), W(this, "_onBeforeUnload", () => {
      this._flushBuffer();
    }), W(this, "_onOffline", () => {
      this._tryAddCustomEvent("browser offline", {});
    }), W(this, "_onOnline", () => {
      this._tryAddCustomEvent("browser online", {});
    }), W(this, "_onVisibilityChange", () => {
      if (null != a && a.visibilityState) {
        var e = "window " + a.visibilityState;
        this._tryAddCustomEvent(e, {});
      }
    }), this.instance = e, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, !this.instance.sessionManager) throw Lr.error("started without valid sessionManager"), new Error(Ar + " started without valid sessionManager. This is a bug.");
    if (this.instance.config.__preview_experimental_cookieless_mode) throw new Error(Ar + " cannot be used with __preview_experimental_cookieless_mode.");
    var {
      sessionId: t,
      windowId: i
    } = this.sessionManager.checkAndGetSessionAndWindowId();
    this.sessionId = t, this.windowId = i, this.buffer = this.clearBuffer(), this.sessionIdleThresholdMilliseconds >= this.sessionManager.sessionTimeoutMs && Lr.warn("session_idle_threshold_ms (".concat(this.sessionIdleThresholdMilliseconds, ") is greater than the session timeout (").concat(this.sessionManager.sessionTimeoutMs, "). Session will never be detected as idle"));
  }
  startIfEnabledOrStop(e) {
    this.isRecordingEnabled ? (this._startCapture(e), oe(t, "beforeunload", this._onBeforeUnload), oe(t, "offline", this._onOffline), oe(t, "online", this._onOnline), oe(t, "visibilitychange", this._onVisibilityChange), this._setupSampling(), this._addEventTriggerListener(), O(this._removePageViewCaptureHook) && (this._removePageViewCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        if ("$pageview" === e.event) {
          var t = null != e && e.properties.$current_url ? this._maskUrl(null == e ? void 0 : e.properties.$current_url) : "";
          if (!t) return;
          this._tryAddCustomEvent("$pageview", {
            href: t
          });
        }
      } catch (e) {
        Lr.error("Could not add $pageview to rrweb session", e);
      }
    })), this._onSessionIdListener || (this._onSessionIdListener = this.sessionManager.onSessionId((e, t, i) => {
      var r, n, s, o;
      i && (this._tryAddCustomEvent("$session_id_change", {
        sessionId: e,
        windowId: t,
        changeReason: i
      }), null === (r = this.instance) || void 0 === r || null === (n = r.persistence) || void 0 === n || n.unregister(Ce), null === (s = this.instance) || void 0 === s || null === (o = s.persistence) || void 0 === o || o.unregister(Ie));
    }))) : this.stopRecording();
  }
  stopRecording() {
    var e, i, r, n;
    this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, null == t || t.removeEventListener("beforeunload", this._onBeforeUnload), null == t || t.removeEventListener("offline", this._onOffline), null == t || t.removeEventListener("online", this._onOnline), null == t || t.removeEventListener("visibilitychange", this._onVisibilityChange), this.clearBuffer(), clearInterval(this._fullSnapshotTimer), null === (e = this._removePageViewCaptureHook) || void 0 === e || e.call(this), this._removePageViewCaptureHook = void 0, null === (i = this._removeEventTriggerCaptureHook) || void 0 === i || i.call(this), this._removeEventTriggerCaptureHook = void 0, null === (r = this._onSessionIdListener) || void 0 === r || r.call(this), this._onSessionIdListener = void 0, null === (n = this._samplingSessionListener) || void 0 === n || n.call(this), this._samplingSessionListener = void 0, Lr.info("stopped"));
  }
  _resetSampling() {
    var e;
    null === (e = this.instance.persistence) || void 0 === e || e.unregister(xe);
  }
  makeSamplingDecision(e) {
    var t,
      i = this.sessionId !== e,
      r = this.sampleRate;
    if (M(r)) {
      var n = this.isSampled,
        s = i || !A(n),
        o = s ? Mr(e, r) : n;
      s && (o ? this._reportStarted("sampled") : Lr.warn("Sample rate (".concat(r, ") has determined that this sessionId (").concat(e, ") will not be sent to the server.")), this._tryAddCustomEvent("samplingDecisionMade", {
        sampleRate: r,
        isSampled: o
      })), null === (t = this.instance.persistence) || void 0 === t || t.register({
        [xe]: o
      });
    } else this._resetSampling();
  }
  onRemoteConfig(e) {
    var t, i, r, n, s, o;
    (this._tryAddCustomEvent("$remote_config_received", e), this._persistRemoteConfig(e), this._linkedFlag = (null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.linkedFlag) || null, null !== (i = e.sessionRecording) && void 0 !== i && i.endpoint) && (this._endpoint = null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.endpoint);
    if (this._setupSampling(), !O(this._linkedFlag) && !this._linkedFlagSeen) {
      var a = T(this._linkedFlag) ? this._linkedFlag : this._linkedFlag.flag,
        l = T(this._linkedFlag) ? null : this._linkedFlag.variant;
      this.instance.onFeatureFlags((e, t) => {
        var i = C(t) && a in t,
          r = l ? t[a] === l : i;
        r && this._reportStarted("linked_flag_matched", {
          linkedFlag: a,
          linkedVariant: l
        }), this._linkedFlagSeen = r;
      });
    }
    null !== (r = e.sessionRecording) && void 0 !== r && r.urlTriggers && (this._urlTriggers = e.sessionRecording.urlTriggers), null !== (n = e.sessionRecording) && void 0 !== n && n.urlBlocklist && (this._urlBlocklist = e.sessionRecording.urlBlocklist), null !== (s = e.sessionRecording) && void 0 !== s && s.eventTriggers && (this._eventTriggers = e.sessionRecording.eventTriggers), this.receivedDecide = !0, this.startIfEnabledOrStop();
  }
  _setupSampling() {
    M(this.sampleRate) && O(this._samplingSessionListener) && (this._samplingSessionListener = this.sessionManager.onSessionId(e => {
      this.makeSamplingDecision(e);
    }));
  }
  _persistRemoteConfig(e) {
    if (this.instance.persistence) {
      var t,
        i = this.instance.persistence,
        r = () => {
          var t,
            r,
            n,
            s,
            o,
            a,
            l,
            u,
            c,
            d = null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.sampleRate,
            h = O(d) ? null : parseFloat(d);
          O(h) && this._resetSampling();
          var _ = null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.minimumDurationMilliseconds;
          i.register({
            [ge]: !!e.sessionRecording,
            [fe]: null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.consoleLogRecordingEnabled,
            [me]: z({
              capturePerformance: e.capturePerformance
            }, null === (s = e.sessionRecording) || void 0 === s ? void 0 : s.networkPayloadCapture),
            [ye]: null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.masking,
            [be]: {
              enabled: null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.recordCanvas,
              fps: null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.canvasFps,
              quality: null === (u = e.sessionRecording) || void 0 === u ? void 0 : u.canvasQuality
            },
            [we]: h,
            [Se]: F(_) ? null : _,
            [ke]: null === (c = e.sessionRecording) || void 0 === c ? void 0 : c.scriptConfig
          });
        };
      r(), null === (t = this._persistDecideOnSessionListener) || void 0 === t || t.call(this), this._persistDecideOnSessionListener = this.sessionManager.onSessionId(r);
    }
  }
  log(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
    null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
      type: 6,
      data: {
        plugin: "rrweb/console@1",
        payload: {
          level: i,
          trace: [],
          payload: [JSON.stringify(e)]
        }
      },
      timestamp: Date.now()
    });
  }
  _startCapture(e) {
    if (!F(Object.assign) && !F(Array.from) && !(this._captureStarted || this.instance.config.disable_session_recording || this.instance.consent.isOptedOut())) {
      var t, i;
      if (this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId(), Dr()) this._onScriptLoaded();else null === (t = _.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, this.scriptName, e => {
        if (e) return Lr.error("could not load recorder", e);
        this._onScriptLoaded();
      });
      Lr.info("starting"), "active" === this.status && this._reportStarted(e || "recording_initialized");
    }
  }
  get scriptName() {
    var e, t, i;
    return (null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t || null === (i = t.get_property(ke)) || void 0 === i ? void 0 : i.script) || "recorder";
  }
  isInteractiveEvent(e) {
    var t;
    return 3 === e.type && -1 !== Nr.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
  }
  _updateWindowAndSessionIds(e) {
    var t = this.isInteractiveEvent(e);
    t || this.isIdle || e.timestamp - this._lastActivityTimestamp > this.sessionIdleThresholdMilliseconds && (this.isIdle = !0, clearInterval(this._fullSnapshotTimer), this._tryAddCustomEvent("sessionIdle", {
      eventTimestamp: e.timestamp,
      lastActivityTimestamp: this._lastActivityTimestamp,
      threshold: this.sessionIdleThresholdMilliseconds,
      bufferLength: this.buffer.data.length,
      bufferSize: this.buffer.size
    }), this._flushBuffer());
    var i = !1;
    if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle)) {
      var r = "unknown" === this.isIdle;
      this.isIdle = !1, r || (this._tryAddCustomEvent("sessionNoLongerIdle", {
        reason: "user activity",
        type: e.type
      }), i = !0);
    }
    if (!this.isIdle) {
      var {
          windowId: n,
          sessionId: s
        } = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
        o = this.sessionId !== s,
        a = this.windowId !== n;
      this.windowId = n, this.sessionId = s, o || a ? (this.stopRecording(), this.startIfEnabledOrStop("session_id_changed")) : i && this._scheduleFullSnapshot();
    }
  }
  _tryRRWebMethod(e) {
    try {
      return e.rrwebMethod(), !0;
    } catch (t) {
      return this.queuedRRWebEvents.length < 10 ? this.queuedRRWebEvents.push({
        enqueuedAt: e.enqueuedAt || Date.now(),
        attempt: e.attempt++,
        rrwebMethod: e.rrwebMethod
      }) : Lr.warn("could not emit queued rrweb event.", t, e), !1;
    }
  }
  _tryAddCustomEvent(e, t) {
    return this._tryRRWebMethod(Br(() => Dr().addCustomEvent(e, t)));
  }
  _tryTakeFullSnapshot() {
    return this._tryRRWebMethod(Br(() => Dr().takeFullSnapshot()));
  }
  _onScriptLoaded() {
    var e,
      t,
      i,
      r,
      n = {
        blockClass: "ph-no-capture",
        blockSelector: void 0,
        ignoreClass: "ph-ignore-input",
        maskTextClass: "ph-mask",
        maskTextSelector: void 0,
        maskTextFn: void 0,
        maskAllInputs: !0,
        maskInputOptions: {
          password: !0
        },
        maskInputFn: void 0,
        slimDOMOptions: {},
        collectFonts: !1,
        inlineStylesheet: !0,
        recordCrossOriginIframes: !1
      },
      s = this.instance.config.session_recording;
    for (var [o, a] of Object.entries(s || {})) o in n && ("maskInputOptions" === o ? n.maskInputOptions = z({
      password: !0
    }, a) : n[o] = a);
    (this.canvasRecording && this.canvasRecording.enabled && (n.recordCanvas = !0, n.sampling = {
      canvas: this.canvasRecording.fps
    }, n.dataURLOptions = {
      type: "image/webp",
      quality: this.canvasRecording.quality
    }), this.masking) && (n.maskAllInputs = null === (t = this.masking.maskAllInputs) || void 0 === t || t, n.maskTextSelector = null !== (i = this.masking.maskTextSelector) && void 0 !== i ? i : void 0, n.blockSelector = null !== (r = this.masking.blockSelector) && void 0 !== r ? r : void 0);
    var l = Dr();
    if (l) {
      this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new Yi(l, {
        refillRate: this.instance.config.session_recording.__mutationRateLimiterRefillRate,
        bucketSize: this.instance.config.session_recording.__mutationRateLimiterBucketSize,
        onBlockedNode: (e, t) => {
          var i = "Too many mutations on node '".concat(e, "'. Rate limiting. This could be due to SVG animations or something similar");
          Lr.info(i, {
            node: t
          }), this.log(Ar + " " + i, "warn");
        }
      });
      var u = this._gatherRRWebPlugins();
      this.stopRrweb = l(z({
        emit: e => {
          this.onRRwebEmit(e);
        },
        plugins: u
      }, n)), this._lastActivityTimestamp = Date.now(), this.isIdle = A(this.isIdle) ? this.isIdle : "unknown", this._tryAddCustomEvent("$session_options", {
        sessionRecordingOptions: n,
        activePlugins: u.map(e => null == e ? void 0 : e.name)
      }), this._tryAddCustomEvent("$posthog_config", {
        config: this.instance.config
      });
    } else Lr.error("onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
  }
  _scheduleFullSnapshot() {
    if (this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), !0 !== this.isIdle) {
      var e = this.fullSnapshotIntervalMillis;
      e && (this._fullSnapshotTimer = setInterval(() => {
        this._tryTakeFullSnapshot();
      }, e));
    }
  }
  _gatherRRWebPlugins() {
    var e,
      t,
      i,
      r,
      n = [],
      s = null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.rrwebPlugins) || void 0 === t ? void 0 : t.getRecordConsolePlugin;
    s && this.isConsoleLogCaptureEnabled && n.push(s());
    var o = null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.rrwebPlugins) || void 0 === r ? void 0 : r.getRecordNetworkPlugin;
    this.networkPayloadCapture && I(o) && (!wt.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? n.push(o(Gi(this.instance.config, this.networkPayloadCapture))) : Lr.info("NetworkCapture not started because we are on localhost."));
    return n;
  }
  onRRwebEmit(e) {
    var t;
    if (this._processQueuedEvents(), e && C(e)) {
      if (e.type === Di.Meta) {
        var i = this._maskUrl(e.data.href);
        if (this._lastHref = i, !i) return;
        e.data.href = i;
      } else this._pageViewFallBack();
      if (this._checkUrlTriggerConditions(), !this._urlBlocked || function (e) {
        return e.type === Di.Custom && "recording paused" === e.data.tag;
      }(e)) {
        e.type === Di.FullSnapshot && this._scheduleFullSnapshot(), e.type === Di.FullSnapshot && "trigger_pending" === this.triggerStatus && this.clearBuffer();
        var r = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
        if (r) {
          var n = function (e) {
            var t = e;
            if (t && C(t) && 6 === t.type && C(t.data) && "rrweb/console@1" === t.data.plugin) {
              t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
              for (var i = [], r = 0; r < t.data.payload.payload.length; r++) t.data.payload.payload[r] && t.data.payload.payload[r].length > 2e3 ? i.push(t.data.payload.payload[r].slice(0, 2e3) + "...[truncated]") : i.push(t.data.payload.payload[r]);
              return t.data.payload.payload = i, e;
            }
            return e;
          }(r);
          if (this._updateWindowAndSessionIds(n), !0 !== this.isIdle || jr(n)) {
            if (jr(n)) {
              var s = n.data.payload;
              if (s) {
                var o = s.lastActivityTimestamp,
                  a = s.threshold;
                n.timestamp = o + a;
              }
            }
            var l = null === (t = this.instance.config.session_recording.compress_events) || void 0 === t || t ? function (e) {
                if (Ai(e) < 1024) return e;
                try {
                  if (e.type === Di.FullSnapshot) return z(z({}, e), {}, {
                    data: Hr(e.data),
                    cv: "2024-10"
                  });
                  if (e.type === Di.IncrementalSnapshot && e.data.source === qi.Mutation) return z(z({}, e), {}, {
                    cv: "2024-10",
                    data: z(z({}, e.data), {}, {
                      texts: Hr(e.data.texts),
                      attributes: Hr(e.data.attributes),
                      removes: Hr(e.data.removes),
                      adds: Hr(e.data.adds)
                    })
                  });
                  if (e.type === Di.IncrementalSnapshot && e.data.source === qi.StyleSheetRule) return z(z({}, e), {}, {
                    cv: "2024-10",
                    data: z(z({}, e.data), {}, {
                      adds: e.data.adds ? Hr(e.data.adds) : void 0,
                      removes: e.data.removes ? Hr(e.data.removes) : void 0
                    })
                  });
                } catch (e) {
                  Lr.error("could not compress event - will use uncompressed event", e);
                }
                return e;
              }(n) : n,
              u = {
                $snapshot_bytes: Ai(l),
                $snapshot_data: l,
                $session_id: this.sessionId,
                $window_id: this.windowId
              };
            "disabled" !== this.status ? this._captureSnapshotBuffered(u) : this.clearBuffer();
          }
        }
      }
    }
  }
  _pageViewFallBack() {
    if (!this.instance.config.capture_pageview && t) {
      var e = this._maskUrl(t.location.href);
      this._lastHref !== e && (this._tryAddCustomEvent("$url_changed", {
        href: e
      }), this._lastHref = e);
    }
  }
  _processQueuedEvents() {
    if (this.queuedRRWebEvents.length) {
      var e = [...this.queuedRRWebEvents];
      this.queuedRRWebEvents = [], e.forEach(e => {
        Date.now() - e.enqueuedAt <= 2e3 && this._tryRRWebMethod(e);
      });
    }
  }
  _maskUrl(e) {
    var t = this.instance.config.session_recording;
    if (t.maskNetworkRequestFn) {
      var i,
        r = {
          url: e
        };
      return null === (i = r = t.maskNetworkRequestFn(r)) || void 0 === i ? void 0 : i.url;
    }
    return e;
  }
  clearBuffer() {
    return this.buffer = {
      size: 0,
      data: [],
      sessionId: this.sessionId,
      windowId: this.windowId
    }, this.buffer;
  }
  _flushBuffer() {
    this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
    var e = this.minimumDuration,
      t = this.sessionDuration,
      i = M(t) && t >= 0,
      r = M(e) && i && t < e;
    if ("buffering" === this.status || "paused" === this.status || "disabled" === this.status || r) return this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3), this.buffer;
    this.buffer.data.length > 0 && Li(this.buffer).forEach(e => {
      this._captureSnapshot({
        $snapshot_bytes: e.size,
        $snapshot_data: e.data,
        $session_id: e.sessionId,
        $window_id: e.windowId,
        $lib: "web",
        $lib_version: p.LIB_VERSION
      });
    });
    return this.clearBuffer();
  }
  _captureSnapshotBuffered(e) {
    var t,
      i = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
    !this.isIdle && (this.buffer.size + e.$snapshot_bytes + i > 943718.4 || this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || this.isIdle || (this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3));
  }
  _captureSnapshot(e) {
    this.instance.capture("$snapshot", e, {
      _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
      _noTruncate: !0,
      _batchKey: "recordings",
      skip_client_rate_limiting: !0
    });
  }
  _checkUrlTriggerConditions() {
    if (void 0 !== t && t.location.href) {
      var e = t.location.href,
        i = this._urlBlocked,
        r = Ur(e, this._urlBlocklist);
      r && !i ? this._pauseRecording() : !r && i && this._resumeRecording(), Ur(e, this._urlTriggers) && this._activateTrigger("url");
    }
  }
  _activateTrigger(e) {
    var t, i;
    "trigger_pending" === this.triggerStatus && (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      ["url" === e ? Ie : Ce]: this.sessionId
    }), this._flushBuffer(), this._reportStarted(e + "_trigger_matched"));
  }
  _pauseRecording() {
    this._urlBlocked || (this._urlBlocked = !0, clearInterval(this._fullSnapshotTimer), Lr.info("recording paused due to URL blocker"), this._tryAddCustomEvent("recording paused", {
      reason: "url blocker"
    }));
  }
  _resumeRecording() {
    this._urlBlocked && (this._urlBlocked = !1, this._tryTakeFullSnapshot(), this._scheduleFullSnapshot(), this._tryAddCustomEvent("recording resumed", {
      reason: "left blocked url"
    }), Lr.info("recording resumed"));
  }
  _addEventTriggerListener() {
    0 !== this._eventTriggers.length && O(this._removeEventTriggerCaptureHook) && (this._removeEventTriggerCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        this._eventTriggers.includes(e.event) && this._activateTrigger("event");
      } catch (e) {
        Lr.error("Could not activate event trigger", e);
      }
    }));
  }
  overrideLinkedFlag() {
    this._linkedFlagSeen = !0, this._tryTakeFullSnapshot(), this._reportStarted("linked_flag_overridden");
  }
  overrideSampling() {
    var e;
    null === (e = this.instance.persistence) || void 0 === e || e.register({
      [xe]: !0
    }), this._tryTakeFullSnapshot(), this._reportStarted("sampling_overridden");
  }
  overrideTrigger(e) {
    this._activateTrigger(e);
  }
  _reportStarted(e, t) {
    this.instance.register_for_session({
      $session_recording_start_reason: e
    }), Lr.info(e.replace("_", " "), t), m(["recording_initialized", "session_id_changed"], e) || this._tryAddCustomEvent(e, t);
  }
}
var Wr = B("[SegmentIntegration]");
function Vr(e, t) {
  var i = e.config.segment;
  if (!i) return t();
  !function (e, t) {
    var i = e.config.segment;
    if (!i) return t();
    var r = i => {
        var r = () => i.anonymousId() || Nt();
        e.config.get_device_id = r, i.id() && (e.register({
          distinct_id: i.id(),
          $device_id: r()
        }), e.persistence.set_property(Le, "identified")), t();
      },
      n = i.user();
    "then" in n && I(n.then) ? n.then(e => r(e)) : r(n);
  }(e, () => {
    i.register((e => {
      Promise && Promise.resolve || Wr.warn("This browser does not have Promise support, and can not use the segment integration");
      var t = (t, i) => {
        var r;
        if (!i) return t;
        t.event.userId || t.event.anonymousId === e.get_distinct_id() || (Wr.info("No userId set, resetting PostHog"), e.reset()), t.event.userId && t.event.userId !== e.get_distinct_id() && (Wr.info("UserId set, identifying with PostHog"), e.identify(t.event.userId));
        var n = e._calculate_event_properties(i, null !== (r = t.event.properties) && void 0 !== r ? r : {}, new Date());
        return t.event.properties = Object.assign({}, n, t.event.properties), t;
      };
      return {
        name: "PostHog JS",
        type: "enrichment",
        version: "1.0.0",
        isLoaded: () => !0,
        load: () => Promise.resolve(),
        track: e => t(e, e.event.event),
        page: e => t(e, "$pageview"),
        identify: e => t(e, "$identify"),
        screen: e => t(e, "$screen")
      };
    })(e)).then(() => {
      t();
    });
  });
}
var Gr = "posthog-js";
function Jr(e) {
  var {
    organization: t,
    projectId: i,
    prefix: r,
    severityAllowList: n = ["error"]
  } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return s => {
    var o, a, l, u, c;
    if (!("*" === n || n.includes(s.level)) || !e.__loaded) return s;
    s.tags || (s.tags = {});
    var d = e.requestRouter.endpointFor("ui", "/project/".concat(e.config.token, "/person/").concat(e.get_distinct_id()));
    s.tags["PostHog Person URL"] = d, e.sessionRecordingStarted() && (s.tags["PostHog Recording URL"] = e.get_session_replay_url({
      withTimestamp: !0
    }));
    var h = (null === (o = s.exception) || void 0 === o ? void 0 : o.values) || [],
      _ = h.map(e => z(z({}, e), {}, {
        stacktrace: e.stacktrace ? z(z({}, e.stacktrace), {}, {
          type: "raw",
          frames: (e.stacktrace.frames || []).map(e => z(z({}, e), {}, {
            platform: "web:javascript"
          }))
        }) : void 0
      })),
      p = {
        $exception_message: (null === (a = h[0]) || void 0 === a ? void 0 : a.value) || s.message,
        $exception_type: null === (l = h[0]) || void 0 === l ? void 0 : l.type,
        $exception_personURL: d,
        $exception_level: s.level,
        $exception_list: _,
        $sentry_event_id: s.event_id,
        $sentry_exception: s.exception,
        $sentry_exception_message: (null === (u = h[0]) || void 0 === u ? void 0 : u.value) || s.message,
        $sentry_exception_type: null === (c = h[0]) || void 0 === c ? void 0 : c.type,
        $sentry_tags: s.tags
      };
    return t && i && (p.$sentry_url = (r || "https://sentry.io/organizations/") + t + "/issues/?project=" + i + "&query=" + s.event_id), e.exceptions.sendExceptionEvent(p), s;
  };
}
class Yr {
  constructor(e, t, i, r, n) {
    this.name = Gr, this.setupOnce = function (s) {
      s(Jr(e, {
        organization: t,
        projectId: i,
        prefix: r,
        severityAllowList: n
      }));
    };
  }
}
var Kr,
  Xr = null != t && t.location ? It(t.location.hash, "__posthog") || It(location.hash, "state") : null,
  Qr = "_postHogToolbarParams",
  Zr = B("[Toolbar]");
!function (e) {
  e[e.UNINITIALIZED = 0] = "UNINITIALIZED", e[e.LOADING = 1] = "LOADING", e[e.LOADED = 2] = "LOADED";
}(Kr || (Kr = {}));
class en {
  constructor(e) {
    this.instance = e;
  }
  setToolbarState(e) {
    _.ph_toolbar_state = e;
  }
  getToolbarState() {
    var e;
    return null !== (e = _.ph_toolbar_state) && void 0 !== e ? e : Kr.UNINITIALIZED;
  }
  maybeLoadToolbar() {
    var e,
      i,
      r = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    if (!t || !a) return !1;
    r = null !== (e = r) && void 0 !== e ? e : t.location, s = null !== (i = s) && void 0 !== i ? i : t.history;
    try {
      if (!n) {
        try {
          t.localStorage.setItem("test", "test"), t.localStorage.removeItem("test");
        } catch (e) {
          return !1;
        }
        n = null == t ? void 0 : t.localStorage;
      }
      var o,
        l = Xr || It(r.hash, "__posthog") || It(r.hash, "state"),
        u = l ? Z(() => JSON.parse(atob(decodeURIComponent(l)))) || Z(() => JSON.parse(decodeURIComponent(l))) : null;
      return u && "ph_authorize" === u.action ? ((o = u).source = "url", o && Object.keys(o).length > 0 && (u.desiredHash ? r.hash = u.desiredHash : s ? s.replaceState(s.state, "", r.pathname + r.search) : r.hash = "")) : ((o = JSON.parse(n.getItem(Qr) || "{}")).source = "localstorage", delete o.userIntent), !(!o.token || this.instance.config.token !== o.token) && (this.loadToolbar(o), !0);
    } catch (e) {
      return !1;
    }
  }
  _callLoadToolbar(e) {
    var t = _.ph_load_toolbar || _.ph_load_editor;
    !O(t) && I(t) ? t(e, this.instance) : Zr.warn("No toolbar load function found");
  }
  loadToolbar(e) {
    var i = !(null == a || !a.getElementById(Ue));
    if (!t || i) return !1;
    var r = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
      n = z(z({
        token: this.instance.config.token
      }, e), {}, {
        apiURL: this.instance.requestRouter.endpointFor("ui")
      }, r ? {
        instrument: !1
      } : {});
    if (t.localStorage.setItem(Qr, JSON.stringify(z(z({}, n), {}, {
      source: void 0
    }))), this.getToolbarState() === Kr.LOADED) this._callLoadToolbar(n);else if (this.getToolbarState() === Kr.UNINITIALIZED) {
      var s, o;
      this.setToolbarState(Kr.LOADING), null === (s = _.__PosthogExtensions__) || void 0 === s || null === (o = s.loadExternalDependency) || void 0 === o || o.call(s, this.instance, "toolbar", e => {
        if (e) return Zr.error("[Toolbar] Failed to load", e), void this.setToolbarState(Kr.UNINITIALIZED);
        this.setToolbarState(Kr.LOADED), this._callLoadToolbar(n);
      }), oe(t, "turbolinks:load", () => {
        this.setToolbarState(Kr.UNINITIALIZED), this.loadToolbar(n);
      });
    }
    return !0;
  }
  _loadEditor(e) {
    return this.loadToolbar(e);
  }
  maybeLoadEditor() {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    return this.maybeLoadToolbar(e, t, i);
  }
}
var tn = B("[TracingHeaders]");
class rn {
  constructor(e) {
    W(this, "_restoreXHRPatch", void 0), W(this, "_restoreFetchPatch", void 0), W(this, "_startCapturing", () => {
      var e, t, i, r;
      F(this._restoreXHRPatch) && (null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.tracingHeadersPatchFns) || void 0 === t || t._patchXHR(this.instance.sessionManager));
      F(this._restoreFetchPatch) && (null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.tracingHeadersPatchFns) || void 0 === r || r._patchFetch(this.instance.sessionManager));
    }), this.instance = e;
  }
  _loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.tracingHeadersPatchFns && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "tracing-headers", t => {
      if (t) return tn.error("failed to load script", t);
      e();
    });
  }
  startIfEnabledOrStop() {
    var e, t;
    this.instance.config.__add_tracing_headers ? this._loadScript(this._startCapturing) : (null === (e = this._restoreXHRPatch) || void 0 === e || e.call(this), null === (t = this._restoreFetchPatch) || void 0 === t || t.call(this), this._restoreXHRPatch = void 0, this._restoreFetchPatch = void 0);
  }
}
var nn = B("[Web Vitals]"),
  sn = 9e5;
class on {
  constructor(e) {
    var t;
    W(this, "_enabledServerSide", !1), W(this, "_initialized", !1), W(this, "buffer", {
      url: void 0,
      metrics: [],
      firstMetricTimestamp: void 0
    }), W(this, "_flushToCapture", () => {
      clearTimeout(this._delayedFlushTimer), 0 !== this.buffer.metrics.length && (this.instance.capture("$web_vitals", this.buffer.metrics.reduce((e, t) => z(z({}, e), {}, {
        ["$web_vitals_".concat(t.name, "_event")]: z({}, t),
        ["$web_vitals_".concat(t.name, "_value")]: t.value
      }), {})), this.buffer = {
        url: void 0,
        metrics: [],
        firstMetricTimestamp: void 0
      });
    }), W(this, "_addToBuffer", e => {
      var t,
        i = null === (t = this.instance.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0);
      if (F(i)) nn.error("Could not read session ID. Dropping metrics!");else {
        this.buffer = this.buffer || {
          url: void 0,
          metrics: [],
          firstMetricTimestamp: void 0
        };
        var r = this._currentURL();
        if (!F(r)) if (O(null == e ? void 0 : e.name) || O(null == e ? void 0 : e.value)) nn.error("Invalid metric received", e);else if (this._maxAllowedValue && e.value >= this._maxAllowedValue) nn.error("Ignoring metric with value >= " + this._maxAllowedValue, e);else this.buffer.url !== r && (this._flushToCapture(), this._delayedFlushTimer = setTimeout(this._flushToCapture, this.flushToCaptureTimeoutMs)), F(this.buffer.url) && (this.buffer.url = r), this.buffer.firstMetricTimestamp = F(this.buffer.firstMetricTimestamp) ? Date.now() : this.buffer.firstMetricTimestamp, e.attribution && e.attribution.interactionTargetElement && (e.attribution.interactionTargetElement = void 0), this.buffer.metrics.push(z(z({}, e), {}, {
          $current_url: r,
          $session_id: i.sessionId,
          $window_id: i.windowId,
          timestamp: Date.now()
        })), this.buffer.metrics.length === this.allowedMetrics.length && this._flushToCapture();
      }
    }), W(this, "_startCapturing", () => {
      var e,
        t,
        i,
        r,
        n = _.__PosthogExtensions__;
      F(n) || F(n.postHogWebVitalsCallbacks) || ({
        onLCP: e,
        onCLS: t,
        onFCP: i,
        onINP: r
      } = n.postHogWebVitalsCallbacks), e && t && i && r ? (this.allowedMetrics.indexOf("LCP") > -1 && e(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("CLS") > -1 && t(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("FCP") > -1 && i(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("INP") > -1 && r(this._addToBuffer.bind(this)), this._initialized = !0) : nn.error("web vitals callbacks not loaded - not starting");
    }), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[_e]), this.startIfEnabled();
  }
  get allowedMetrics() {
    var e,
      t,
      i = C(this.instance.config.capture_performance) ? null === (e = this.instance.config.capture_performance) || void 0 === e ? void 0 : e.web_vitals_allowed_metrics : void 0;
    return F(i) ? (null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.props[ve]) || ["CLS", "FCP", "INP", "LCP"] : i;
  }
  get flushToCaptureTimeoutMs() {
    return (C(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals_delayed_flush_ms : void 0) || 5e3;
  }
  get _maxAllowedValue() {
    var e = C(this.instance.config.capture_performance) && M(this.instance.config.capture_performance.__web_vitals_max_value) ? this.instance.config.capture_performance.__web_vitals_max_value : sn;
    return 0 < e && e <= 6e4 ? sn : e;
  }
  get isEnabled() {
    var e = C(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals : void 0;
    return A(e) ? e : this._enabledServerSide;
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (nn.info("enabled, starting..."), this.loadScript(this._startCapturing));
  }
  onRemoteConfig(e) {
    var t = C(e.capturePerformance) && !!e.capturePerformance.web_vitals,
      i = C(e.capturePerformance) ? e.capturePerformance.web_vitals_allowed_metrics : void 0;
    this.instance.persistence && (this.instance.persistence.register({
      [_e]: t
    }), this.instance.persistence.register({
      [ve]: i
    })), this._enabledServerSide = t, this.startIfEnabled();
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.postHogWebVitalsCallbacks && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "web-vitals", t => {
      t ? nn.error("failed to load script", t) : e();
    });
  }
  _currentURL() {
    var e = t ? t.location.href : void 0;
    return e || nn.error("Could not determine current URL"), e;
  }
}
var an = B("[Heatmaps]");
function ln(e) {
  return C(e) && "clientX" in e && "clientY" in e && M(e.clientX) && M(e.clientY);
}
class un {
  constructor(e) {
    var t;
    W(this, "rageclicks", new bt()), W(this, "_enabledServerSide", !1), W(this, "_initialized", !1), W(this, "_flushInterval", null), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[de]);
  }
  get flushIntervalMilliseconds() {
    var e = 5e3;
    return C(this.instance.config.capture_heatmaps) && this.instance.config.capture_heatmaps.flush_interval_milliseconds && (e = this.instance.config.capture_heatmaps.flush_interval_milliseconds), e;
  }
  get isEnabled() {
    return F(this.instance.config.capture_heatmaps) ? F(this.instance.config.enable_heatmaps) ? this._enabledServerSide : this.instance.config.enable_heatmaps : !1 !== this.instance.config.capture_heatmaps;
  }
  startIfEnabled() {
    if (this.isEnabled) {
      if (this._initialized) return;
      an.info("starting..."), this._setupListeners(), this._flushInterval = setInterval(this.flush.bind(this), this.flushIntervalMilliseconds);
    } else {
      var e, t;
      clearInterval(null !== (e = this._flushInterval) && void 0 !== e ? e : void 0), null === (t = this.deadClicksCapture) || void 0 === t || t.stop(), this.getAndClearBuffer();
    }
  }
  onRemoteConfig(e) {
    var t = !!e.heatmaps;
    this.instance.persistence && this.instance.persistence.register({
      [de]: t
    }), this._enabledServerSide = t, this.startIfEnabled();
  }
  getAndClearBuffer() {
    var e = this.buffer;
    return this.buffer = void 0, e;
  }
  _onDeadClick(e) {
    this._onClick(e.originalEvent, "deadclick");
  }
  _setupListeners() {
    t && a && (oe(t, "beforeunload", this.flush.bind(this)), oe(a, "click", e => this._onClick(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), oe(a, "mousemove", e => this._onMouseMove(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), this.deadClicksCapture = new si(this.instance, ri, this._onDeadClick.bind(this)), this.deadClicksCapture.startIfEnabled(), this._initialized = !0);
  }
  _getProperties(e, i) {
    var r = this.instance.scrollManager.scrollY(),
      n = this.instance.scrollManager.scrollX(),
      s = this.instance.scrollManager.scrollElement(),
      o = function (e, i, r) {
        for (var n = e; n && Ge(n) && !Je(n, "body");) {
          if (n === r) return !1;
          if (m(i, null == t ? void 0 : t.getComputedStyle(n).position)) return !0;
          n = nt(n);
        }
        return !1;
      }(it(e), ["fixed", "sticky"], s);
    return {
      x: e.clientX + (o ? 0 : n),
      y: e.clientY + (o ? 0 : r),
      target_fixed: o,
      type: i
    };
  }
  _onClick(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "click";
    if (!Ve(e.target) && ln(e)) {
      var r = this._getProperties(e, i);
      null !== (t = this.rageclicks) && void 0 !== t && t.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._capture(z(z({}, r), {}, {
        type: "rageclick"
      })), this._capture(r);
    }
  }
  _onMouseMove(e) {
    !Ve(e.target) && ln(e) && (clearTimeout(this._mouseMoveTimeout), this._mouseMoveTimeout = setTimeout(() => {
      this._capture(this._getProperties(e, "mousemove"));
    }, 500));
  }
  _capture(e) {
    if (t) {
      var i = t.location.href;
      this.buffer = this.buffer || {}, this.buffer[i] || (this.buffer[i] = []), this.buffer[i].push(e);
    }
  }
  flush() {
    this.buffer && !P(this.buffer) && this.instance.capture("$$heatmap", {
      $heatmap_data: this.getAndClearBuffer()
    });
  }
}
class cn {
  constructor(e) {
    this._instance = e;
  }
  doPageView(e, i) {
    var r,
      n = this._previousPageViewProperties(e, i);
    return this._currentPageview = {
      pathname: null !== (r = null == t ? void 0 : t.location.pathname) && void 0 !== r ? r : "",
      pageViewId: i,
      timestamp: e
    }, this._instance.scrollManager.resetContext(), n;
  }
  doPageLeave(e) {
    var t;
    return this._previousPageViewProperties(e, null === (t = this._currentPageview) || void 0 === t ? void 0 : t.pageViewId);
  }
  doEvent() {
    var e;
    return {
      $pageview_id: null === (e = this._currentPageview) || void 0 === e ? void 0 : e.pageViewId
    };
  }
  _previousPageViewProperties(e, t) {
    var i = this._currentPageview;
    if (!i) return {
      $pageview_id: t
    };
    var r = {
        $pageview_id: t,
        $prev_pageview_id: i.pageViewId
      },
      n = this._instance.scrollManager.getContext();
    if (n && !this._instance.config.disable_scroll_properties) {
      var {
        maxScrollHeight: s,
        lastScrollY: o,
        maxScrollY: a,
        maxContentHeight: l,
        lastContentY: u,
        maxContentY: c
      } = n;
      if (!(F(s) || F(o) || F(a) || F(l) || F(u) || F(c))) {
        s = Math.ceil(s), o = Math.ceil(o), a = Math.ceil(a), l = Math.ceil(l), u = Math.ceil(u), c = Math.ceil(c);
        var d = s <= 1 ? 1 : Ji(o / s, 0, 1),
          h = s <= 1 ? 1 : Ji(a / s, 0, 1),
          _ = l <= 1 ? 1 : Ji(u / l, 0, 1),
          p = l <= 1 ? 1 : Ji(c / l, 0, 1);
        r = K(r, {
          $prev_pageview_last_scroll: o,
          $prev_pageview_last_scroll_percentage: d,
          $prev_pageview_max_scroll: a,
          $prev_pageview_max_scroll_percentage: h,
          $prev_pageview_last_content: u,
          $prev_pageview_last_content_percentage: _,
          $prev_pageview_max_content: c,
          $prev_pageview_max_content_percentage: p
        });
      }
    }
    return i.pathname && (r.$prev_pageview_pathname = i.pathname), i.timestamp && (r.$prev_pageview_duration = (e.getTime() - i.timestamp.getTime()) / 1e3), r;
  }
}
class dn {
  constructor(e) {
    this.instance = e;
  }
  sendExceptionEvent(e) {
    this.instance.capture("$exception", e, {
      _noTruncate: !0,
      _batchKey: "exceptionEvent"
    });
  }
}
var hn = "Mobile",
  _n = "iOS",
  pn = "Android",
  vn = "Tablet",
  gn = pn + " " + vn,
  fn = "iPad",
  mn = "Apple",
  yn = mn + " Watch",
  bn = "Safari",
  wn = "BlackBerry",
  Sn = "Samsung",
  kn = Sn + "Browser",
  En = Sn + " Internet",
  xn = "Chrome",
  In = xn + " OS",
  Cn = xn + " " + _n,
  Pn = "Internet Explorer",
  Fn = Pn + " " + hn,
  Tn = "Opera",
  Rn = Tn + " Mini",
  $n = "Edge",
  On = "Microsoft " + $n,
  Mn = "Firefox",
  An = Mn + " " + _n,
  Ln = "Nintendo",
  Dn = "PlayStation",
  qn = "Xbox",
  Nn = pn + " " + hn,
  Bn = hn + " " + bn,
  Hn = "Windows",
  jn = Hn + " Phone",
  Un = "Nokia",
  zn = "Ouya",
  Wn = "Generic",
  Vn = Wn + " " + hn.toLowerCase(),
  Gn = Wn + " " + vn.toLowerCase(),
  Jn = "Konqueror",
  Yn = "(\\d+(\\.\\d+)?)",
  Kn = new RegExp("Version/" + Yn),
  Xn = new RegExp(qn, "i"),
  Qn = new RegExp(Dn + " \\w+", "i"),
  Zn = new RegExp(Ln + " \\w+", "i"),
  es = new RegExp(wn + "|PlayBook|BB10", "i"),
  ts = {
    "NT3.51": "NT 3.11",
    "NT4.0": "NT 4.0",
    "5.0": "2000",
    5.1: "XP",
    5.2: "XP",
    "6.0": "Vista",
    6.1: "7",
    6.2: "8",
    6.3: "8.1",
    6.4: "10",
    "10.0": "10"
  };
var is,
  rs = (e, t) => t && m(t, mn) || function (e) {
    return m(e, bn) && !m(e, xn) && !m(e, pn);
  }(e),
  ns = function (e, t) {
    return t = t || "", m(e, " OPR/") && m(e, "Mini") ? Rn : m(e, " OPR/") ? Tn : es.test(e) ? wn : m(e, "IE" + hn) || m(e, "WPDesktop") ? Fn : m(e, kn) ? En : m(e, $n) || m(e, "Edg/") ? On : m(e, "FBIOS") ? "Facebook " + hn : m(e, "UCWEB") || m(e, "UCBrowser") ? "UC Browser" : m(e, "CriOS") ? Cn : m(e, "CrMo") || m(e, xn) ? xn : m(e, pn) && m(e, bn) ? Nn : m(e, "FxiOS") ? An : m(e.toLowerCase(), Jn.toLowerCase()) ? Jn : rs(e, t) ? m(e, hn) ? Bn : bn : m(e, Mn) ? Mn : m(e, "MSIE") || m(e, "Trident/") ? Pn : m(e, "Gecko") ? Mn : "";
  },
  ss = {
    [Fn]: [new RegExp("rv:" + Yn)],
    [On]: [new RegExp($n + "?\\/" + Yn)],
    [xn]: [new RegExp("(" + xn + "|CrMo)\\/" + Yn)],
    [Cn]: [new RegExp("CriOS\\/" + Yn)],
    "UC Browser": [new RegExp("(UCBrowser|UCWEB)\\/" + Yn)],
    [bn]: [Kn],
    [Bn]: [Kn],
    [Tn]: [new RegExp("(Opera|OPR)\\/" + Yn)],
    [Mn]: [new RegExp(Mn + "\\/" + Yn)],
    [An]: [new RegExp("FxiOS\\/" + Yn)],
    [Jn]: [new RegExp("Konqueror[:/]?" + Yn, "i")],
    [wn]: [new RegExp(wn + " " + Yn), Kn],
    [Nn]: [new RegExp("android\\s" + Yn, "i")],
    [En]: [new RegExp(kn + "\\/" + Yn)],
    [Pn]: [new RegExp("(rv:|MSIE )" + Yn)],
    Mozilla: [new RegExp("rv:" + Yn)]
  },
  os = [[new RegExp(qn + "; " + qn + " (.*?)[);]", "i"), e => [qn, e && e[1] || ""]], [new RegExp(Ln, "i"), [Ln, ""]], [new RegExp(Dn, "i"), [Dn, ""]], [es, [wn, ""]], [new RegExp(Hn, "i"), (e, t) => {
    if (/Phone/.test(t) || /WPDesktop/.test(t)) return [jn, ""];
    if (new RegExp(hn).test(t) && !/IEMobile\b/.test(t)) return [Hn + " " + hn, ""];
    var i = /Windows NT ([0-9.]+)/i.exec(t);
    if (i && i[1]) {
      var r = i[1],
        n = ts[r] || "";
      return /arm/i.test(t) && (n = "RT"), [Hn, n];
    }
    return [Hn, ""];
  }], [/((iPhone|iPad|iPod).*?OS (\d+)_(\d+)_?(\d+)?|iPhone)/, e => {
    if (e && e[3]) {
      var t = [e[3], e[4], e[5] || "0"];
      return [_n, t.join(".")];
    }
    return [_n, ""];
  }], [/(watch.*\/(\d+\.\d+\.\d+)|watch os,(\d+\.\d+),)/i, e => {
    var t = "";
    return e && e.length >= 3 && (t = F(e[2]) ? e[3] : e[2]), ["watchOS", t];
  }], [new RegExp("(" + pn + " (\\d+)\\.(\\d+)\\.?(\\d+)?|" + pn + ")", "i"), e => {
    if (e && e[2]) {
      var t = [e[2], e[3], e[4] || "0"];
      return [pn, t.join(".")];
    }
    return [pn, ""];
  }], [/Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i, e => {
    var t = ["Mac OS X", ""];
    if (e && e[1]) {
      var i = [e[1], e[2], e[3] || "0"];
      t[1] = i.join(".");
    }
    return t;
  }], [/Mac/i, ["Mac OS X", ""]], [/CrOS/, [In, ""]], [/Linux|debian/i, ["Linux", ""]]],
  as = function (e) {
    return Zn.test(e) ? Ln : Qn.test(e) ? Dn : Xn.test(e) ? qn : new RegExp(zn, "i").test(e) ? zn : new RegExp("(" + jn + "|WPDesktop)", "i").test(e) ? jn : /iPad/.test(e) ? fn : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(e) ? yn : es.test(e) ? wn : /(kobo)\s(ereader|touch)/i.test(e) ? "Kobo" : new RegExp(Un, "i").test(e) ? Un : /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(e) || /(kf[a-z]+)( bui|\)).+silk\//i.test(e) ? "Kindle Fire" : /(Android|ZTE)/i.test(e) ? !new RegExp(hn).test(e) || /(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(e) ? /pixel[\daxl ]{1,6}/i.test(e) && !/pixel c/i.test(e) || /(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(e) || /lmy47v/i.test(e) && !/QTAQZ3/i.test(e) ? pn : gn : pn : new RegExp("(pda|" + hn + ")", "i").test(e) ? Vn : new RegExp(vn, "i").test(e) && !new RegExp(vn + " pc", "i").test(e) ? Gn : "";
  },
  ls = "https?://(.*)",
  us = ["gclid", "gclsrc", "dclid", "gbraid", "wbraid", "fbclid", "msclkid", "twclid", "li_fat_id", "igshid", "ttclid", "rdt_cid", "irclid", "_kx"],
  cs = X(["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gad_source", "mc_cid"], us),
  ds = "<masked>",
  hs = {
    campaignParams: function () {
      var {
        customTrackedParams: e,
        maskPersonalDataProperties: t,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!a) return {};
      var r = t ? X([], us, i || []) : [];
      return this._campaignParamsFromUrl(xt(a.URL, r, ds), e);
    },
    _campaignParamsFromUrl: function (e, t) {
      var i = cs.concat(t || []),
        r = {};
      return Y(i, function (t) {
        var i = Et(e, t);
        r[t] = i || null;
      }), r;
    },
    _searchEngine: function (e) {
      return e ? 0 === e.search(ls + "google.([^/?]*)") ? "google" : 0 === e.search(ls + "bing.com") ? "bing" : 0 === e.search(ls + "yahoo.com") ? "yahoo" : 0 === e.search(ls + "duckduckgo.com") ? "duckduckgo" : null : null;
    },
    _searchInfoFromReferrer: function (e) {
      var t = hs._searchEngine(e),
        i = "yahoo" != t ? "q" : "p",
        r = {};
      if (!$(t)) {
        r.$search_engine = t;
        var n = a ? Et(a.referrer, i) : "";
        n.length && (r.ph_keyword = n);
      }
      return r;
    },
    searchInfo: function () {
      var e = null == a ? void 0 : a.referrer;
      return e ? this._searchInfoFromReferrer(e) : {};
    },
    browser: ns,
    browserVersion: function (e, t) {
      var i = ns(e, t),
        r = ss[i];
      if (F(r)) return null;
      for (var n = 0; n < r.length; n++) {
        var s = r[n],
          o = e.match(s);
        if (o) return parseFloat(o[o.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    browserLanguagePrefix: function () {
      var e = this.browserLanguage();
      return "string" == typeof e ? e.split("-")[0] : void 0;
    },
    os: function (e) {
      for (var t = 0; t < os.length; t++) {
        var [i, r] = os[t],
          n = i.exec(e),
          s = n && (I(r) ? r(n, e) : r);
        if (s) return s;
      }
      return ["", ""];
    },
    device: as,
    deviceType: function (e) {
      var t = as(e);
      return t === fn || t === gn || "Kobo" === t || "Kindle Fire" === t || t === Gn ? vn : t === Ln || t === qn || t === Dn || t === zn ? "Console" : t === yn ? "Wearable" : t ? hn : "Desktop";
    },
    referrer: function () {
      return (null == a ? void 0 : a.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != a && a.referrer && (null === (e = St(a.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    referrerInfo: function () {
      return {
        $referrer: this.referrer(),
        $referring_domain: this.referringDomain()
      };
    },
    personInfo: function () {
      var {
          maskPersonalDataProperties: e,
          customPersonalDataProperties: t
        } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
        i = e ? X([], us, t || []) : [],
        r = null == l ? void 0 : l.href.substring(0, 1e3);
      return {
        r: this.referrer().substring(0, 1e3),
        u: r ? xt(r, i, ds) : void 0
      };
    },
    personPropsFromInfo: function (e) {
      var t,
        {
          r: i,
          u: r
        } = e,
        n = {
          $referrer: i,
          $referring_domain: null == i ? void 0 : "$direct" == i ? "$direct" : null === (t = St(i)) || void 0 === t ? void 0 : t.host
        };
      if (r) {
        n.$current_url = r;
        var s = St(r);
        n.$host = null == s ? void 0 : s.host, n.$pathname = null == s ? void 0 : s.pathname;
        var o = this._campaignParamsFromUrl(r);
        K(n, o);
      }
      if (i) {
        var a = this._searchInfoFromReferrer(i);
        K(n, a);
      }
      return n;
    },
    initialPersonPropsFromInfo: function (e) {
      var t = this.personPropsFromInfo(e),
        i = {};
      return Y(t, function (e, t) {
        i["$initial_".concat(b(t))] = e;
      }), i;
    },
    timezone: function () {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return;
      }
    },
    timezoneOffset: function () {
      try {
        return new Date().getTimezoneOffset();
      } catch (e) {
        return;
      }
    },
    properties: function () {
      var {
        maskPersonalDataProperties: e,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!h) return {};
      var r = e ? X([], us, i || []) : [],
        [n, s] = hs.os(h);
      return K(te({
        $os: n,
        $os_version: s,
        $browser: hs.browser(h, navigator.vendor),
        $device: hs.device(h),
        $device_type: hs.deviceType(h),
        $timezone: hs.timezone(),
        $timezone_offset: hs.timezoneOffset()
      }), {
        $current_url: xt(null == l ? void 0 : l.href, r, ds),
        $host: null == l ? void 0 : l.host,
        $pathname: null == l ? void 0 : l.pathname,
        $raw_user_agent: h.length > 1e3 ? h.substring(0, 997) + "..." : h,
        $browser_version: hs.browserVersion(h, navigator.vendor),
        $browser_language: hs.browserLanguage(),
        $browser_language_prefix: hs.browserLanguagePrefix(),
        $screen_height: null == t ? void 0 : t.screen.height,
        $screen_width: null == t ? void 0 : t.screen.width,
        $viewport_height: null == t ? void 0 : t.innerHeight,
        $viewport_width: null == t ? void 0 : t.innerWidth,
        $lib: "web",
        $lib_version: p.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: Date.now() / 1e3
      });
    },
    people_properties: function () {
      if (!h) return {};
      var [e, t] = hs.os(h);
      return K(te({
        $os: e,
        $os_version: t,
        $browser: hs.browser(h, navigator.vendor)
      }), {
        $browser_version: hs.browserVersion(h, navigator.vendor)
      });
    }
  },
  _s = B("[FeatureFlags]"),
  ps = "$active_feature_flags",
  vs = "$override_feature_flags",
  gs = "$feature_flag_payloads",
  fs = "$override_feature_flag_payloads",
  ms = "$feature_flag_request_id",
  ys = e => {
    var t = {};
    for (var [i, r] of Q(e || {})) r && (t[i] = r);
    return t;
  },
  bs = e => {
    var t = e.flags;
    return t ? (e.featureFlags = Object.fromEntries(Object.keys(t).map(e => {
      var i;
      return [e, null !== (i = t[e].variant) && void 0 !== i ? i : t[e].enabled];
    })), e.featureFlagPayloads = Object.fromEntries(Object.keys(t).filter(e => t[e].enabled).filter(e => {
      var i;
      return null === (i = t[e].metadata) || void 0 === i ? void 0 : i.payload;
    }).map(e => {
      var i;
      return [e, null === (i = t[e].metadata) || void 0 === i ? void 0 : i.payload];
    }))) : _s.warn("Using an older version of the feature flags endpoint. Please upgrade your PostHog server to the latest version"), e;
  };
!function (e) {
  e.FeatureFlags = "feature_flags", e.Recordings = "recordings";
}(is || (is = {}));
class ws {
  constructor(e) {
    W(this, "_override_warning", !1), W(this, "_hasLoadedFlags", !1), W(this, "_requestInFlight", !1), W(this, "_reloadingDisabled", !1), W(this, "_additionalReloadRequested", !1), W(this, "_decideCalled", !1), W(this, "_flagsLoadedFromRemote", !1), this.instance = e, this.featureFlagEventHandlers = [];
  }
  decide() {
    if (this.instance.config.__preview_remote_config) this._decideCalled = !0;else {
      var e = !this._reloadDebouncer && (this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load);
      this._callDecideEndpoint({
        disableFlags: e
      });
    }
  }
  get hasLoadedFlags() {
    return this._hasLoadedFlags;
  }
  getFlags() {
    return Object.keys(this.getFlagVariants());
  }
  getFlagsWithDetails() {
    var e = this.instance.get_property(Te),
      t = this.instance.get_property(vs),
      i = this.instance.get_property(fs);
    if (!i && !t) return e || {};
    var r = K({}, e || {}),
      n = [...new Set([...Object.keys(i || {}), ...Object.keys(t || {})])];
    for (var s of n) {
      var o,
        a,
        l = r[s],
        u = null == t ? void 0 : t[s],
        c = F(u) ? null !== (o = null == l ? void 0 : l.enabled) && void 0 !== o && o : !!u,
        d = F(u) ? l.variant : "string" == typeof u ? u : void 0,
        h = null == i ? void 0 : i[s],
        _ = z(z({}, l), {}, {
          enabled: c,
          variant: c ? null != d ? d : null == l ? void 0 : l.variant : void 0
        });
      if (c !== (null == l ? void 0 : l.enabled) && (_.original_enabled = null == l ? void 0 : l.enabled), d !== (null == l ? void 0 : l.variant) && (_.original_variant = null == l ? void 0 : l.variant), h) _.metadata = z(z({}, null == l ? void 0 : l.metadata), {}, {
        payload: h,
        original_payload: null == l || null === (a = l.metadata) || void 0 === a ? void 0 : a.payload
      });
      r[s] = _;
    }
    return this._override_warning || (_s.warn(" Overriding feature flag details!", {
      flagDetails: e,
      overriddenPayloads: i,
      finalDetails: r
    }), this._override_warning = !0), r;
  }
  getFlagVariants() {
    var e = this.instance.get_property(Pe),
      t = this.instance.get_property(vs);
    if (!t) return e || {};
    for (var i = K({}, e), r = Object.keys(t), n = 0; n < r.length; n++) i[r[n]] = t[r[n]];
    return this._override_warning || (_s.warn(" Overriding feature flags!", {
      enabledFlags: e,
      overriddenFlags: t,
      finalFlags: i
    }), this._override_warning = !0), i;
  }
  getFlagPayloads() {
    var e = this.instance.get_property(gs),
      t = this.instance.get_property(fs);
    if (!t) return e || {};
    for (var i = K({}, e || {}), r = Object.keys(t), n = 0; n < r.length; n++) i[r[n]] = t[r[n]];
    return this._override_warning || (_s.warn(" Overriding feature flag payloads!", {
      flagPayloads: e,
      overriddenPayloads: t,
      finalPayloads: i
    }), this._override_warning = !0), i;
  }
  reloadFeatureFlags() {
    this._reloadingDisabled || this.instance.config.advanced_disable_feature_flags || this._reloadDebouncer || (this._reloadDebouncer = setTimeout(() => {
      this._callDecideEndpoint();
    }, 5));
  }
  clearDebouncer() {
    clearTimeout(this._reloadDebouncer), this._reloadDebouncer = void 0;
  }
  ensureFlagsLoaded() {
    this._hasLoadedFlags || this._requestInFlight || this._reloadDebouncer || this.reloadFeatureFlags();
  }
  setAnonymousDistinctId(e) {
    this.$anon_distinct_id = e;
  }
  setReloadingPaused(e) {
    this._reloadingDisabled = e;
  }
  _callDecideEndpoint(t) {
    var i;
    if (this.clearDebouncer(), !this.instance.config.advanced_disable_decide) if (this._requestInFlight) this._additionalReloadRequested = !0;else {
      var r = {
        token: this.instance.config.token,
        distinct_id: this.instance.get_distinct_id(),
        groups: this.instance.getGroups(),
        $anon_distinct_id: this.$anon_distinct_id,
        person_properties: z(z({}, (null === (i = this.instance.persistence) || void 0 === i ? void 0 : i.get_initial_props()) || {}), this.instance.get_property(Re) || {}),
        group_properties: this.instance.get_property($e)
      };
      (null != t && t.disableFlags || this.instance.config.advanced_disable_feature_flags) && (r.disable_flags = !0);
      var n = this.instance.config.__preview_flags_v2 && this.instance.config.__preview_remote_config;
      n && (r.timezone = hs.timezone()), this._requestInFlight = !0, this.instance._send_request({
        method: "POST",
        url: this.instance.requestRouter.endpointFor("api", n ? "/flags/?v=2" : "/decide/?v=4"),
        data: r,
        compression: this.instance.config.disable_compression ? void 0 : e.Base64,
        timeout: this.instance.config.feature_flag_request_timeout_ms,
        callback: e => {
          var t,
            i,
            n,
            s = !0;
          (200 === e.statusCode && (this._additionalReloadRequested || (this.$anon_distinct_id = void 0), s = !1), this._requestInFlight = !1, this._decideCalled) || (this._decideCalled = !0, this.instance._onRemoteConfig(null !== (n = e.json) && void 0 !== n ? n : {}));
          r.disable_flags && !this._additionalReloadRequested || (this._flagsLoadedFromRemote = !s, e.json && null !== (t = e.json.quotaLimited) && void 0 !== t && t.includes(is.FeatureFlags) ? _s.warn("You have hit your feature flags quota limit, and will not be able to load feature flags until the quota is reset.  Please visit https://posthog.com/docs/billing/limits-alerts to learn more.") : (this.receivedFeatureFlags(null !== (i = e.json) && void 0 !== i ? i : {}, s), this._additionalReloadRequested && (this._additionalReloadRequested = !1, this._callDecideEndpoint())));
        }
      });
    }
  }
  getFeatureFlag(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) {
      var i = this.getFlagVariants()[e],
        r = "".concat(i),
        n = this.instance.get_property(ms) || void 0,
        s = this.instance.get_property(Ae) || {};
      if ((t.send_event || !("send_event" in t)) && (!(e in s) || !s[e].includes(r))) {
        var o, a, l, u, c, d, h, _, p, v, g;
        x(s[e]) ? s[e].push(r) : s[e] = [r], null === (o = this.instance.persistence) || void 0 === o || o.register({
          [Ae]: s
        });
        var f = this.getFeatureFlagDetails(e),
          m = {
            $feature_flag: e,
            $feature_flag_response: i,
            $feature_flag_payload: this.getFeatureFlagPayload(e) || null,
            $feature_flag_request_id: n,
            $feature_flag_bootstrapped_response: (null === (a = this.instance.config.bootstrap) || void 0 === a || null === (l = a.featureFlags) || void 0 === l ? void 0 : l[e]) || null,
            $feature_flag_bootstrapped_payload: (null === (u = this.instance.config.bootstrap) || void 0 === u || null === (c = u.featureFlagPayloads) || void 0 === c ? void 0 : c[e]) || null,
            $used_bootstrap_value: !this._flagsLoadedFromRemote
          };
        F(null == f || null === (d = f.metadata) || void 0 === d ? void 0 : d.version) || (m.$feature_flag_version = f.metadata.version);
        var y,
          b = null !== (h = null == f || null === (_ = f.reason) || void 0 === _ ? void 0 : _.description) && void 0 !== h ? h : null == f || null === (p = f.reason) || void 0 === p ? void 0 : p.code;
        if (b && (m.$feature_flag_reason = b), null != f && null !== (v = f.metadata) && void 0 !== v && v.id && (m.$feature_flag_id = f.metadata.id), F(null == f ? void 0 : f.original_variant) && F(null == f ? void 0 : f.original_enabled) || (m.$feature_flag_original_response = F(f.original_variant) ? f.original_enabled : f.original_variant), null != f && null !== (g = f.metadata) && void 0 !== g && g.original_payload) m.$feature_flag_original_payload = null == f || null === (y = f.metadata) || void 0 === y ? void 0 : y.original_payload;
        this.instance.capture("$feature_flag_called", m);
      }
      return i;
    }
    _s.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  getFeatureFlagDetails(e) {
    return this.getFlagsWithDetails()[e];
  }
  getFeatureFlagPayload(e) {
    return this.getFlagPayloads()[e];
  }
  getRemoteConfigPayload(t, i) {
    var r = this.instance.config.token;
    this.instance._send_request({
      method: "POST",
      url: this.instance.requestRouter.endpointFor("api", "/decide/?v=4"),
      data: {
        distinct_id: this.instance.get_distinct_id(),
        token: r
      },
      compression: this.instance.config.disable_compression ? void 0 : e.Base64,
      timeout: this.instance.config.feature_flag_request_timeout_ms,
      callback: e => {
        var r,
          n = null === (r = e.json) || void 0 === r ? void 0 : r.featureFlagPayloads;
        i((null == n ? void 0 : n[t]) || void 0);
      }
    });
  }
  isFeatureEnabled(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
    _s.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  addFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers.push(e);
  }
  removeFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(t => t !== e);
  }
  receivedFeatureFlags(e, t) {
    if (this.instance.persistence) {
      this._hasLoadedFlags = !0;
      var i = this.getFlagVariants(),
        r = this.getFlagPayloads(),
        n = this.getFlagsWithDetails();
      !function (e, t) {
        var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
          r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
          n = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : {},
          s = bs(e),
          o = s.flags,
          a = s.featureFlags,
          l = s.featureFlagPayloads;
        if (a) {
          var u = e.requestId;
          if (x(a)) {
            _s.warn("v1 of the feature flags endpoint is deprecated. Please use the latest version.");
            var c = {};
            if (a) for (var d = 0; d < a.length; d++) c[a[d]] = !0;
            t && t.register({
              [ps]: a,
              [Pe]: c
            });
          } else {
            var h = a,
              _ = l,
              p = o;
            e.errorsWhileComputingFlags && (h = z(z({}, i), h), _ = z(z({}, r), _), p = z(z({}, n), p)), t && t.register(z({
              [ps]: Object.keys(ys(h)),
              [Pe]: h || {},
              [gs]: _ || {},
              [Te]: p || {}
            }, u ? {
              [ms]: u
            } : {}));
          }
        }
      }(e, this.instance.persistence, i, r, n), this._fireFeatureFlagsCallbacks(t);
    }
  }
  override(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    _s.warn("override is deprecated. Please use overrideFeatureFlags instead."), this.overrideFeatureFlags({
      flags: e,
      suppressWarning: t
    });
  }
  overrideFeatureFlags(e) {
    if (!this.instance.__loaded || !this.instance.persistence) return _s.uninitializedWarning("posthog.featureFlags.overrideFeatureFlags");
    if (!1 === e) return this.instance.persistence.unregister(vs), this.instance.persistence.unregister(fs), void this._fireFeatureFlagsCallbacks();
    if (e && "object" == typeof e && ("flags" in e || "payloads" in e)) {
      var t,
        i = e;
      if (this._override_warning = Boolean(null !== (t = i.suppressWarning) && void 0 !== t && t), "flags" in i) if (!1 === i.flags) this.instance.persistence.unregister(vs);else if (i.flags) if (x(i.flags)) {
        for (var r = {}, n = 0; n < i.flags.length; n++) r[i.flags[n]] = !0;
        this.instance.persistence.register({
          [vs]: r
        });
      } else this.instance.persistence.register({
        [vs]: i.flags
      });
      return "payloads" in i && (!1 === i.payloads ? this.instance.persistence.unregister(fs) : i.payloads && this.instance.persistence.register({
        [fs]: i.payloads
      })), void this._fireFeatureFlagsCallbacks();
    }
    this._fireFeatureFlagsCallbacks();
  }
  onFeatureFlags(e) {
    if (this.addFeatureFlagsHandler(e), this._hasLoadedFlags) {
      var {
        flags: t,
        flagVariants: i
      } = this._prepareFeatureFlagsForCallbacks();
      e(t, i);
    }
    return () => this.removeFeatureFlagsHandler(e);
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    var i,
      r = (this.instance.get_property(Fe) || []).find(t => t.flagKey === e),
      n = {
        ["$feature_enrollment/".concat(e)]: t
      },
      s = {
        $feature_flag: e,
        $feature_enrollment: t,
        $set: n
      };
    r && (s.$early_access_feature_name = r.name), this.instance.capture("$feature_enrollment_update", s), this.setPersonPropertiesForFlags(n, !1);
    var o = z(z({}, this.getFlagVariants()), {}, {
      [e]: t
    });
    null === (i = this.instance.persistence) || void 0 === i || i.register({
      [ps]: Object.keys(ys(o)),
      [Pe]: o
    }), this._fireFeatureFlagsCallbacks();
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      i = arguments.length > 2 ? arguments[2] : void 0,
      r = this.instance.get_property(Fe),
      n = i ? "&".concat(i.map(e => "stage=".concat(e)).join("&")) : "";
    if (r && !t) return e(r);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/early_access_features/?token=".concat(this.instance.config.token).concat(n)),
      method: "GET",
      callback: t => {
        var i;
        if (t.json) {
          var r = t.json.earlyAccessFeatures;
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [Fe]: r
          }), e(r);
        }
      }
    });
  }
  _prepareFeatureFlagsForCallbacks() {
    var e = this.getFlags(),
      t = this.getFlagVariants();
    return {
      flags: e.filter(e => t[e]),
      flagVariants: Object.keys(t).filter(e => t[e]).reduce((e, i) => (e[i] = t[i], e), {})
    };
  }
  _fireFeatureFlagsCallbacks(e) {
    var {
      flags: t,
      flagVariants: i
    } = this._prepareFeatureFlagsForCallbacks();
    this.featureFlagEventHandlers.forEach(r => r(t, i, {
      errorsLoading: e
    }));
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property(Re) || {};
    this.instance.register({
      [Re]: z(z({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetPersonPropertiesForFlags() {
    this.instance.unregister(Re);
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property($e) || {};
    0 !== Object.keys(i).length && Object.keys(i).forEach(t => {
      i[t] = z(z({}, i[t]), e[t]), delete e[t];
    }), this.instance.register({
      [$e]: z(z({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetGroupPropertiesForFlags(e) {
    if (e) {
      var t = this.instance.get_property($e) || {};
      this.instance.register({
        [$e]: z(z({}, t), {}, {
          [e]: {}
        })
      });
    } else this.instance.unregister($e);
  }
}
var Ss = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"];
class ks {
  constructor(e) {
    this.config = e, this.props = {}, this.campaign_params_saved = !1, this.name = (e => {
      var t = "";
      return e.token && (t = e.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), e.persistence_name ? "ph_" + e.persistence_name : "ph_" + t + "_posthog";
    })(e), this.storage = this.buildStorage(e), this.load(), e.debug && N.info("Persistence loaded", e.persistence, z({}, this.props)), this.update_config(e, e), this.save();
  }
  buildStorage(e) {
    -1 === Ss.indexOf(e.persistence.toLowerCase()) && (N.critical("Unknown persistence type " + e.persistence + "; falling back to localStorage+cookie"), e.persistence = "localStorage+cookie");
    var t = e.persistence.toLowerCase();
    return "localstorage" === t && Jt.is_supported() ? Jt : "localstorage+cookie" === t && Kt.is_supported() ? Kt : "sessionstorage" === t && ei.is_supported() ? ei : "memory" === t ? Qt : "cookie" === t ? Vt : Kt.is_supported() ? Kt : Vt;
  }
  properties() {
    var e = {};
    return Y(this.props, function (t, i) {
      if (i === Pe && C(t)) for (var r = Object.keys(t), n = 0; n < r.length; n++) e["$feature/".concat(r[n])] = t[r[n]];else a = i, l = !1, ($(o = We) ? l : s && o.indexOf === s ? -1 != o.indexOf(a) : (Y(o, function (e) {
        if (l || (l = e === a)) return G;
      }), l)) || (e[i] = t);
      var o, a, l;
    }), e;
  }
  load() {
    if (!this.disabled) {
      var e = this.storage.parse(this.name);
      e && (this.props = K({}, e));
    }
  }
  save() {
    this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure, this.config.debug);
  }
  remove() {
    this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
  }
  clear() {
    this.remove(), this.props = {};
  }
  register_once(e, t, i) {
    if (C(e)) {
      F(t) && (t = "None"), this.expire_days = F(i) ? this.default_expiry : i;
      var r = !1;
      if (Y(e, (e, i) => {
        this.props.hasOwnProperty(i) && this.props[i] !== t || (this.props[i] = e, r = !0);
      }), r) return this.save(), !0;
    }
    return !1;
  }
  register(e, t) {
    if (C(e)) {
      this.expire_days = F(t) ? this.default_expiry : t;
      var i = !1;
      if (Y(e, (t, r) => {
        e.hasOwnProperty(r) && this.props[r] !== t && (this.props[r] = t, i = !0);
      }), i) return this.save(), !0;
    }
    return !1;
  }
  unregister(e) {
    e in this.props && (delete this.props[e], this.save());
  }
  update_campaign_params() {
    if (!this.campaign_params_saved) {
      var e = hs.campaignParams({
        customTrackedParams: this.config.custom_campaign_params,
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
      P(te(e)) || this.register(e), this.campaign_params_saved = !0;
    }
  }
  update_search_keyword() {
    this.register(hs.searchInfo());
  }
  update_referrer_info() {
    this.register_once(hs.referrerInfo(), void 0);
  }
  set_initial_person_info() {
    this.props[Ne] || this.props[Be] || this.register_once({
      [He]: hs.personInfo({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      })
    }, void 0);
  }
  get_referrer_info() {
    return te({
      $referrer: this.props.$referrer,
      $referring_domain: this.props.$referring_domain
    });
  }
  get_initial_props() {
    var e = {};
    Y([Be, Ne], t => {
      var i = this.props[t];
      i && Y(i, function (t, i) {
        e["$initial_" + b(i)] = t;
      });
    });
    var t = this.props[He];
    if (t) {
      var i = hs.initialPersonPropsFromInfo(t);
      K(e, i);
    }
    return e;
  }
  safe_merge(e) {
    return Y(this.props, function (t, i) {
      i in e || (e[i] = t);
    }), e;
  }
  update_config(e, t) {
    if (this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie), e.persistence !== t.persistence) {
      var i = this.buildStorage(e),
        r = this.props;
      this.clear(), this.storage = i, this.props = r, this.save();
    }
  }
  set_disabled(e) {
    this.disabled = e, this.disabled ? this.remove() : this.save();
  }
  set_cross_subdomain(e) {
    e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
  }
  get_cross_subdomain() {
    return !!this.cross_subdomain;
  }
  set_secure(e) {
    e !== this.secure && (this.secure = e, this.remove(), this.save());
  }
  set_event_timer(e, t) {
    var i = this.props[ue] || {};
    i[e] = t, this.props[ue] = i, this.save();
  }
  remove_event_timer(e) {
    var t = (this.props[ue] || {})[e];
    return F(t) || (delete this.props[ue][e], this.save()), t;
  }
  get_property(e) {
    return this.props[e];
  }
  set_property(e, t) {
    this.props[e] = t, this.save();
  }
}
var Es,
  xs,
  Is,
  Cs,
  Ps,
  Fs,
  Ts,
  Rs,
  $s,
  Os,
  Ms,
  As,
  Ls = {},
  Ds = [],
  qs = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i,
  Ns = Array.isArray;
function Bs(e, t) {
  for (var i in t) e[i] = t[i];
  return e;
}
function Hs(e) {
  var t = e.parentNode;
  t && t.removeChild(e);
}
function js(e, t, i, r, n) {
  var s = {
    type: e,
    props: t,
    key: i,
    ref: r,
    __k: null,
    __: null,
    __b: 0,
    __e: null,
    __d: void 0,
    __c: null,
    constructor: void 0,
    __v: null == n ? ++Is : n,
    __i: -1,
    __u: 0
  };
  return null == n && null != xs.vnode && xs.vnode(s), s;
}
function Us(e) {
  return e.children;
}
function zs(e, t) {
  this.props = e, this.context = t;
}
function Ws(e, t) {
  if (null == t) return e.__ ? Ws(e.__, e.__i + 1) : null;
  for (var i; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) return i.__e;
  return "function" == typeof e.type ? Ws(e) : null;
}
function Vs(e) {
  var t, i;
  if (null != (e = e.__) && null != e.__c) {
    for (e.__e = e.__c.base = null, t = 0; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) {
      e.__e = e.__c.base = i.__e;
      break;
    }
    return Vs(e);
  }
}
function Gs(e) {
  (!e.__d && (e.__d = !0) && Cs.push(e) && !Js.__r++ || Ps !== xs.debounceRendering) && ((Ps = xs.debounceRendering) || Fs)(Js);
}
function Js() {
  var e, t, i, r, n, s, o, a, l;
  for (Cs.sort(Ts); e = Cs.shift();) e.__d && (t = Cs.length, r = void 0, s = (n = (i = e).__v).__e, a = [], l = [], (o = i.__P) && ((r = Bs({}, n)).__v = n.__v + 1, xs.vnode && xs.vnode(r), ro(o, r, n, i.__n, void 0 !== o.ownerSVGElement, 32 & n.__u ? [s] : null, a, null == s ? Ws(n) : s, !!(32 & n.__u), l), r.__.__k[r.__i] = r, no(a, r, l), r.__e != s && Vs(r)), Cs.length > t && Cs.sort(Ts));
  Js.__r = 0;
}
function Ys(e, t, i, r, n, s, o, a, l, u, c) {
  var d,
    h,
    _,
    p,
    v,
    g = r && r.__k || Ds,
    f = t.length;
  for (i.__d = l, Ks(i, t, g), l = i.__d, d = 0; d < f; d++) null != (_ = i.__k[d]) && "boolean" != typeof _ && "function" != typeof _ && (h = -1 === _.__i ? Ls : g[_.__i] || Ls, _.__i = d, ro(e, _, h, n, s, o, a, l, u, c), p = _.__e, _.ref && h.ref != _.ref && (h.ref && oo(h.ref, null, _), c.push(_.ref, _.__c || p, _)), null == v && null != p && (v = p), 65536 & _.__u || h.__k === _.__k ? l = Xs(_, l, e) : "function" == typeof _.type && void 0 !== _.__d ? l = _.__d : p && (l = p.nextSibling), _.__d = void 0, _.__u &= -196609);
  i.__d = l, i.__e = v;
}
function Ks(e, t, i) {
  var r,
    n,
    s,
    o,
    a,
    l = t.length,
    u = i.length,
    c = u,
    d = 0;
  for (e.__k = [], r = 0; r < l; r++) null != (n = e.__k[r] = null == (n = t[r]) || "boolean" == typeof n || "function" == typeof n ? null : "string" == typeof n || "number" == typeof n || "bigint" == typeof n || n.constructor == String ? js(null, n, null, null, n) : Ns(n) ? js(Us, {
    children: n
  }, null, null, null) : void 0 === n.constructor && n.__b > 0 ? js(n.type, n.props, n.key, n.ref ? n.ref : null, n.__v) : n) ? (n.__ = e, n.__b = e.__b + 1, a = Qs(n, i, o = r + d, c), n.__i = a, s = null, -1 !== a && (c--, (s = i[a]) && (s.__u |= 131072)), null == s || null === s.__v ? (-1 == a && d--, "function" != typeof n.type && (n.__u |= 65536)) : a !== o && (a === o + 1 ? d++ : a > o ? c > l - o ? d += a - o : d-- : d = a < o && a == o - 1 ? a - o : 0, a !== r + d && (n.__u |= 65536))) : (s = i[r]) && null == s.key && s.__e && (s.__e == e.__d && (e.__d = Ws(s)), ao(s, s, !1), i[r] = null, c--);
  if (c) for (r = 0; r < u; r++) null != (s = i[r]) && 0 == (131072 & s.__u) && (s.__e == e.__d && (e.__d = Ws(s)), ao(s, s));
}
function Xs(e, t, i) {
  var r, n;
  if ("function" == typeof e.type) {
    for (r = e.__k, n = 0; r && n < r.length; n++) r[n] && (r[n].__ = e, t = Xs(r[n], t, i));
    return t;
  }
  return e.__e != t && (i.insertBefore(e.__e, t || null), t = e.__e), t && t.nextSibling;
}
function Qs(e, t, i, r) {
  var n = e.key,
    s = e.type,
    o = i - 1,
    a = i + 1,
    l = t[i];
  if (null === l || l && n == l.key && s === l.type) return i;
  if (r > (null != l && 0 == (131072 & l.__u) ? 1 : 0)) for (; o >= 0 || a < t.length;) {
    if (o >= 0) {
      if ((l = t[o]) && 0 == (131072 & l.__u) && n == l.key && s === l.type) return o;
      o--;
    }
    if (a < t.length) {
      if ((l = t[a]) && 0 == (131072 & l.__u) && n == l.key && s === l.type) return a;
      a++;
    }
  }
  return -1;
}
function Zs(e, t, i) {
  "-" === t[0] ? e.setProperty(t, null == i ? "" : i) : e[t] = null == i ? "" : "number" != typeof i || qs.test(t) ? i : i + "px";
}
function eo(e, t, i, r, n) {
  var s;
  e: if ("style" === t) {
    if ("string" == typeof i) e.style.cssText = i;else {
      if ("string" == typeof r && (e.style.cssText = r = ""), r) for (t in r) i && t in i || Zs(e.style, t, "");
      if (i) for (t in i) r && i[t] === r[t] || Zs(e.style, t, i[t]);
    }
  } else if ("o" === t[0] && "n" === t[1]) s = t !== (t = t.replace(/(PointerCapture)$|Capture$/, "$1")), t = t.toLowerCase() in e ? t.toLowerCase().slice(2) : t.slice(2), e.l || (e.l = {}), e.l[t + s] = i, i ? r ? i.u = r.u : (i.u = Date.now(), e.addEventListener(t, s ? io : to, s)) : e.removeEventListener(t, s ? io : to, s);else {
    if (n) t = t.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");else if ("width" !== t && "height" !== t && "href" !== t && "list" !== t && "form" !== t && "tabIndex" !== t && "download" !== t && "rowSpan" !== t && "colSpan" !== t && "role" !== t && t in e) try {
      e[t] = null == i ? "" : i;
      break e;
    } catch (e) {}
    "function" == typeof i || (null == i || !1 === i && "-" !== t[4] ? e.removeAttribute(t) : e.setAttribute(t, i));
  }
}
function to(e) {
  var t = this.l[e.type + !1];
  if (e.t) {
    if (e.t <= t.u) return;
  } else e.t = Date.now();
  return t(xs.event ? xs.event(e) : e);
}
function io(e) {
  return this.l[e.type + !0](xs.event ? xs.event(e) : e);
}
function ro(e, t, i, r, n, s, o, a, l, u) {
  var c,
    d,
    h,
    _,
    p,
    v,
    g,
    f,
    m,
    y,
    b,
    w,
    S,
    k,
    E,
    x = t.type;
  if (void 0 !== t.constructor) return null;
  128 & i.__u && (l = !!(32 & i.__u), s = [a = t.__e = i.__e]), (c = xs.__b) && c(t);
  e: if ("function" == typeof x) try {
    if (f = t.props, m = (c = x.contextType) && r[c.__c], y = c ? m ? m.props.value : c.__ : r, i.__c ? g = (d = t.__c = i.__c).__ = d.__E : ("prototype" in x && x.prototype.render ? t.__c = d = new x(f, y) : (t.__c = d = new zs(f, y), d.constructor = x, d.render = lo), m && m.sub(d), d.props = f, d.state || (d.state = {}), d.context = y, d.__n = r, h = d.__d = !0, d.__h = [], d._sb = []), null == d.__s && (d.__s = d.state), null != x.getDerivedStateFromProps && (d.__s == d.state && (d.__s = Bs({}, d.__s)), Bs(d.__s, x.getDerivedStateFromProps(f, d.__s))), _ = d.props, p = d.state, d.__v = t, h) null == x.getDerivedStateFromProps && null != d.componentWillMount && d.componentWillMount(), null != d.componentDidMount && d.__h.push(d.componentDidMount);else {
      if (null == x.getDerivedStateFromProps && f !== _ && null != d.componentWillReceiveProps && d.componentWillReceiveProps(f, y), !d.__e && (null != d.shouldComponentUpdate && !1 === d.shouldComponentUpdate(f, d.__s, y) || t.__v === i.__v)) {
        for (t.__v !== i.__v && (d.props = f, d.state = d.__s, d.__d = !1), t.__e = i.__e, t.__k = i.__k, t.__k.forEach(function (e) {
          e && (e.__ = t);
        }), b = 0; b < d._sb.length; b++) d.__h.push(d._sb[b]);
        d._sb = [], d.__h.length && o.push(d);
        break e;
      }
      null != d.componentWillUpdate && d.componentWillUpdate(f, d.__s, y), null != d.componentDidUpdate && d.__h.push(function () {
        d.componentDidUpdate(_, p, v);
      });
    }
    if (d.context = y, d.props = f, d.__P = e, d.__e = !1, w = xs.__r, S = 0, "prototype" in x && x.prototype.render) {
      for (d.state = d.__s, d.__d = !1, w && w(t), c = d.render(d.props, d.state, d.context), k = 0; k < d._sb.length; k++) d.__h.push(d._sb[k]);
      d._sb = [];
    } else do {
      d.__d = !1, w && w(t), c = d.render(d.props, d.state, d.context), d.state = d.__s;
    } while (d.__d && ++S < 25);
    d.state = d.__s, null != d.getChildContext && (r = Bs(Bs({}, r), d.getChildContext())), h || null == d.getSnapshotBeforeUpdate || (v = d.getSnapshotBeforeUpdate(_, p)), Ys(e, Ns(E = null != c && c.type === Us && null == c.key ? c.props.children : c) ? E : [E], t, i, r, n, s, o, a, l, u), d.base = t.__e, t.__u &= -161, d.__h.length && o.push(d), g && (d.__E = d.__ = null);
  } catch (e) {
    t.__v = null, l || null != s ? (t.__e = a, t.__u |= l ? 160 : 32, s[s.indexOf(a)] = null) : (t.__e = i.__e, t.__k = i.__k), xs.__e(e, t, i);
  } else null == s && t.__v === i.__v ? (t.__k = i.__k, t.__e = i.__e) : t.__e = so(i.__e, t, i, r, n, s, o, l, u);
  (c = xs.diffed) && c(t);
}
function no(e, t, i) {
  t.__d = void 0;
  for (var r = 0; r < i.length; r++) oo(i[r], i[++r], i[++r]);
  xs.__c && xs.__c(t, e), e.some(function (t) {
    try {
      e = t.__h, t.__h = [], e.some(function (e) {
        e.call(t);
      });
    } catch (e) {
      xs.__e(e, t.__v);
    }
  });
}
function so(e, t, i, r, n, s, o, a, l) {
  var u,
    c,
    d,
    h,
    _,
    p,
    v,
    g = i.props,
    f = t.props,
    m = t.type;
  if ("svg" === m && (n = !0), null != s) for (u = 0; u < s.length; u++) if ((_ = s[u]) && "setAttribute" in _ == !!m && (m ? _.localName === m : 3 === _.nodeType)) {
    e = _, s[u] = null;
    break;
  }
  if (null == e) {
    if (null === m) return document.createTextNode(f);
    e = n ? document.createElementNS("http://www.w3.org/2000/svg", m) : document.createElement(m, f.is && f), s = null, a = !1;
  }
  if (null === m) g === f || a && e.data === f || (e.data = f);else {
    if (s = s && Es.call(e.childNodes), g = i.props || Ls, !a && null != s) for (g = {}, u = 0; u < e.attributes.length; u++) g[(_ = e.attributes[u]).name] = _.value;
    for (u in g) _ = g[u], "children" == u || ("dangerouslySetInnerHTML" == u ? d = _ : "key" === u || u in f || eo(e, u, null, _, n));
    for (u in f) _ = f[u], "children" == u ? h = _ : "dangerouslySetInnerHTML" == u ? c = _ : "value" == u ? p = _ : "checked" == u ? v = _ : "key" === u || a && "function" != typeof _ || g[u] === _ || eo(e, u, _, g[u], n);
    if (c) a || d && (c.__html === d.__html || c.__html === e.innerHTML) || (e.innerHTML = c.__html), t.__k = [];else if (d && (e.innerHTML = ""), Ys(e, Ns(h) ? h : [h], t, i, r, n && "foreignObject" !== m, s, o, s ? s[0] : i.__k && Ws(i, 0), a, l), null != s) for (u = s.length; u--;) null != s[u] && Hs(s[u]);
    a || (u = "value", void 0 !== p && (p !== e[u] || "progress" === m && !p || "option" === m && p !== g[u]) && eo(e, u, p, g[u], !1), u = "checked", void 0 !== v && v !== e[u] && eo(e, u, v, g[u], !1));
  }
  return e;
}
function oo(e, t, i) {
  try {
    "function" == typeof e ? e(t) : e.current = t;
  } catch (e) {
    xs.__e(e, i);
  }
}
function ao(e, t, i) {
  var r, n;
  if (xs.unmount && xs.unmount(e), (r = e.ref) && (r.current && r.current !== e.__e || oo(r, null, t)), null != (r = e.__c)) {
    if (r.componentWillUnmount) try {
      r.componentWillUnmount();
    } catch (e) {
      xs.__e(e, t);
    }
    r.base = r.__P = null, e.__c = void 0;
  }
  if (r = e.__k) for (n = 0; n < r.length; n++) r[n] && ao(r[n], t, i || "function" != typeof e.type);
  i || null == e.__e || Hs(e.__e), e.__ = e.__e = e.__d = void 0;
}
function lo(e, t, i) {
  return this.constructor(e, i);
}
Es = Ds.slice, xs = {
  __e: function (e, t, i, r) {
    for (var n, s, o; t = t.__;) if ((n = t.__c) && !n.__) try {
      if ((s = n.constructor) && null != s.getDerivedStateFromError && (n.setState(s.getDerivedStateFromError(e)), o = n.__d), null != n.componentDidCatch && (n.componentDidCatch(e, r || {}), o = n.__d), o) return n.__E = n;
    } catch (t) {
      e = t;
    }
    throw e;
  }
}, Is = 0, zs.prototype.setState = function (e, t) {
  var i;
  i = null != this.__s && this.__s !== this.state ? this.__s : this.__s = Bs({}, this.state), "function" == typeof e && (e = e(Bs({}, i), this.props)), e && Bs(i, e), null != e && this.__v && (t && this._sb.push(t), Gs(this));
}, zs.prototype.forceUpdate = function (e) {
  this.__v && (this.__e = !0, e && this.__h.push(e), Gs(this));
}, zs.prototype.render = Us, Cs = [], Fs = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, Ts = function (e, t) {
  return e.__v.__b - t.__v.__b;
}, Js.__r = 0, Rs = 0, function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}($s || ($s = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(Os || (Os = {})), function (e) {
  e.NextQuestion = "next_question", e.End = "end", e.ResponseBased = "response_based", e.SpecificQuestion = "specific_question";
}(Ms || (Ms = {})), function (e) {
  e.Once = "once", e.Recurring = "recurring", e.Always = "always";
}(As || (As = {}));
!function (e, t) {
  var i = {
    __c: t = "__cC" + Rs++,
    __: e,
    Consumer: function (e, t) {
      return e.children(t);
    },
    Provider: function (e) {
      var i, r;
      return this.getChildContext || (i = [], (r = {})[t] = this, this.getChildContext = function () {
        return r;
      }, this.shouldComponentUpdate = function (e) {
        this.props.value !== e.value && i.some(function (e) {
          e.__e = !0, Gs(e);
        });
      }, this.sub = function (e) {
        i.push(e);
        var t = e.componentWillUnmount;
        e.componentWillUnmount = function () {
          i.splice(i.indexOf(e), 1), t && t.call(e);
        };
      }), e.children;
    }
  };
  i.Provider.__ = i.Consumer.contextType = i;
}({
  isPreviewMode: !1,
  previewPageIndex: 0,
  onPopupSurveyDismissed: () => {},
  isPopup: !0,
  onPreviewSubmit: () => {}
});
var uo = function (e, t) {
  if (!function (e) {
    try {
      new RegExp(e);
    } catch (e) {
      return !1;
    }
    return !0;
  }(t)) return !1;
  try {
    return new RegExp(t).test(e);
  } catch (e) {
    return !1;
  }
};
class co {
  constructor() {
    W(this, "events", {}), this.events = {};
  }
  on(e, t) {
    return this.events[e] || (this.events[e] = []), this.events[e].push(t), () => {
      this.events[e] = this.events[e].filter(e => e !== t);
    };
  }
  emit(e, t) {
    for (var i of this.events[e] || []) i(t);
    for (var r of this.events["*"] || []) r(e, t);
  }
}
class ho {
  constructor(e) {
    W(this, "_debugEventEmitter", new co()), W(this, "checkStep", (e, t) => this.checkStepEvent(e, t) && this.checkStepUrl(e, t) && this.checkStepElement(e, t)), W(this, "checkStepEvent", (e, t) => null == t || !t.event || (null == e ? void 0 : e.event) === (null == t ? void 0 : t.event)), this.instance = e, this.actionEvents = new Set(), this.actionRegistry = new Set();
  }
  init() {
    var e;
    if (!F(null === (e = this.instance) || void 0 === e ? void 0 : e._addCaptureHook)) {
      var t;
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.on(e, t);
      });
    }
  }
  register(e) {
    var t, i;
    if (!F(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) && (e.forEach(e => {
      var t, i;
      null === (t = this.actionRegistry) || void 0 === t || t.add(e), null === (i = e.steps) || void 0 === i || i.forEach(e => {
        var t;
        null === (t = this.actionEvents) || void 0 === t || t.add((null == e ? void 0 : e.event) || "");
      });
    }), null !== (i = this.instance) && void 0 !== i && i.autocapture)) {
      var r,
        n = new Set();
      e.forEach(e => {
        var t;
        null === (t = e.steps) || void 0 === t || t.forEach(e => {
          null != e && e.selector && n.add(null == e ? void 0 : e.selector);
        });
      }), null === (r = this.instance) || void 0 === r || r.autocapture.setElementSelectors(n);
    }
  }
  on(e, t) {
    var i;
    null != t && 0 != e.length && (this.actionEvents.has(e) || this.actionEvents.has(null == t ? void 0 : t.event)) && this.actionRegistry && (null === (i = this.actionRegistry) || void 0 === i ? void 0 : i.size) > 0 && this.actionRegistry.forEach(e => {
      this.checkAction(t, e) && this._debugEventEmitter.emit("actionCaptured", e.name);
    });
  }
  _addActionHook(e) {
    this.onAction("actionCaptured", t => e(t));
  }
  checkAction(e, t) {
    if (null == (null == t ? void 0 : t.steps)) return !1;
    for (var i of t.steps) if (this.checkStep(e, i)) return !0;
    return !1;
  }
  onAction(e, t) {
    return this._debugEventEmitter.on(e, t);
  }
  checkStepUrl(e, t) {
    if (null != t && t.url) {
      var i,
        r = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$current_url;
      if (!r || "string" != typeof r) return !1;
      if (!ho.matchString(r, null == t ? void 0 : t.url, (null == t ? void 0 : t.url_matching) || "contains")) return !1;
    }
    return !0;
  }
  static matchString(e, i, r) {
    switch (r) {
      case "regex":
        return !!t && uo(e, i);
      case "exact":
        return i === e;
      case "contains":
        var n = ho.escapeStringRegexp(i).replace(/_/g, ".").replace(/%/g, ".*");
        return uo(e, n);
      default:
        return !1;
    }
  }
  static escapeStringRegexp(e) {
    return e.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  }
  checkStepElement(e, t) {
    if ((null != t && t.href || null != t && t.tag_name || null != t && t.text) && !this.getElementsList(e).some(e => !(null != t && t.href && !ho.matchString(e.href || "", null == t ? void 0 : t.href, (null == t ? void 0 : t.href_matching) || "exact")) && (null == t || !t.tag_name || e.tag_name === (null == t ? void 0 : t.tag_name)) && !(null != t && t.text && !ho.matchString(e.text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact") && !ho.matchString(e.$el_text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact")))) return !1;
    if (null != t && t.selector) {
      var i,
        r = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$element_selectors;
      if (!r) return !1;
      if (!r.includes(null == t ? void 0 : t.selector)) return !1;
    }
    return !0;
  }
  getElementsList(e) {
    return null == (null == e ? void 0 : e.properties.$elements) ? [] : null == e ? void 0 : e.properties.$elements;
  }
}
class _o {
  constructor(e) {
    this.instance = e, this.eventToSurveys = new Map(), this.actionToSurveys = new Map();
  }
  register(e) {
    var t;
    F(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) || (this.setupEventBasedSurveys(e), this.setupActionBasedSurveys(e));
  }
  setupActionBasedSurveys(e) {
    var t = e.filter(e => {
      var t, i, r, n;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.actions) && (null === (i = e.conditions) || void 0 === i || null === (r = i.actions) || void 0 === r || null === (n = r.values) || void 0 === n ? void 0 : n.length) > 0;
    });
    if (0 !== t.length) {
      if (null == this.actionMatcher) {
        this.actionMatcher = new ho(this.instance), this.actionMatcher.init();
        this.actionMatcher._addActionHook(e => {
          this.onAction(e);
        });
      }
      t.forEach(e => {
        var t, i, r, n, s, o, a, l, u, c;
        e.conditions && null !== (t = e.conditions) && void 0 !== t && t.actions && null !== (i = e.conditions) && void 0 !== i && null !== (r = i.actions) && void 0 !== r && r.values && (null === (n = e.conditions) || void 0 === n || null === (s = n.actions) || void 0 === s || null === (o = s.values) || void 0 === o ? void 0 : o.length) > 0 && (null === (a = this.actionMatcher) || void 0 === a || a.register(e.conditions.actions.values), null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u || null === (c = u.values) || void 0 === c || c.forEach(t => {
          if (t && t.name) {
            var i = this.actionToSurveys.get(t.name);
            i && i.push(e.id), this.actionToSurveys.set(t.name, i || [e.id]);
          }
        }));
      });
    }
  }
  setupEventBasedSurveys(e) {
    var t;
    if (0 !== e.filter(e => {
      var t, i, r, n;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (r = i.events) || void 0 === r || null === (n = r.values) || void 0 === n ? void 0 : n.length) > 0;
    }).length) {
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.onEvent(e, t);
      }), e.forEach(e => {
        var t, i, r;
        null === (t = e.conditions) || void 0 === t || null === (i = t.events) || void 0 === i || null === (r = i.values) || void 0 === r || r.forEach(t => {
          if (t && t.name) {
            var i = this.eventToSurveys.get(t.name);
            i && i.push(e.id), this.eventToSurveys.set(t.name, i || [e.id]);
          }
        });
      });
    }
  }
  onEvent(e, t) {
    var i,
      r,
      n = (null === (i = this.instance) || void 0 === i || null === (r = i.persistence) || void 0 === r ? void 0 : r.props[Me]) || [];
    if (_o.SURVEY_SHOWN_EVENT_NAME == e && t && n.length > 0) {
      var s,
        o = null == t || null === (s = t.properties) || void 0 === s ? void 0 : s.$survey_id;
      if (o) {
        var a = n.indexOf(o);
        a >= 0 && (n.splice(a, 1), this._updateActivatedSurveys(n));
      }
    } else this.eventToSurveys.has(e) && this._updateActivatedSurveys(n.concat(this.eventToSurveys.get(e) || []));
  }
  onAction(e) {
    var t,
      i,
      r = (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i ? void 0 : i.props[Me]) || [];
    this.actionToSurveys.has(e) && this._updateActivatedSurveys(r.concat(this.actionToSurveys.get(e) || []));
  }
  _updateActivatedSurveys(e) {
    var t, i;
    null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      [Me]: [...new Set(e)]
    });
  }
  getSurveys() {
    var e,
      t,
      i = null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t ? void 0 : t.props[Me];
    return i || [];
  }
  getEventToSurveys() {
    return this.eventToSurveys;
  }
  _getActionMatcher() {
    return this.actionMatcher;
  }
}
W(_o, "SURVEY_SHOWN_EVENT_NAME", "survey shown");
var po = B("[Surveys]"),
  vo = {
    icontains: (e, t) => e.some(e => t.toLowerCase().includes(e.toLowerCase())),
    not_icontains: (e, t) => e.every(e => !t.toLowerCase().includes(e.toLowerCase())),
    regex: (e, t) => e.some(e => uo(t, e)),
    not_regex: (e, t) => e.every(e => !uo(t, e)),
    exact: (e, t) => e.some(e => t === e),
    is_not: (e, t) => e.every(e => t !== e)
  };
function go(e) {
  return null != e ? e : "icontains";
}
class fo {
  constructor(e) {
    W(this, "_isFetchingSurveys", !1), W(this, "_isInitializingSurveys", !1), W(this, "_surveyCallbacks", []), this.instance = e, this._surveyEventReceiver = null;
  }
  onRemoteConfig(e) {
    var t = e.surveys;
    if (O(t)) return po.warn("Decide not loaded yet. Not loading surveys.");
    var i = x(t);
    this._hasSurveys = i ? t.length > 0 : t, po.info("decide response received, hasSurveys: ".concat(this._hasSurveys)), this._hasSurveys && this.loadIfEnabled();
  }
  reset() {
    localStorage.removeItem("lastSeenSurveyDate");
    var e = (() => {
      for (var e = [], t = 0; t < localStorage.length; t++) {
        var i = localStorage.key(t);
        null != i && i.startsWith("seenSurvey_") && e.push(i);
      }
      return e;
    })();
    e.forEach(e => localStorage.removeItem(e));
  }
  loadIfEnabled() {
    if (!this._surveyManager) if (this._isInitializingSurveys) po.info("Already initializing surveys, skipping...");else if (this.instance.config.disable_surveys) po.info("Disabled. Not loading surveys.");else {
      var e = null == _ ? void 0 : _.__PosthogExtensions__;
      if (e) {
        if (this._hasSurveys) {
          this._isInitializingSurveys = !0;
          try {
            var t = e.generateSurveys;
            if (t) this._surveyManager = t(this.instance), this._isInitializingSurveys = !1, this._surveyEventReceiver = new _o(this.instance), po.info("Surveys loaded successfully"), this._notifySurveyCallbacks({
              isLoaded: !0
            });else {
              var i = e.loadExternalDependency;
              if (i) i(this.instance, "surveys", t => {
                if (t || !e.generateSurveys) return po.error("Could not load surveys script", t), void (this._isInitializingSurveys = !1);
                this._surveyManager = e.generateSurveys(this.instance), this._isInitializingSurveys = !1, this._surveyEventReceiver = new _o(this.instance), po.info("Surveys loaded successfully"), this._notifySurveyCallbacks({
                  isLoaded: !0
                });
              });else {
                var r = "PostHog loadExternalDependency extension not found. Cannot load remote config.";
                po.error(r), this._isInitializingSurveys = !1, this._notifySurveyCallbacks({
                  isLoaded: !1,
                  error: r
                });
              }
            }
          } catch (e) {
            throw po.error("Error initializing surveys", e), this._isInitializingSurveys = !1, this._notifySurveyCallbacks({
              isLoaded: !1,
              error: "Error initializing surveys"
            }), e;
          }
        } else po.info("No surveys to load.");
      } else po.error("PostHog Extensions not found.");
    }
  }
  onSurveysLoaded(e) {
    return this._surveyCallbacks.push(e), this._surveyManager && this._notifySurveyCallbacks({
      isLoaded: !0
    }), () => {
      this._surveyCallbacks = this._surveyCallbacks.filter(t => t !== e);
    };
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (this.instance.config.disable_surveys) return po.info("Disabled. Not loading surveys."), e([]);
    var i = this.instance.get_property(Oe);
    if (i && !t) return e(i, {
      isLoaded: !0
    });
    if (this._isFetchingSurveys) return e([], {
      isLoaded: !1,
      error: "Surveys are already being loaded"
    });
    try {
      this._isFetchingSurveys = !0, this.instance._send_request({
        url: this.instance.requestRouter.endpointFor("api", "/api/surveys/?token=".concat(this.instance.config.token)),
        method: "GET",
        timeout: this.instance.config.surveys_request_timeout_ms,
        callback: t => {
          var i;
          this._isFetchingSurveys = !1;
          var r = t.statusCode;
          if (200 !== r || !t.json) {
            var n = "Surveys API could not be loaded, status: ".concat(r);
            return po.error(n), e([], {
              isLoaded: !1,
              error: n
            });
          }
          var s,
            o = t.json.surveys || [],
            a = o.filter(e => {
              var t, i, r, n, s, o, a, l, u, c, d, h;
              return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (r = i.events) || void 0 === r ? void 0 : r.values) && (null === (n = e.conditions) || void 0 === n || null === (s = n.events) || void 0 === s || null === (o = s.values) || void 0 === o ? void 0 : o.length) > 0 || (null === (a = e.conditions) || void 0 === a ? void 0 : a.actions) && (null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u ? void 0 : u.values) && (null === (c = e.conditions) || void 0 === c || null === (d = c.actions) || void 0 === d || null === (h = d.values) || void 0 === h ? void 0 : h.length) > 0;
            });
          a.length > 0 && (null === (s = this._surveyEventReceiver) || void 0 === s || s.register(a));
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [Oe]: o
          }), e(o, {
            isLoaded: !0
          });
        }
      });
    } catch (e) {
      throw this._isFetchingSurveys = !1, e;
    }
  }
  _notifySurveyCallbacks(e) {
    for (var t of this._surveyCallbacks) try {
      e.isLoaded ? this.getSurveys(t) : t([], e);
    } catch (e) {
      po.error("Error in survey callback", e);
    }
  }
  isSurveyFeatureFlagEnabled(e) {
    return !e || this.instance.featureFlags.isFeatureEnabled(e);
  }
  getActiveMatchingSurveys(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.getSurveys(i => {
      var r,
        n = i.filter(e => !(!e.start_date || e.end_date)).filter(e => {
          var i;
          if (!e.conditions) return !0;
          var r = function (e) {
              var i, r, n;
              if (null === (i = e.conditions) || void 0 === i || !i.url) return !0;
              var s = null == t || null === (r = t.location) || void 0 === r ? void 0 : r.href;
              if (!s) return !1;
              var o = [e.conditions.url];
              return vo[go(null === (n = e.conditions) || void 0 === n ? void 0 : n.urlMatchType)](o, s);
            }(e),
            n = null === (i = e.conditions) || void 0 === i || !i.selector || (null == a ? void 0 : a.querySelector(e.conditions.selector)),
            s = function (e) {
              var t, i, r;
              if (null === (t = e.conditions) || void 0 === t || !t.deviceTypes || 0 === (null === (i = e.conditions) || void 0 === i ? void 0 : i.deviceTypes.length)) return !0;
              if (!h) return !1;
              var n = hs.deviceType(h);
              return vo[go(null === (r = e.conditions) || void 0 === r ? void 0 : r.deviceTypesMatchType)](e.conditions.deviceTypes, n);
            }(e);
          return r && n && s;
        }),
        s = null === (r = this._surveyEventReceiver) || void 0 === r ? void 0 : r.getSurveys(),
        o = n.filter(e => {
          var t, i, r, n, o, a, l, u, c;
          if (!(e.linked_flag_key || e.targeting_flag_key || e.internal_targeting_flag_key || null !== (t = e.feature_flag_keys) && void 0 !== t && t.length)) return !0;
          var d = this.isSurveyFeatureFlagEnabled(e.linked_flag_key),
            h = this.isSurveyFeatureFlagEnabled(e.targeting_flag_key),
            _ = (null !== (i = null === (r = e.conditions) || void 0 === r || null === (n = r.events) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) && void 0 !== i ? i : 0) > 0,
            p = (null !== (a = null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u || null === (c = u.values) || void 0 === c ? void 0 : c.length) && void 0 !== a ? a : 0) > 0,
            v = !_ && !p || (null == s ? void 0 : s.includes(e.id)),
            g = this._canActivateRepeatedly(e) || this.isSurveyFeatureFlagEnabled(e.internal_targeting_flag_key),
            f = this.checkFlags(e);
          return d && h && g && v && f;
        });
      return e(o);
    }, i);
  }
  checkFlags(e) {
    var t;
    return null === (t = e.feature_flag_keys) || void 0 === t || !t.length || e.feature_flag_keys.every(e => {
      var {
        key: t,
        value: i
      } = e;
      return !t || !i || this.instance.featureFlags.isFeatureEnabled(i);
    });
  }
  _canActivateRepeatedly(e) {
    var t;
    return O(null === (t = _.__PosthogExtensions__) || void 0 === t ? void 0 : t.canActivateRepeatedly) ? (po.warn("init was not called"), !1) : _.__PosthogExtensions__.canActivateRepeatedly(e);
  }
  canRenderSurvey(e) {
    if (O(this._surveyManager)) return po.warn("init was not called"), {
      visible: !1,
      disabledReason: "SDK is not enabled or survey functionality is not yet loaded"
    };
    var t = null;
    return this.getSurveys(i => {
      var r = i.filter(t => t.id === e)[0];
      t = r ? z({}, this._surveyManager.canRenderSurvey(r)) : {
        visible: !1,
        disabledReason: "Survey not found"
      };
    }), t;
  }
  renderSurvey(e, t) {
    O(this._surveyManager) ? po.warn("init was not called") : this.getSurveys(i => {
      var r = i.filter(t => t.id === e)[0];
      this._surveyManager.renderSurvey(r, null == a ? void 0 : a.querySelector(t));
    });
  }
}
var mo = B("[RateLimiter]");
class yo {
  constructor(e) {
    var t, i;
    W(this, "serverLimits", {}), W(this, "lastEventRateLimited", !1), W(this, "checkForLimiting", e => {
      var t = e.text;
      if (t && t.length) try {
        (JSON.parse(t).quota_limited || []).forEach(e => {
          mo.info("".concat(e || "events", " is quota limited.")), this.serverLimits[e] = new Date().getTime() + 6e4;
        });
      } catch (e) {
        return void mo.warn('could not rate limit - continuing. Error: "'.concat(null == e ? void 0 : e.message, '"'), {
          text: t
        });
      }
    }), this.instance = e, this.captureEventsPerSecond = (null === (t = e.config.rate_limiting) || void 0 === t ? void 0 : t.events_per_second) || 10, this.captureEventsBurstLimit = Math.max((null === (i = e.config.rate_limiting) || void 0 === i ? void 0 : i.events_burst_limit) || 10 * this.captureEventsPerSecond, this.captureEventsPerSecond), this.lastEventRateLimited = this.clientRateLimitContext(!0).isRateLimited;
  }
  clientRateLimitContext() {
    var e,
      t,
      i,
      r = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      n = new Date().getTime(),
      s = null !== (e = null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.get_property(qe)) && void 0 !== e ? e : {
        tokens: this.captureEventsBurstLimit,
        last: n
      };
    s.tokens += (n - s.last) / 1e3 * this.captureEventsPerSecond, s.last = n, s.tokens > this.captureEventsBurstLimit && (s.tokens = this.captureEventsBurstLimit);
    var o = s.tokens < 1;
    return o || r || (s.tokens = Math.max(0, s.tokens - 1)), !o || this.lastEventRateLimited || r || this.instance.capture("$$client_ingestion_warning", {
      $$client_ingestion_warning_message: "posthog-js client rate limited. Config is set to ".concat(this.captureEventsPerSecond, " events per second and ").concat(this.captureEventsBurstLimit, " events burst limit.")
    }, {
      skip_client_rate_limiting: !0
    }), this.lastEventRateLimited = o, null === (i = this.instance.persistence) || void 0 === i || i.set_property(qe, s), {
      isRateLimited: o,
      remainingTokens: s.tokens
    };
  }
  isServerRateLimited(e) {
    var t = this.serverLimits[e || "events"] || !1;
    return !1 !== t && new Date().getTime() < t;
  }
}
var bo = B("[RemoteConfig]");
class wo {
  constructor(e) {
    this.instance = e;
  }
  get remoteConfig() {
    var e, t;
    return null === (e = _._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.config;
  }
  _loadRemoteConfigJs(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.loadExternalDependency ? null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "remote-config", () => e(this.remoteConfig)) : (bo.error("PostHog Extensions not found. Cannot load remote config."), e());
  }
  _loadRemoteConfigJSON(e) {
    this.instance._send_request({
      method: "GET",
      url: this.instance.requestRouter.endpointFor("assets", "/array/".concat(this.instance.config.token, "/config")),
      callback: t => {
        e(t.json);
      }
    });
  }
  load() {
    try {
      if (this.remoteConfig) return bo.info("Using preloaded remote config", this.remoteConfig), void this.onRemoteConfig(this.remoteConfig);
      if (this.instance.config.advanced_disable_decide) return void bo.warn("Remote config is disabled. Falling back to local config.");
      this._loadRemoteConfigJs(e => {
        if (!e) return bo.info("No config found after loading remote JS config. Falling back to JSON."), void this._loadRemoteConfigJSON(e => {
          this.onRemoteConfig(e);
        });
        this.onRemoteConfig(e);
      });
    } catch (e) {
      bo.error("Error loading remote config", e);
    }
  }
  onRemoteConfig(e) {
    e ? this.instance.config.__preview_remote_config ? (this.instance._onRemoteConfig(e), !1 !== e.hasFeatureFlags && this.instance.featureFlags.ensureFlagsLoaded()) : bo.info("__preview_remote_config is disabled. Logging config instead", e) : bo.error("Failed to fetch remote config from PostHog.");
  }
}
var So = function (e) {
    var t,
      i,
      r,
      n,
      s = "";
    for (t = i = 0, r = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, n = 0; n < r; n++) {
      var o = e.charCodeAt(n),
        a = null;
      o < 128 ? i++ : a = o > 127 && o < 2048 ? String.fromCharCode(o >> 6 | 192, 63 & o | 128) : String.fromCharCode(o >> 12 | 224, o >> 6 & 63 | 128, 63 & o | 128), $(a) || (i > t && (s += e.substring(t, i)), s += a, t = i = n + 1);
    }
    return i > t && (s += e.substring(t, e.length)), s;
  },
  ko = !!c || !!u,
  Eo = "text/plain",
  xo = (e, t) => {
    var [i, r] = e.split("?"),
      n = z({}, t);
    null == r || r.split("&").forEach(e => {
      var [t] = e.split("=");
      delete n[t];
    });
    var s = kt(n);
    return s = s ? (r ? r + "&" : "") + s : r, "".concat(i, "?").concat(s);
  },
  Io = (e, t) => JSON.stringify(e, (e, t) => "bigint" == typeof t ? t.toString() : t, t),
  Co = t => {
    var {
      data: i,
      compression: r
    } = t;
    if (i) {
      if (r === e.GZipJS) {
        var n = $r(Or(Io(i)), {
            mtime: 0
          }),
          s = new Blob([n], {
            type: Eo
          });
        return {
          contentType: Eo,
          body: s,
          estimatedSize: s.size
        };
      }
      if (r === e.Base64) {
        var o = function (e) {
            var t,
              i,
              r,
              n,
              s,
              o = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
              a = 0,
              l = 0,
              u = "",
              c = [];
            if (!e) return e;
            e = So(e);
            do {
              t = (s = e.charCodeAt(a++) << 16 | e.charCodeAt(a++) << 8 | e.charCodeAt(a++)) >> 18 & 63, i = s >> 12 & 63, r = s >> 6 & 63, n = 63 & s, c[l++] = o.charAt(t) + o.charAt(i) + o.charAt(r) + o.charAt(n);
            } while (a < e.length);
            switch (u = c.join(""), e.length % 3) {
              case 1:
                u = u.slice(0, -2) + "==";
                break;
              case 2:
                u = u.slice(0, -1) + "=";
            }
            return u;
          }(Io(i)),
          a = (e => "data=" + encodeURIComponent("string" == typeof e ? e : Io(e)))(o);
        return {
          contentType: "application/x-www-form-urlencoded",
          body: a,
          estimatedSize: new Blob([a]).size
        };
      }
      var l = Io(i);
      return {
        contentType: "application/json",
        body: l,
        estimatedSize: new Blob([l]).size
      };
    }
  },
  Po = [];
u && Po.push({
  transport: "fetch",
  method: e => {
    var t,
      i,
      {
        contentType: r,
        body: n,
        estimatedSize: s
      } = null !== (t = Co(e)) && void 0 !== t ? t : {},
      o = new Headers();
    Y(e.headers, function (e, t) {
      o.append(t, e);
    }), r && o.append("Content-Type", r);
    var a = e.url,
      l = null;
    if (d) {
      var c = new d();
      l = {
        signal: c.signal,
        timeout: setTimeout(() => c.abort(), e.timeout)
      };
    }
    u(a, z({
      method: (null == e ? void 0 : e.method) || "GET",
      headers: o,
      keepalive: "POST" === e.method && (s || 0) < 52428.8,
      body: n,
      signal: null === (i = l) || void 0 === i ? void 0 : i.signal
    }, e.fetchOptions)).then(t => t.text().then(i => {
      var r,
        n = {
          statusCode: t.status,
          text: i
        };
      if (200 === t.status) try {
        n.json = JSON.parse(i);
      } catch (e) {
        N.error(e);
      }
      null === (r = e.callback) || void 0 === r || r.call(e, n);
    })).catch(t => {
      var i;
      N.error(t), null === (i = e.callback) || void 0 === i || i.call(e, {
        statusCode: 0,
        text: t
      });
    }).finally(() => l ? clearTimeout(l.timeout) : null);
  }
}), c && Po.push({
  transport: "XHR",
  method: e => {
    var t,
      i = new c();
    i.open(e.method || "GET", e.url, !0);
    var {
      contentType: r,
      body: n
    } = null !== (t = Co(e)) && void 0 !== t ? t : {};
    Y(e.headers, function (e, t) {
      i.setRequestHeader(t, e);
    }), r && i.setRequestHeader("Content-Type", r), e.timeout && (i.timeout = e.timeout), i.withCredentials = !0, i.onreadystatechange = () => {
      if (4 === i.readyState) {
        var t,
          r = {
            statusCode: i.status,
            text: i.responseText
          };
        if (200 === i.status) try {
          r.json = JSON.parse(i.responseText);
        } catch (e) {}
        null === (t = e.callback) || void 0 === t || t.call(e, r);
      }
    }, i.send(n);
  }
}), null != o && o.sendBeacon && Po.push({
  transport: "sendBeacon",
  method: e => {
    var t = xo(e.url, {
      beacon: "1"
    });
    try {
      var i,
        {
          contentType: r,
          body: n
        } = null !== (i = Co(e)) && void 0 !== i ? i : {},
        s = "string" == typeof n ? new Blob([n], {
          type: r
        }) : n;
      o.sendBeacon(t, s);
    } catch (e) {}
  }
});
var Fo = 3e3;
class To {
  constructor(e, t) {
    W(this, "isPaused", !0), W(this, "queue", []), this.flushTimeoutMs = Ji((null == t ? void 0 : t.flush_interval_ms) || Fo, 250, 5e3, "flush interval", Fo), this.sendRequest = e;
  }
  enqueue(e) {
    this.queue.push(e), this.flushTimeout || this.setFlushTimeout();
  }
  unload() {
    this.clearFlushTimeout();
    var e = this.queue.length > 0 ? this.formatQueue() : {},
      t = Object.values(e),
      i = [...t.filter(e => 0 === e.url.indexOf("/e")), ...t.filter(e => 0 !== e.url.indexOf("/e"))];
    i.map(e => {
      this.sendRequest(z(z({}, e), {}, {
        transport: "sendBeacon"
      }));
    });
  }
  enable() {
    this.isPaused = !1, this.setFlushTimeout();
  }
  setFlushTimeout() {
    var e = this;
    this.isPaused || (this.flushTimeout = setTimeout(() => {
      if (this.clearFlushTimeout(), this.queue.length > 0) {
        var t = this.formatQueue(),
          i = function (i) {
            var r = t[i],
              n = new Date().getTime();
            r.data && x(r.data) && Y(r.data, e => {
              e.offset = Math.abs(e.timestamp - n), delete e.timestamp;
            }), e.sendRequest(r);
          };
        for (var r in t) i(r);
      }
    }, this.flushTimeoutMs));
  }
  clearFlushTimeout() {
    clearTimeout(this.flushTimeout), this.flushTimeout = void 0;
  }
  formatQueue() {
    var e = {};
    return Y(this.queue, t => {
      var i,
        r = t,
        n = (r ? r.batchKey : null) || r.url;
      F(e[n]) && (e[n] = z(z({}, r), {}, {
        data: []
      })), null === (i = e[n].data) || void 0 === i || i.push(r.data);
    }), this.queue = [], e;
  }
}
var Ro = ["retriesPerformedSoFar"];
class $o {
  constructor(e) {
    W(this, "isPolling", !1), W(this, "pollIntervalMs", 3e3), W(this, "queue", []), this.instance = e, this.queue = [], this.areWeOnline = !0, !F(t) && "onLine" in t.navigator && (this.areWeOnline = t.navigator.onLine, oe(t, "online", () => {
      this.areWeOnline = !0, this.flush();
    }), oe(t, "offline", () => {
      this.areWeOnline = !1;
    }));
  }
  retriableRequest(e) {
    var {
        retriesPerformedSoFar: t
      } = e,
      i = V(e, Ro);
    M(t) && t > 0 && (i.url = xo(i.url, {
      retry_count: t
    })), this.instance._send_request(z(z({}, i), {}, {
      callback: e => {
        var r;
        200 !== e.statusCode && (e.statusCode < 400 || e.statusCode >= 500) && (null != t ? t : 0) < 10 ? this.enqueue(z({
          retriesPerformedSoFar: t
        }, i)) : null === (r = i.callback) || void 0 === r || r.call(i, e);
      }
    }));
  }
  enqueue(e) {
    var t = e.retriesPerformedSoFar || 0;
    e.retriesPerformedSoFar = t + 1;
    var i = function (e) {
        var t = 3e3 * Math.pow(2, e),
          i = t / 2,
          r = Math.min(18e5, t),
          n = (Math.random() - .5) * (r - i);
        return Math.ceil(r + n);
      }(t),
      r = Date.now() + i;
    this.queue.push({
      retryAt: r,
      requestOptions: e
    });
    var n = "Enqueued failed request for retry in ".concat(i);
    navigator.onLine || (n += " (Browser is offline)"), N.warn(n), this.isPolling || (this.isPolling = !0, this.poll());
  }
  poll() {
    this.poller && clearTimeout(this.poller), this.poller = setTimeout(() => {
      this.areWeOnline && this.queue.length > 0 && this.flush(), this.poll();
    }, this.pollIntervalMs);
  }
  flush() {
    var e = Date.now(),
      t = [],
      i = this.queue.filter(i => i.retryAt < e || (t.push(i), !1));
    if (this.queue = t, i.length > 0) for (var {
      requestOptions: r
    } of i) this.retriableRequest(r);
  }
  unload() {
    for (var {
      requestOptions: e
    } of (this.poller && (clearTimeout(this.poller), this.poller = void 0), this.queue)) try {
      this.instance._send_request(z(z({}, e), {}, {
        transport: "sendBeacon"
      }));
    } catch (e) {
      N.error(e);
    }
    this.queue = [];
  }
}
class Oo {
  constructor(e) {
    W(this, "_updateScrollData", () => {
      var e, t, i, r;
      this.context || (this.context = {});
      var n = this.scrollElement(),
        s = this.scrollY(),
        o = n ? Math.max(0, n.scrollHeight - n.clientHeight) : 0,
        a = s + ((null == n ? void 0 : n.clientHeight) || 0),
        l = (null == n ? void 0 : n.scrollHeight) || 0;
      this.context.lastScrollY = Math.ceil(s), this.context.maxScrollY = Math.max(s, null !== (e = this.context.maxScrollY) && void 0 !== e ? e : 0), this.context.maxScrollHeight = Math.max(o, null !== (t = this.context.maxScrollHeight) && void 0 !== t ? t : 0), this.context.lastContentY = a, this.context.maxContentY = Math.max(a, null !== (i = this.context.maxContentY) && void 0 !== i ? i : 0), this.context.maxContentHeight = Math.max(l, null !== (r = this.context.maxContentHeight) && void 0 !== r ? r : 0);
    }), this.instance = e;
  }
  getContext() {
    return this.context;
  }
  resetContext() {
    var e = this.context;
    return setTimeout(this._updateScrollData, 0), e;
  }
  startMeasuringScrollPosition() {
    oe(t, "scroll", this._updateScrollData, {
      capture: !0
    }), oe(t, "scrollend", this._updateScrollData, {
      capture: !0
    }), oe(t, "resize", this._updateScrollData);
  }
  scrollElement() {
    if (!this.instance.config.scroll_root_selector) return null == t ? void 0 : t.document.documentElement;
    var e = x(this.instance.config.scroll_root_selector) ? this.instance.config.scroll_root_selector : [this.instance.config.scroll_root_selector];
    for (var i of e) {
      var r = null == t ? void 0 : t.document.querySelector(i);
      if (r) return r;
    }
  }
  scrollY() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollTop || 0;
    }
    return t && (t.scrollY || t.pageYOffset || t.document.documentElement.scrollTop) || 0;
  }
  scrollX() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollLeft || 0;
    }
    return t && (t.scrollX || t.pageXOffset || t.document.documentElement.scrollLeft) || 0;
  }
}
var Mo = e => hs.personInfo({
  maskPersonalDataProperties: null == e ? void 0 : e.config.mask_personal_data_properties,
  customPersonalDataProperties: null == e ? void 0 : e.config.custom_personal_data_properties
});
class Ao {
  constructor(e, t, i, r) {
    W(this, "_onSessionIdCallback", e => {
      var t = this._getStored();
      if (!t || t.sessionId !== e) {
        var i = {
          sessionId: e,
          props: this._sessionSourceParamGenerator(this.instance)
        };
        this._persistence.register({
          [De]: i
        });
      }
    }), this.instance = e, this._sessionIdManager = t, this._persistence = i, this._sessionSourceParamGenerator = r || Mo, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
  }
  _getStored() {
    return this._persistence.props[De];
  }
  getSetOnceProps() {
    var e,
      t = null === (e = this._getStored()) || void 0 === e ? void 0 : e.props;
    return t ? "r" in t ? hs.personPropsFromInfo(t) : {
      $referring_domain: t.referringDomain,
      $pathname: t.initialPathName,
      utm_source: t.utm_source,
      utm_campaign: t.utm_campaign,
      utm_medium: t.utm_medium,
      utm_content: t.utm_content,
      utm_term: t.utm_term
    } : {};
  }
  getSessionProps() {
    var e = {};
    return Y(te(this.getSetOnceProps()), (t, i) => {
      "$current_url" === i && (i = "url"), e["$session_entry_".concat(b(i))] = t;
    }), e;
  }
}
var Lo = B("[SessionId]");
class Do {
  constructor(e, t, i) {
    var r;
    if (W(this, "_sessionIdChangedHandlers", []), !e.persistence) throw new Error("SessionIdManager requires a PostHogPersistence instance");
    if (e.config.__preview_experimental_cookieless_mode) throw new Error("SessionIdManager cannot be used with __preview_experimental_cookieless_mode");
    this.config = e.config, this.persistence = e.persistence, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = t || Nt, this._windowIdGenerator = i || Nt;
    var n = this.config.persistence_name || this.config.token,
      s = this.config.session_idle_timeout_seconds || 1800;
    if (this._sessionTimeoutMs = 1e3 * Ji(s, 60, 36e3, "session_idle_timeout_seconds", 1800), e.register({
      $configured_session_timeout_ms: this._sessionTimeoutMs
    }), this.resetIdleTimer(), this._window_id_storage_key = "ph_" + n + "_window_id", this._primary_window_exists_storage_key = "ph_" + n + "_primary_window_exists", this._canUseSessionStorage()) {
      var o = ei.parse(this._window_id_storage_key),
        a = ei.parse(this._primary_window_exists_storage_key);
      o && !a ? this._windowId = o : ei.remove(this._window_id_storage_key), ei.set(this._primary_window_exists_storage_key, !0);
    }
    if (null !== (r = this.config.bootstrap) && void 0 !== r && r.sessionID) try {
      var l = (e => {
        var t = e.replace(/-/g, "");
        if (32 !== t.length) throw new Error("Not a valid UUID");
        if ("7" !== t[12]) throw new Error("Not a UUIDv7");
        return parseInt(t.substring(0, 12), 16);
      })(this.config.bootstrap.sessionID);
      this._setSessionId(this.config.bootstrap.sessionID, new Date().getTime(), l);
    } catch (e) {
      Lo.error("Invalid sessionID in bootstrap", e);
    }
    this._listenToReloadWindow();
  }
  get sessionTimeoutMs() {
    return this._sessionTimeoutMs;
  }
  onSessionId(e) {
    return F(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), () => {
      this._sessionIdChangedHandlers = this._sessionIdChangedHandlers.filter(t => t !== e);
    };
  }
  _canUseSessionStorage() {
    return "memory" !== this.config.persistence && !this.persistence.disabled && ei.is_supported();
  }
  _setWindowId(e) {
    e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && ei.set(this._window_id_storage_key, e));
  }
  _getWindowId() {
    return this._windowId ? this._windowId : this._canUseSessionStorage() ? ei.parse(this._window_id_storage_key) : null;
  }
  _setSessionId(e, t, i) {
    e === this._sessionId && t === this._sessionActivityTimestamp && i === this._sessionStartTimestamp || (this._sessionStartTimestamp = i, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register({
      [Ee]: [t, e, i]
    }));
  }
  _getSessionId() {
    if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
    var e = this.persistence.props[Ee];
    return x(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
  }
  resetSessionId() {
    this._setSessionId(null, null, null);
  }
  _listenToReloadWindow() {
    oe(t, "beforeunload", () => {
      this._canUseSessionStorage() && ei.remove(this._primary_window_exists_storage_key);
    }, {
      capture: !1
    });
  }
  checkAndGetSessionAndWindowId() {
    var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
    if (this.config.__preview_experimental_cookieless_mode) throw new Error("checkAndGetSessionAndWindowId should not be called in __preview_experimental_cookieless_mode");
    var i = t || new Date().getTime(),
      [r, n, s] = this._getSessionId(),
      o = this._getWindowId(),
      a = M(s) && s > 0 && Math.abs(i - s) > 864e5,
      l = !1,
      u = !n,
      c = !e && Math.abs(i - r) > this.sessionTimeoutMs;
    u || c || a ? (n = this._sessionIdGenerator(), o = this._windowIdGenerator(), Lo.info("new session ID generated", {
      sessionId: n,
      windowId: o,
      changeReason: {
        noSessionId: u,
        activityTimeout: c,
        sessionPastMaximumLength: a
      }
    }), s = i, l = !0) : o || (o = this._windowIdGenerator(), l = !0);
    var d = 0 === r || !e || a ? i : r,
      h = 0 === s ? new Date().getTime() : s;
    return this._setWindowId(o), this._setSessionId(n, d, h), e || this.resetIdleTimer(), l && this._sessionIdChangedHandlers.forEach(e => e(n, o, l ? {
      noSessionId: u,
      activityTimeout: c,
      sessionPastMaximumLength: a
    } : void 0)), {
      sessionId: n,
      windowId: o,
      sessionStartTimestamp: h,
      changeReason: l ? {
        noSessionId: u,
        activityTimeout: c,
        sessionPastMaximumLength: a
      } : void 0,
      lastActivityTimestamp: r
    };
  }
  resetIdleTimer() {
    clearTimeout(this._enforceIdleTimeout), this._enforceIdleTimeout = setTimeout(() => {
      this.resetSessionId();
    }, 1.1 * this.sessionTimeoutMs);
  }
}
var qo = ["$set_once", "$set"],
  No = B("[SiteApps]");
class Bo {
  constructor(e) {
    this.instance = e, this.bufferedInvocations = [], this.apps = {};
  }
  get isEnabled() {
    return !!this.instance.config.opt_in_site_apps;
  }
  eventCollector(e, t) {
    if (t) {
      var i = this.globalsForEvent(t);
      this.bufferedInvocations.push(i), this.bufferedInvocations.length > 1e3 && (this.bufferedInvocations = this.bufferedInvocations.slice(10));
    }
  }
  get siteAppLoaders() {
    var e, t;
    return null === (e = _._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.siteApps;
  }
  init() {
    if (this.isEnabled) {
      var e = this.instance._addCaptureHook(this.eventCollector.bind(this));
      this.stopBuffering = () => {
        e(), this.bufferedInvocations = [], this.stopBuffering = void 0;
      };
    }
  }
  globalsForEvent(e) {
    var t, i, r, n, s, o, a;
    if (!e) throw new Error("Event payload is required");
    var l = {},
      u = this.instance.get_property("$groups") || [],
      c = this.instance.get_property("$stored_group_properties") || {};
    for (var [d, h] of Object.entries(c)) l[d] = {
      id: u[d],
      type: d,
      properties: h
    };
    var {
      $set_once: _,
      $set: p
    } = e;
    return {
      event: z(z({}, V(e, qo)), {}, {
        properties: z(z(z({}, e.properties), p ? {
          $set: z(z({}, null !== (t = null === (i = e.properties) || void 0 === i ? void 0 : i.$set) && void 0 !== t ? t : {}), p)
        } : {}), _ ? {
          $set_once: z(z({}, null !== (r = null === (n = e.properties) || void 0 === n ? void 0 : n.$set_once) && void 0 !== r ? r : {}), _)
        } : {}),
        elements_chain: null !== (s = null === (o = e.properties) || void 0 === o ? void 0 : o.$elements_chain) && void 0 !== s ? s : "",
        distinct_id: null === (a = e.properties) || void 0 === a ? void 0 : a.distinct_id
      }),
      person: {
        properties: this.instance.get_property("$stored_person_properties")
      },
      groups: l
    };
  }
  setupSiteApp(e) {
    var t = this.apps[e.id],
      i = () => {
        var i;
        (!t.errored && this.bufferedInvocations.length && (No.info("Processing ".concat(this.bufferedInvocations.length, " events for site app with id ").concat(e.id)), this.bufferedInvocations.forEach(e => {
          var i;
          return null === (i = t.processEvent) || void 0 === i ? void 0 : i.call(t, e);
        }), t.processedBuffer = !0), Object.values(this.apps).every(e => e.processedBuffer || e.errored)) && (null === (i = this.stopBuffering) || void 0 === i || i.call(this));
      },
      r = !1,
      n = n => {
        t.errored = !n, t.loaded = !0, No.info("Site app with id ".concat(e.id, " ").concat(n ? "loaded" : "errored")), r && i();
      };
    try {
      var {
        processEvent: s
      } = e.init({
        posthog: this.instance,
        callback: e => {
          n(e);
        }
      });
      s && (t.processEvent = s), r = !0;
    } catch (t) {
      No.error("Error while initializing PostHog app with config id ".concat(e.id), t), n(!1);
    }
    if (r && t.loaded) try {
      i();
    } catch (i) {
      No.error("Error while processing buffered events PostHog app with config id ".concat(e.id), i), t.errored = !0;
    }
  }
  setupSiteApps() {
    var e = this.siteAppLoaders || [];
    for (var t of e) this.apps[t.id] = {
      id: t.id,
      loaded: !1,
      errored: !1,
      processedBuffer: !1
    };
    for (var i of e) this.setupSiteApp(i);
  }
  onCapturedEvent(e) {
    if (0 !== Object.keys(this.apps).length) {
      var t = this.globalsForEvent(e);
      for (var i of Object.values(this.apps)) try {
        var r;
        null === (r = i.processEvent) || void 0 === r || r.call(i, t);
      } catch (t) {
        No.error("Error while processing event ".concat(e.event, " for site app ").concat(i.id), t);
      }
    }
  }
  onRemoteConfig(e) {
    var t,
      i,
      r,
      n = this;
    if (null !== (t = this.siteAppLoaders) && void 0 !== t && t.length) return this.isEnabled ? (this.setupSiteApps(), void this.instance.on("eventCaptured", e => this.onCapturedEvent(e))) : void No.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
    if (null === (i = this.stopBuffering) || void 0 === i || i.call(this), null !== (r = e.siteApps) && void 0 !== r && r.length) if (this.isEnabled) {
      var s = function (e, t) {
        var i, r;
        _["__$$ph_site_app_".concat(e)] = n.instance, null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadSiteApp) || void 0 === r || r.call(i, n.instance, t, t => {
          if (t) return No.error("Error while initializing PostHog app with config id ".concat(e), t);
        });
      };
      for (var {
        id: o,
        url: a
      } of e.siteApps) s(o, a);
    } else No.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
  }
}
var Ho,
  jo = ["amazonbot", "amazonproductbot", "app.hypefactors.com", "applebot", "archive.org_bot", "awariobot", "backlinksextendedbot", "baiduspider", "bingbot", "bingpreview", "chrome-lighthouse", "dataforseobot", "deepscan", "duckduckbot", "facebookexternal", "facebookcatalog", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "meta-externalagent", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "sebot-wa", "sitebulb", "slackbot", "slurp", "trendictionbot", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "zoombot", "bot.htm", "bot.php", "(bot;", "bot/", "crawler", "ahrefsbot", "ahrefssiteaudit", "semrushbot", "siteauditbot", "splitsignalbot", "gptbot", "oai-searchbot", "chatgpt-user", "perplexitybot", "better uptime bot", "sentryuptimebot", "uptimerobot", "headlesschrome", "cypress", "google-hoteladsverifier", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google", "bytespider"],
  Uo = function (e, t) {
    if (!e) return !1;
    var i = e.toLowerCase();
    return jo.concat(t || []).some(e => {
      var t = e.toLowerCase();
      return -1 !== i.indexOf(t);
    });
  },
  zo = function (e, t) {
    if (!e) return !1;
    var i = e.userAgent;
    if (i && Uo(i, t)) return !0;
    try {
      var r = null == e ? void 0 : e.userAgentData;
      if (null != r && r.brands && r.brands.some(e => Uo(null == e ? void 0 : e.brand, t))) return !0;
    } catch (e) {}
    return !!e.webdriver;
  };
function Wo(e, t, i) {
  return Io({
    distinct_id: e,
    userPropertiesToSet: t,
    userPropertiesToSetOnce: i
  });
}
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(Ho || (Ho = {}));
var Vo = "i.posthog.com";
class Go {
  constructor(e) {
    W(this, "_regionCache", {}), this.instance = e;
  }
  get apiHost() {
    var e = this.instance.config.api_host.trim().replace(/\/$/, "");
    return "https://app.posthog.com" === e ? "https://us.i.posthog.com" : e;
  }
  get uiHost() {
    var e,
      t = null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
    return t || (t = this.apiHost.replace(".".concat(Vo), ".posthog.com")), "https://app.posthog.com" === t ? "https://us.posthog.com" : t;
  }
  get region() {
    return this._regionCache[this.apiHost] || (/https:\/\/(app|us|us-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = Ho.US : /https:\/\/(eu|eu-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = Ho.EU : this._regionCache[this.apiHost] = Ho.CUSTOM), this._regionCache[this.apiHost];
  }
  endpointFor(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
    if (t && (t = "/" === t[0] ? t : "/".concat(t)), "ui" === e) return this.uiHost + t;
    if (this.region === Ho.CUSTOM) return this.apiHost + t;
    var i = Vo + t;
    switch (e) {
      case "assets":
        return "https://".concat(this.region, "-assets.").concat(i);
      case "api":
        return "https://".concat(this.region, ".").concat(i);
    }
  }
}
var Jo = {
  icontains: (e, i) => !!t && i.href.toLowerCase().indexOf(e.toLowerCase()) > -1,
  not_icontains: (e, i) => !!t && -1 === i.href.toLowerCase().indexOf(e.toLowerCase()),
  regex: (e, i) => !!t && uo(i.href, e),
  not_regex: (e, i) => !!t && !uo(i.href, e),
  exact: (e, t) => t.href === e,
  is_not: (e, t) => t.href !== e
};
class Yo {
  constructor(e) {
    var t = this;
    W(this, "getWebExperimentsAndEvaluateDisplayLogic", function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
      t.getWebExperiments(e => {
        Yo.logInfo("retrieved web experiments from the server"), t._flagToExperiments = new Map(), e.forEach(e => {
          if (e.feature_flag_key) {
            var i;
            if (t._flagToExperiments) Yo.logInfo("setting flag key ", e.feature_flag_key, " to web experiment ", e), null === (i = t._flagToExperiments) || void 0 === i || i.set(e.feature_flag_key, e);
            var r = t.instance.getFeatureFlag(e.feature_flag_key);
            T(r) && e.variants[r] && t.applyTransforms(e.name, r, e.variants[r].transforms);
          } else if (e.variants) for (var n in e.variants) {
            var s = e.variants[n];
            Yo.matchesTestVariant(s) && t.applyTransforms(e.name, n, s.transforms);
          }
        });
      }, e);
    }), this.instance = e, this.instance.onFeatureFlags(e => {
      this.onFeatureFlags(e);
    });
  }
  onFeatureFlags(e) {
    if (this._is_bot()) Yo.logInfo("Refusing to render web experiment since the viewer is a likely bot");else if (!this.instance.config.disable_web_experiments) {
      if (O(this._flagToExperiments)) return this._flagToExperiments = new Map(), this.loadIfEnabled(), void this.previewWebExperiment();
      Yo.logInfo("applying feature flags", e), e.forEach(e => {
        var t;
        if (this._flagToExperiments && null !== (t = this._flagToExperiments) && void 0 !== t && t.has(e)) {
          var i,
            r = this.instance.getFeatureFlag(e),
            n = null === (i = this._flagToExperiments) || void 0 === i ? void 0 : i.get(e);
          r && null != n && n.variants[r] && this.applyTransforms(n.name, r, n.variants[r].transforms);
        }
      });
    }
  }
  previewWebExperiment() {
    var e = Yo.getWindowLocation();
    if (null != e && e.search) {
      var t = Et(null == e ? void 0 : e.search, "__experiment_id"),
        i = Et(null == e ? void 0 : e.search, "__experiment_variant");
      t && i && (Yo.logInfo("previewing web experiments ".concat(t, " && ").concat(i)), this.getWebExperiments(e => {
        this.showPreviewWebExperiment(parseInt(t), i, e);
      }, !1, !0));
    }
  }
  loadIfEnabled() {
    this.instance.config.disable_web_experiments || this.getWebExperimentsAndEvaluateDisplayLogic();
  }
  getWebExperiments(e, t, i) {
    if (this.instance.config.disable_web_experiments && !i) return e([]);
    var r = this.instance.get_property("$web_experiments");
    if (r && !t) return e(r);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/web_experiments/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        if (200 !== t.statusCode || !t.json) return e([]);
        var i = t.json.experiments || [];
        return e(i);
      }
    });
  }
  showPreviewWebExperiment(e, t, i) {
    var r = i.filter(t => t.id === e);
    r && r.length > 0 && (Yo.logInfo("Previewing web experiment [".concat(r[0].name, "] with variant [").concat(t, "]")), this.applyTransforms(r[0].name, t, r[0].variants[t].transforms));
  }
  static matchesTestVariant(e) {
    return !O(e.conditions) && Yo.matchUrlConditions(e) && Yo.matchUTMConditions(e);
  }
  static matchUrlConditions(e) {
    var t;
    if (O(e.conditions) || O(null === (t = e.conditions) || void 0 === t ? void 0 : t.url)) return !0;
    var i,
      r,
      n,
      s = Yo.getWindowLocation();
    return !!s && (null === (i = e.conditions) || void 0 === i || !i.url || Jo[null !== (r = null === (n = e.conditions) || void 0 === n ? void 0 : n.urlMatchType) && void 0 !== r ? r : "icontains"](e.conditions.url, s));
  }
  static getWindowLocation() {
    return null == t ? void 0 : t.location;
  }
  static matchUTMConditions(e) {
    var t;
    if (O(e.conditions) || O(null === (t = e.conditions) || void 0 === t ? void 0 : t.utm)) return !0;
    var i = hs.campaignParams();
    if (i.utm_source) {
      var r,
        n,
        s,
        o,
        a,
        l,
        u,
        c,
        d,
        h,
        _,
        p,
        v,
        g,
        f,
        m,
        y = null === (r = e.conditions) || void 0 === r || null === (n = r.utm) || void 0 === n || !n.utm_campaign || (null === (s = e.conditions) || void 0 === s || null === (o = s.utm) || void 0 === o ? void 0 : o.utm_campaign) == i.utm_campaign,
        b = null === (a = e.conditions) || void 0 === a || null === (l = a.utm) || void 0 === l || !l.utm_source || (null === (u = e.conditions) || void 0 === u || null === (c = u.utm) || void 0 === c ? void 0 : c.utm_source) == i.utm_source,
        w = null === (d = e.conditions) || void 0 === d || null === (h = d.utm) || void 0 === h || !h.utm_medium || (null === (_ = e.conditions) || void 0 === _ || null === (p = _.utm) || void 0 === p ? void 0 : p.utm_medium) == i.utm_medium,
        S = null === (v = e.conditions) || void 0 === v || null === (g = v.utm) || void 0 === g || !g.utm_term || (null === (f = e.conditions) || void 0 === f || null === (m = f.utm) || void 0 === m ? void 0 : m.utm_term) == i.utm_term;
      return y && w && S && b;
    }
    return !1;
  }
  static logInfo(e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    N.info("[WebExperiments] ".concat(e), i);
  }
  applyTransforms(e, t, i) {
    this._is_bot() ? Yo.logInfo("Refusing to render web experiment since the viewer is a likely bot") : "control" !== t ? i.forEach(i => {
      if (i.selector) {
        var r;
        Yo.logInfo("applying transform of variant ".concat(t, " for experiment ").concat(e, " "), i);
        var n = null === (r = document) || void 0 === r ? void 0 : r.querySelectorAll(i.selector);
        null == n || n.forEach(e => {
          var t = e;
          i.html && (t.innerHTML = i.html), i.css && t.setAttribute("style", i.css);
        });
      }
    }) : Yo.logInfo("Control variants leave the page unmodified.");
  }
  _is_bot() {
    return o && this.instance ? zo(o, this.instance.config.custom_blocked_useragents) : void 0;
  }
}
var Ko = {},
  Xo = () => {},
  Qo = "posthog",
  Zo = !ko && -1 === (null == h ? void 0 : h.indexOf("MSIE")) && -1 === (null == h ? void 0 : h.indexOf("Mozilla")),
  ea = () => {
    var e;
    return {
      api_host: "https://us.i.posthog.com",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: ne(null == a ? void 0 : a.location),
      persistence: "localStorage+cookie",
      persistence_name: "",
      loaded: Xo,
      save_campaign_params: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: "if_capture_pageview",
      debug: l && T(null == l ? void 0 : l.search) && -1 !== l.search.indexOf("__posthog_debug=true") || !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_web_experiments: !0,
      disable_surveys: !1,
      disable_external_dependency_loading: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == t || null === (e = t.location) || void 0 === e ? void 0 : e.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_denylist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      mask_personal_data_properties: !1,
      custom_personal_data_properties: [],
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      feature_flag_request_timeout_ms: 3e3,
      surveys_request_timeout_ms: 1e4,
      on_request_error: e => {
        var t = "Bad HTTP status: " + e.statusCode + " " + e.text;
        N.error(t);
      },
      get_device_id: e => e,
      capture_performance: void 0,
      name: "posthog",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800,
      person_profiles: "identified_only",
      before_send: void 0,
      request_queue_config: {
        flush_interval_ms: Fo
      },
      _onCapture: Xo
    };
  },
  ta = e => {
    var t = {};
    F(e.process_person) || (t.person_profiles = e.process_person), F(e.xhr_headers) || (t.request_headers = e.xhr_headers), F(e.cookie_name) || (t.persistence_name = e.cookie_name), F(e.disable_cookie) || (t.disable_persistence = e.disable_cookie), F(e.store_google) || (t.save_campaign_params = e.store_google), F(e.verbose) || (t.debug = e.verbose);
    var i = K({}, t, e);
    return x(e.property_blacklist) && (F(e.property_denylist) ? i.property_denylist = e.property_blacklist : x(e.property_denylist) ? i.property_denylist = [...e.property_blacklist, ...e.property_denylist] : N.error("Invalid value for property_denylist config: " + e.property_denylist)), i;
  };
class ia {
  constructor() {
    W(this, "__forceAllowLocalhost", !1);
  }
  get _forceAllowLocalhost() {
    return this.__forceAllowLocalhost;
  }
  set _forceAllowLocalhost(e) {
    N.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
  }
}
class ra {
  get decideEndpointWasHit() {
    var e, t;
    return null !== (e = null === (t = this.featureFlags) || void 0 === t ? void 0 : t.hasLoadedFlags) && void 0 !== e && e;
  }
  constructor() {
    W(this, "webPerformance", new ia()), W(this, "_personProcessingSetOncePropertiesSent", !1), W(this, "version", p.LIB_VERSION), W(this, "_internalEventEmitter", new co()), this.config = ea(), this.SentryIntegration = Yr, this.sentryIntegration = e => function (e, t) {
      var i = Jr(e, t);
      return {
        name: Gr,
        processEvent: e => i(e)
      };
    }(this, e), this.__request_queue = [], this.__loaded = !1, this.analyticsDefaultEndpoint = "/e/", this._initialPageviewCaptured = !1, this._initialPersonProfilesConfig = null, this._cachedIdentify = null, this.featureFlags = new ws(this), this.toolbar = new en(this), this.scrollManager = new Oo(this), this.pageViewManager = new cn(this), this.surveys = new fo(this), this.experiments = new Yo(this), this.exceptions = new dn(this), this.rateLimiter = new yo(this), this.requestRouter = new Go(this), this.consent = new ti(this), this.people = {
      set: (e, t, i) => {
        var r = T(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(r), null == i || i({});
      },
      set_once: (e, t, i) => {
        var r = T(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(void 0, r), null == i || i({});
      }
    }, this.on("eventCaptured", e => N.info('send "'.concat(null == e ? void 0 : e.event, '"'), e));
  }
  init(e, t, i) {
    if (i && i !== Qo) {
      var r,
        n = null !== (r = Ko[i]) && void 0 !== r ? r : new ra();
      return n._init(e, t, i), Ko[i] = n, Ko[Qo][i] = n, n;
    }
    return this._init(e, t, i);
  }
  _init(i) {
    var r,
      n,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      o = arguments.length > 2 ? arguments[2] : void 0;
    if (F(i) || R(i)) return N.critical("PostHog was initialized without a token. This likely indicates a misconfiguration. Please check the first argument passed to posthog.init()"), this;
    if (this.__loaded) return N.warn("You have already initialized PostHog! Re-initializing is a no-op"), this;
    this.__loaded = !0, this.config = {}, this._triggered_notifs = [], s.person_profiles && (this._initialPersonProfilesConfig = s.person_profiles), this.set_config(K({}, ea(), ta(s), {
      name: o,
      token: i
    })), this.config.on_xhr_error && N.error("on_xhr_error is deprecated. Use on_request_error instead"), this.compression = s.disable_compression ? void 0 : e.GZipJS, this.persistence = new ks(this.config), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new ks(z(z({}, this.config), {}, {
      persistence: "sessionStorage"
    }));
    var a = z({}, this.persistence.props),
      l = z({}, this.sessionPersistence.props);
    if (this._requestQueue = new To(e => this._send_retriable_request(e), this.config.request_queue_config), this._retryQueue = new $o(this), this.__request_queue = [], this.config.__preview_experimental_cookieless_mode || (this.sessionManager = new Do(this), this.sessionPropsManager = new Ao(this, this.sessionManager, this.persistence)), new rn(this).startIfEnabledOrStop(), this.siteApps = new Bo(this), null === (r = this.siteApps) || void 0 === r || r.init(), this.config.__preview_experimental_cookieless_mode || (this.sessionRecording = new zr(this), this.sessionRecording.startIfEnabledOrStop()), this.config.disable_scroll_properties || this.scrollManager.startMeasuringScrollPosition(), this.autocapture = new $t(this), this.autocapture.startIfEnabled(), this.surveys.loadIfEnabled(), this.heatmaps = new un(this), this.heatmaps.startIfEnabled(), this.webVitalsAutocapture = new on(this), this.exceptionObserver = new ai(this), this.exceptionObserver.startIfEnabled(), this.deadClicksAutocapture = new si(this, ni), this.deadClicksAutocapture.startIfEnabled(), p.DEBUG = p.DEBUG || this.config.debug, p.DEBUG && N.info("Starting in debug mode", {
      this: this,
      config: s,
      thisC: z({}, this.config),
      p: a,
      s: l
    }), this._sync_opt_out_with_persistence(), void 0 !== (null === (n = s.bootstrap) || void 0 === n ? void 0 : n.distinctID)) {
      var u,
        c,
        d = this.config.get_device_id(Nt()),
        h = null !== (u = s.bootstrap) && void 0 !== u && u.isIdentifiedID ? d : s.bootstrap.distinctID;
      this.persistence.set_property(Le, null !== (c = s.bootstrap) && void 0 !== c && c.isIdentifiedID ? "identified" : "anonymous"), this.register({
        distinct_id: s.bootstrap.distinctID,
        $device_id: h
      });
    }
    if (this._hasBootstrappedFeatureFlags()) {
      var _,
        v,
        g = Object.keys((null === (_ = s.bootstrap) || void 0 === _ ? void 0 : _.featureFlags) || {}).filter(e => {
          var t, i;
          return !(null === (t = s.bootstrap) || void 0 === t || null === (i = t.featureFlags) || void 0 === i || !i[e]);
        }).reduce((e, t) => {
          var i, r;
          return e[t] = (null === (i = s.bootstrap) || void 0 === i || null === (r = i.featureFlags) || void 0 === r ? void 0 : r[t]) || !1, e;
        }, {}),
        f = Object.keys((null === (v = s.bootstrap) || void 0 === v ? void 0 : v.featureFlagPayloads) || {}).filter(e => g[e]).reduce((e, t) => {
          var i, r, n, o;
          null !== (i = s.bootstrap) && void 0 !== i && null !== (r = i.featureFlagPayloads) && void 0 !== r && r[t] && (e[t] = null === (n = s.bootstrap) || void 0 === n || null === (o = n.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
          return e;
        }, {});
      this.featureFlags.receivedFeatureFlags({
        featureFlags: g,
        featureFlagPayloads: f
      });
    }
    if (this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: ze,
      $device_id: null
    }, "");else if (!this.get_distinct_id()) {
      var m = this.config.get_device_id(Nt());
      this.register_once({
        distinct_id: m,
        $device_id: m
      }, ""), this.persistence.set_property(Le, "anonymous");
    }
    return oe(t, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this), {
      passive: !1
    }), this.toolbar.maybeLoadToolbar(), s.segment ? Vr(this, () => this._loaded()) : this._loaded(), I(this.config._onCapture) && this.config._onCapture !== Xo && (N.warn("onCapture is deprecated. Please use `before_send` instead"), this.on("eventCaptured", e => this.config._onCapture(e.event, e))), this;
  }
  _onRemoteConfig(t) {
    var i, r, n, s, o, l, u, c;
    if (!a || !a.body) return N.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(() => {
      this._onRemoteConfig(t);
    }, 500);
    this.compression = void 0, t.supportedCompression && !this.config.disable_compression && (this.compression = m(t.supportedCompression, e.GZipJS) ? e.GZipJS : m(t.supportedCompression, e.Base64) ? e.Base64 : void 0), null !== (i = t.analytics) && void 0 !== i && i.endpoint && (this.analyticsDefaultEndpoint = t.analytics.endpoint), this.set_config({
      person_profiles: this._initialPersonProfilesConfig ? this._initialPersonProfilesConfig : "identified_only"
    }), null === (r = this.siteApps) || void 0 === r || r.onRemoteConfig(t), null === (n = this.sessionRecording) || void 0 === n || n.onRemoteConfig(t), null === (s = this.autocapture) || void 0 === s || s.onRemoteConfig(t), null === (o = this.heatmaps) || void 0 === o || o.onRemoteConfig(t), this.surveys.onRemoteConfig(t), null === (l = this.webVitalsAutocapture) || void 0 === l || l.onRemoteConfig(t), null === (u = this.exceptionObserver) || void 0 === u || u.onRemoteConfig(t), null === (c = this.deadClicksAutocapture) || void 0 === c || c.onRemoteConfig(t);
  }
  _loaded() {
    try {
      this.config.loaded(this);
    } catch (e) {
      N.critical("`loaded` function failed", e);
    }
    this._start_queue_if_opted_in(), this.config.capture_pageview && setTimeout(() => {
      this.consent.isOptedIn() && this._captureInitialPageview();
    }, 1), new wo(this).load(), this.featureFlags.decide();
  }
  _start_queue_if_opted_in() {
    var e;
    this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.enable());
  }
  _dom_loaded() {
    this.has_opted_out_capturing() || J(this.__request_queue, e => this._send_retriable_request(e)), this.__request_queue = [], this._start_queue_if_opted_in();
  }
  _handle_unload() {
    var e, t;
    this.config.request_batching ? (this._shouldCapturePageleave() && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this._shouldCapturePageleave() && this.capture("$pageleave", null, {
      transport: "sendBeacon"
    });
  }
  _send_request(e) {
    this.__loaded && (Zo ? this.__request_queue.push(e) : this.rateLimiter.isServerRateLimited(e.batchKey) || (e.transport = e.transport || this.config.api_transport, e.url = xo(e.url, {
      ip: this.config.ip ? 1 : 0
    }), e.headers = z({}, this.config.request_headers), e.compression = "best-available" === e.compression ? this.compression : e.compression, e.fetchOptions = e.fetchOptions || this.config.fetch_options, (e => {
      var t,
        i,
        r,
        n = z({}, e);
      n.timeout = n.timeout || 6e4, n.url = xo(n.url, {
        _: new Date().getTime().toString(),
        ver: p.LIB_VERSION,
        compression: n.compression
      });
      var s = null !== (t = n.transport) && void 0 !== t ? t : "fetch",
        o = null !== (i = null === (r = se(Po, e => e.transport === s)) || void 0 === r ? void 0 : r.method) && void 0 !== i ? i : Po[0].method;
      if (!o) throw new Error("No available transport method");
      o(n);
    })(z(z({}, e), {}, {
      callback: t => {
        var i, r, n;
        (this.rateLimiter.checkForLimiting(t), t.statusCode >= 400) && (null === (r = (n = this.config).on_request_error) || void 0 === r || r.call(n, t));
        null === (i = e.callback) || void 0 === i || i.call(e, t);
      }
    }))));
  }
  _send_retriable_request(e) {
    this._retryQueue ? this._retryQueue.retriableRequest(e) : this._send_request(e);
  }
  _execute_array(e) {
    var t,
      i = [],
      r = [],
      n = [];
    J(e, e => {
      e && (t = e[0], x(t) ? n.push(e) : I(e) ? e.call(this) : x(e) && "alias" === t ? i.push(e) : x(e) && -1 !== t.indexOf("capture") && I(this[t]) ? n.push(e) : r.push(e));
    });
    var s = function (e, t) {
      J(e, function (e) {
        if (x(e[0])) {
          var i = t;
          Y(e, function (e) {
            i = i[e[0]].apply(i, e.slice(1));
          });
        } else this[e[0]].apply(this, e.slice(1));
      }, t);
    };
    s(i, this), s(r, this), s(n, this);
  }
  _hasBootstrappedFeatureFlags() {
    var e, t;
    return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
  }
  push(e) {
    this._execute_array([e]);
  }
  capture(e, t, i) {
    var r;
    if (this.__loaded && this.persistence && this.sessionPersistence && this._requestQueue) {
      if (!this.consent.isOptedOut()) if (!F(e) && T(e)) {
        if (this.config.opt_out_useragent_filter || !this._is_bot()) {
          var n = null != i && i.skip_client_rate_limiting ? void 0 : this.rateLimiter.clientRateLimitContext();
          if (null == n || !n.isRateLimited) {
            null != t && t.$current_url && !T(null == t ? void 0 : t.$current_url) && (N.error("Invalid `$current_url` property provided to `posthog.capture`. Input must be a string. Ignoring provided value."), null == t || delete t.$current_url), this.sessionPersistence.update_search_keyword(), this.config.save_campaign_params && this.sessionPersistence.update_campaign_params(), this.config.save_referrer && this.sessionPersistence.update_referrer_info(), (this.config.save_campaign_params || this.config.save_referrer) && this.persistence.set_initial_person_info();
            var s = new Date(),
              o = (null == i ? void 0 : i.timestamp) || s,
              a = Nt(),
              l = {
                uuid: a,
                event: e,
                properties: this._calculate_event_properties(e, t || {}, o, a)
              };
            n && (l.properties.$lib_rate_limit_remaining_tokens = n.remainingTokens), (null == i ? void 0 : i.$set) && (l.$set = null == i ? void 0 : i.$set);
            var u = this._calculate_set_once_properties(null == i ? void 0 : i.$set_once);
            u && (l.$set_once = u), (l = ie(l, null != i && i._noTruncate ? null : this.config.properties_string_max_length)).timestamp = o, F(null == i ? void 0 : i.timestamp) || (l.properties.$event_time_override_provided = !0, l.properties.$event_time_override_system_time = s);
            var c = z(z({}, l.properties.$set), l.$set);
            if (P(c) || this.setPersonPropertiesForFlags(c), !O(this.config.before_send)) {
              var d = this._runBeforeSend(l);
              if (!d) return;
              l = d;
            }
            this._internalEventEmitter.emit("eventCaptured", l);
            var h = {
              method: "POST",
              url: null !== (r = null == i ? void 0 : i._url) && void 0 !== r ? r : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
              data: l,
              compression: "best-available",
              batchKey: null == i ? void 0 : i._batchKey
            };
            return !this.config.request_batching || i && (null == i || !i._batchKey) || null != i && i.send_instantly ? this._send_retriable_request(h) : this._requestQueue.enqueue(h), l;
          }
          N.critical("This capture call is ignored due to client rate limiting.");
        }
      } else N.error("No event name provided to posthog.capture");
    } else N.uninitializedWarning("posthog.capture");
  }
  _addCaptureHook(e) {
    return this.on("eventCaptured", t => e(t.event, t));
  }
  _calculate_event_properties(e, t, i, r) {
    if (i = i || new Date(), !this.persistence || !this.sessionPersistence) return t;
    var n = this.persistence.remove_event_timer(e),
      s = z({}, t);
    if (s.token = this.config.token, this.config.__preview_experimental_cookieless_mode && (s.$cookieless_mode = !0), "$snapshot" === e) {
      var o = z(z({}, this.persistence.properties()), this.sessionPersistence.properties());
      return s.distinct_id = o.distinct_id, (!T(s.distinct_id) && !M(s.distinct_id) || R(s.distinct_id)) && N.error("Invalid distinct_id for replay event. This indicates a bug in your implementation"), s;
    }
    var l,
      u = hs.properties({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
    if (this.sessionManager) {
      var {
        sessionId: c,
        windowId: d
      } = this.sessionManager.checkAndGetSessionAndWindowId();
      s.$session_id = c, s.$window_id = d;
    }
    this.sessionPropsManager && K(s, this.sessionPropsManager.getSessionProps());
    try {
      var _, p;
      this.sessionRecording && (s.$recording_status = this.sessionRecording.status, s.$sdk_debug_replay_internal_buffer_length = this.sessionRecording.buffer.data.length, s.$sdk_debug_replay_internal_buffer_size = this.sessionRecording.buffer.size), s.$sdk_debug_retry_queue_size = null === (_ = this._retryQueue) || void 0 === _ || null === (p = _.queue) || void 0 === p ? void 0 : p.length;
    } catch (e) {
      s.$sdk_debug_error_capturing_properties = String(e);
    }
    if (this.requestRouter.region === Ho.CUSTOM && (s.$lib_custom_api_host = this.config.api_host), l = "$pageview" === e ? this.pageViewManager.doPageView(i, r) : "$pageleave" === e ? this.pageViewManager.doPageLeave(i) : this.pageViewManager.doEvent(), s = K(s, l), "$pageview" === e && a && (s.title = a.title), !F(n)) {
      var v = i.getTime() - n;
      s.$duration = parseFloat((v / 1e3).toFixed(3));
    }
    h && this.config.opt_out_useragent_filter && (s.$browser_type = this._is_bot() ? "bot" : "browser"), (s = K({}, u, this.persistence.properties(), this.sessionPersistence.properties(), s)).$is_identified = this._isIdentified(), x(this.config.property_denylist) ? Y(this.config.property_denylist, function (e) {
      delete s[e];
    }) : N.error("Invalid value for property_denylist config: " + this.config.property_denylist + " or property_blacklist config: " + this.config.property_blacklist);
    var g = this.config.sanitize_properties;
    g && (N.error("sanitize_properties is deprecated. Use before_send instead"), s = g(s, e));
    var f = this._hasPersonProcessing();
    return s.$process_person_profile = f, f && this._requirePersonProcessing("_calculate_event_properties"), s;
  }
  _calculate_set_once_properties(e) {
    var t;
    if (!this.persistence || !this._hasPersonProcessing()) return e;
    if (this._personProcessingSetOncePropertiesSent) return e;
    var i = this.persistence.get_initial_props(),
      r = null === (t = this.sessionPropsManager) || void 0 === t ? void 0 : t.getSetOnceProps(),
      n = K({}, i, r || {}, e || {}),
      s = this.config.sanitize_properties;
    return s && (N.error("sanitize_properties is deprecated. Use before_send instead"), n = s(n, "$set_once")), this._personProcessingSetOncePropertiesSent = !0, P(n) ? void 0 : n;
  }
  register(e, t) {
    var i;
    null === (i = this.persistence) || void 0 === i || i.register(e, t);
  }
  register_once(e, t, i) {
    var r;
    null === (r = this.persistence) || void 0 === r || r.register_once(e, t, i);
  }
  register_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
  }
  unregister(e) {
    var t;
    null === (t = this.persistence) || void 0 === t || t.unregister(e);
  }
  unregister_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
  }
  _register_single(e, t) {
    this.register({
      [e]: t
    });
  }
  getFeatureFlag(e, t) {
    return this.featureFlags.getFeatureFlag(e, t);
  }
  getFeatureFlagPayload(e) {
    var t = this.featureFlags.getFeatureFlagPayload(e);
    try {
      return JSON.parse(t);
    } catch (e) {
      return t;
    }
  }
  isFeatureEnabled(e, t) {
    return this.featureFlags.isFeatureEnabled(e, t);
  }
  reloadFeatureFlags() {
    this.featureFlags.reloadFeatureFlags();
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      i = arguments.length > 2 ? arguments[2] : void 0;
    return this.featureFlags.getEarlyAccessFeatures(e, t, i);
  }
  on(e, t) {
    return this._internalEventEmitter.on(e, t);
  }
  onFeatureFlags(e) {
    return this.featureFlags.onFeatureFlags(e);
  }
  onSurveysLoaded(e) {
    return this.surveys.onSurveysLoaded(e);
  }
  onSessionId(e) {
    var t, i;
    return null !== (t = null === (i = this.sessionManager) || void 0 === i ? void 0 : i.onSessionId(e)) && void 0 !== t ? t : () => {};
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getSurveys(e, t);
  }
  getActiveMatchingSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getActiveMatchingSurveys(e, t);
  }
  renderSurvey(e, t) {
    this.surveys.renderSurvey(e, t);
  }
  canRenderSurvey(e) {
    return this.surveys.canRenderSurvey(e);
  }
  identify(e, t, i) {
    if (!this.__loaded || !this.persistence) return N.uninitializedWarning("posthog.identify");
    if (M(e) && (e = e.toString(), N.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), e) {
      if (["distinct_id", "distinctid"].includes(e.toLowerCase())) N.critical('The string "'.concat(e, '" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.'));else if (this._requirePersonProcessing("posthog.identify")) {
        var r = this.get_distinct_id();
        if (this.register({
          $user_id: e
        }), !this.get_property("$device_id")) {
          var n = r;
          this.register_once({
            $had_persisted_distinct_id: !0,
            $device_id: n
          }, "");
        }
        e !== r && e !== this.get_property(le) && (this.unregister(le), this.register({
          distinct_id: e
        }));
        var s = "anonymous" === (this.persistence.get_property(Le) || "anonymous");
        e !== r && s ? (this.persistence.set_property(Le, "identified"), this.setPersonPropertiesForFlags(z(z({}, i || {}), t || {}), !1), this.capture("$identify", {
          distinct_id: e,
          $anon_distinct_id: r
        }, {
          $set: t || {},
          $set_once: i || {}
        }), this.featureFlags.setAnonymousDistinctId(r), this._cachedIdentify = Wo(e, t, i)) : (t || i) && (this._cachedIdentify !== Wo(e, t, i) ? (this.setPersonProperties(t, i), this._cachedIdentify = Wo(e, t, i)) : N.info("A duplicate posthog.identify call was made with the same properties. It has been ignored.")), e !== r && (this.reloadFeatureFlags(), this.unregister(Ae));
      }
    } else N.error("Unique user id has not been set in posthog.identify");
  }
  setPersonProperties(e, t) {
    (e || t) && this._requirePersonProcessing("posthog.setPersonProperties") && (this.setPersonPropertiesForFlags(z(z({}, t || {}), e || {})), this.capture("$set", {
      $set: e || {},
      $set_once: t || {}
    }));
  }
  group(e, t, i) {
    if (e && t) {
      if (this._requirePersonProcessing("posthog.group")) {
        var r = this.getGroups();
        r[e] !== t && this.resetGroupPropertiesForFlags(e), this.register({
          $groups: z(z({}, r), {}, {
            [e]: t
          })
        }), i && (this.capture("$groupidentify", {
          $group_type: e,
          $group_key: t,
          $group_set: i
        }), this.setGroupPropertiesForFlags({
          [e]: i
        })), r[e] === t || i || this.reloadFeatureFlags();
      }
    } else N.error("posthog.group requires a group type and group key");
  }
  resetGroups() {
    this.register({
      $groups: {}
    }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this.featureFlags.setPersonPropertiesForFlags(e, t);
  }
  resetPersonPropertiesForFlags() {
    this.featureFlags.resetPersonPropertiesForFlags();
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setGroupPropertiesForFlags") && this.featureFlags.setGroupPropertiesForFlags(e, t);
  }
  resetGroupPropertiesForFlags(e) {
    this.featureFlags.resetGroupPropertiesForFlags(e);
  }
  reset(e) {
    var t, i, r, n;
    if (N.info("reset"), !this.__loaded) return N.uninitializedWarning("posthog.reset");
    var s = this.get_property("$device_id");
    if (this.consent.reset(), null === (t = this.persistence) || void 0 === t || t.clear(), null === (i = this.sessionPersistence) || void 0 === i || i.clear(), this.surveys.reset(), null === (r = this.persistence) || void 0 === r || r.set_property(Le, "anonymous"), null === (n = this.sessionManager) || void 0 === n || n.resetSessionId(), this._cachedIdentify = null, this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: ze,
      $device_id: null
    }, "");else {
      var o = this.config.get_device_id(Nt());
      this.register_once({
        distinct_id: o,
        $device_id: e ? o : s
      }, "");
    }
    this.register({
      $last_posthog_reset: new Date().toISOString()
    }, 1);
  }
  get_distinct_id() {
    return this.get_property("distinct_id");
  }
  getGroups() {
    return this.get_property("$groups") || {};
  }
  get_session_id() {
    var e, t;
    return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
  }
  get_session_replay_url(e) {
    if (!this.sessionManager) return "";
    var {
        sessionId: t,
        sessionStartTimestamp: i
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0),
      r = this.requestRouter.endpointFor("ui", "/project/".concat(this.config.token, "/replay/").concat(t));
    if (null != e && e.withTimestamp && i) {
      var n,
        s = null !== (n = e.timestampLookBack) && void 0 !== n ? n : 10;
      if (!i) return r;
      var o = Math.max(Math.floor((new Date().getTime() - i) / 1e3) - s, 0);
      r += "?t=".concat(o);
    }
    return r;
  }
  alias(e, t) {
    return e === this.get_property(ae) ? (N.critical("Attempting to create alias for existing People user - aborting."), -2) : this._requirePersonProcessing("posthog.alias") ? (F(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(le, e), this.capture("$create_alias", {
      alias: e,
      distinct_id: t
    })) : (N.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1)) : void 0;
  }
  set_config(e) {
    var t,
      i,
      r,
      n,
      s = z({}, this.config);
    C(e) && (K(this.config, ta(e)), null === (t = this.persistence) || void 0 === t || t.update_config(this.config, s), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new ks(z(z({}, this.config), {}, {
      persistence: "sessionStorage"
    })), Jt.is_supported() && "true" === Jt.get("ph_debug") && (this.config.debug = !0), this.config.debug && (p.DEBUG = !0, N.info("set_config", JSON.stringify({
      config: e,
      oldConfig: s,
      newConfig: z({}, this.config)
    }, null, 2))), null === (i = this.sessionRecording) || void 0 === i || i.startIfEnabledOrStop(), null === (r = this.autocapture) || void 0 === r || r.startIfEnabled(), null === (n = this.heatmaps) || void 0 === n || n.startIfEnabled(), this.surveys.loadIfEnabled(), this._sync_opt_out_with_persistence());
  }
  startSessionRecording(e) {
    var t = !0 === e,
      i = {
        sampling: t || !(null == e || !e.sampling),
        linked_flag: t || !(null == e || !e.linked_flag),
        url_trigger: t || !(null == e || !e.url_trigger),
        event_trigger: t || !(null == e || !e.event_trigger)
      };
    if (Object.values(i).some(Boolean)) {
      var r, n, s, o, a;
      if (null === (r = this.sessionManager) || void 0 === r || r.checkAndGetSessionAndWindowId(), i.sampling) null === (n = this.sessionRecording) || void 0 === n || n.overrideSampling();
      if (i.linked_flag) null === (s = this.sessionRecording) || void 0 === s || s.overrideLinkedFlag();
      if (i.url_trigger) null === (o = this.sessionRecording) || void 0 === o || o.overrideTrigger("url");
      if (i.event_trigger) null === (a = this.sessionRecording) || void 0 === a || a.overrideTrigger("event");
    }
    this.set_config({
      disable_session_recording: !1
    });
  }
  stopSessionRecording() {
    this.set_config({
      disable_session_recording: !0
    });
  }
  sessionRecordingStarted() {
    var e;
    return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
  }
  captureException(e, t) {
    var i = new Error("PostHog syntheticException");
    this.exceptions.sendExceptionEvent(z(z({}, Mi((e => e instanceof Error)(e) ? {
      error: e,
      event: e.message
    } : {
      event: e
    }, {
      syntheticException: i
    })), t));
  }
  loadToolbar(e) {
    return this.toolbar.loadToolbar(e);
  }
  get_property(e) {
    var t;
    return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
  }
  getSessionProperty(e) {
    var t;
    return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
  }
  toString() {
    var e,
      t = null !== (e = this.config.name) && void 0 !== e ? e : Qo;
    return t !== Qo && (t = Qo + "." + t), t;
  }
  _isIdentified() {
    var e, t;
    return "identified" === (null === (e = this.persistence) || void 0 === e ? void 0 : e.get_property(Le)) || "identified" === (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.get_property(Le));
  }
  _hasPersonProcessing() {
    var e, t, i, r;
    return !("never" === this.config.person_profiles || "identified_only" === this.config.person_profiles && !this._isIdentified() && P(this.getGroups()) && (null === (e = this.persistence) || void 0 === e || null === (t = e.props) || void 0 === t || !t[le]) && (null === (i = this.persistence) || void 0 === i || null === (r = i.props) || void 0 === r || !r[je]));
  }
  _shouldCapturePageleave() {
    return !0 === this.config.capture_pageleave || "if_capture_pageview" === this.config.capture_pageleave && this.config.capture_pageview;
  }
  createPersonProfile() {
    this._hasPersonProcessing() || this._requirePersonProcessing("posthog.createPersonProfile") && this.setPersonProperties({}, {});
  }
  _requirePersonProcessing(e) {
    return "never" === this.config.person_profiles ? (N.error(e + ' was called, but process_person is set to "never". This call will be ignored.'), !1) : (this._register_single(je, !0), !0);
  }
  _sync_opt_out_with_persistence() {
    var e,
      t,
      i,
      r,
      n = this.consent.isOptedOut(),
      s = this.config.opt_out_persistence_by_default,
      o = this.config.disable_persistence || n && !!s;
    (null === (e = this.persistence) || void 0 === e ? void 0 : e.disabled) !== o && (null === (i = this.persistence) || void 0 === i || i.set_disabled(o));
    (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.disabled) !== o && (null === (r = this.sessionPersistence) || void 0 === r || r.set_disabled(o));
  }
  opt_in_capturing(e) {
    var t;
    (this.consent.optInOut(!0), this._sync_opt_out_with_persistence(), F(null == e ? void 0 : e.captureEventName) || null != e && e.captureEventName) && this.capture(null !== (t = null == e ? void 0 : e.captureEventName) && void 0 !== t ? t : "$opt_in", null == e ? void 0 : e.captureProperties, {
      send_instantly: !0
    });
    this.config.capture_pageview && this._captureInitialPageview();
  }
  opt_out_capturing() {
    this.consent.optInOut(!1), this._sync_opt_out_with_persistence();
  }
  has_opted_in_capturing() {
    return this.consent.isOptedIn();
  }
  has_opted_out_capturing() {
    return this.consent.isOptedOut();
  }
  clear_opt_in_out_capturing() {
    this.consent.reset(), this._sync_opt_out_with_persistence();
  }
  _is_bot() {
    return o ? zo(o, this.config.custom_blocked_useragents) : void 0;
  }
  _captureInitialPageview() {
    a && !this._initialPageviewCaptured && (this._initialPageviewCaptured = !0, this.capture("$pageview", {
      title: a.title
    }, {
      send_instantly: !0
    }));
  }
  debug(e) {
    !1 === e ? (null == t || t.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
      debug: !1
    })) : (null == t || t.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
      debug: !0
    }));
  }
  _runBeforeSend(e) {
    if (O(this.config.before_send)) return e;
    var t = x(this.config.before_send) ? this.config.before_send : [this.config.before_send],
      i = e;
    for (var r of t) {
      if (i = r(i), O(i)) {
        var n = "Event '".concat(e.event, "' was rejected in beforeSend function");
        return D(e.event) ? N.warn("".concat(n, ". This can cause unexpected behavior.")) : N.info(n), null;
      }
      i.properties && !P(i.properties) || N.warn("Event '".concat(e.event, "' has no properties after beforeSend function, this is likely an error."));
    }
    return i;
  }
  getPageViewId() {
    var e;
    return null === (e = this.pageViewManager._currentPageview) || void 0 === e ? void 0 : e.pageViewId;
  }
  captureTraceFeedback(e, t) {
    this.capture("$ai_feedback", {
      $ai_trace_id: String(e),
      $ai_feedback_text: t
    });
  }
  captureTraceMetric(e, t, i) {
    this.capture("$ai_metric", {
      $ai_trace_id: String(e),
      $ai_metric_name: t,
      $ai_metric_value: String(i)
    });
  }
}
!function (e, t) {
  for (var i = 0; i < t.length; i++) e.prototype[t[i]] = ee(e.prototype[t[i]]);
}(ra, ["identify"]);
var na,
  sa = (na = Ko[Qo] = new ra(), function () {
    function e() {
      e.done || (e.done = !0, Zo = !1, Y(Ko, function (e) {
        e._dom_loaded();
      }));
    }
    null != a && a.addEventListener ? "complete" === a.readyState ? e() : oe(a, "DOMContentLoaded", e, {
      capture: !1
    }) : t && N.error("Browser doesn't support `document.addEventListener` so PostHog couldn't be initialized");
  }(), na);
export { v as COPY_AUTOCAPTURE_EVENT, e as Compression, ra as PostHog, Ms as SurveyQuestionBranchingType, Os as SurveyQuestionType, As as SurveySchedule, $s as SurveyType, sa as default, g as knownUnsafeEditableEvent, sa as posthog, f as severityLevels };
